import React, { useState, useEffect } from 'react'
import { ethers, BigNumber } from 'ethers';
import styled from 'styled-components'
import numeral from 'numeral';
import { RouteComponentProps } from 'react-router-dom'
import { Button, Progress, Row, Col, Input, notification } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'
import Countdown from 'react-countdown'
import STS from '../STS'
import { Container } from '../STS/Markets'
import useIdoInfo from '../../hooks/useIdoInfo'
import { configIdoList } from '../../config'
import { useFixedSwapContract } from '../../hooks/useContract'
import { ChainId, Currency, JSBI, TokenAmount } from '@uniswap/sdk'
import { TUSDT } from '../../constants'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useIdoModalToggle } from '../../state/application/hooks'
import "antd/lib/input/style/css"
import Modal from '../../components/Modal'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { ButtonPrimary } from '../../components/Button'

import { useActiveWeb3React } from '../../hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useTranslation } from 'react-i18next'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'

interface idoInfoInterface {
  poolName?: string;
  tokenName?: string;
  tokenDescription?: string;
  tokenLogo?: string;
  tokenCode?: string;
  erc20?: string;
  individualMinimumAmount?: BigNumber;
  individualMaximumAmount?: BigNumber;
  decimals: number;
  tradeValue?: number;
  startDate?: BigNumber;
  endDate?: BigNumber;
  tokensAllocated?: BigNumber;
  tokensForSale?: BigNumber;
  isTokenTrade?: boolean;
  tradeTokenBalance?: string;
  useToken?: Currency;
}

export default function IdoDetail(props: RouteComponentProps<{ contractAddress: string }>) {
  const {
    match: {
      params: { contractAddress }
    }
  } = props
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const [approval, approveCallback] = useApproveCallback(new TokenAmount(TUSDT[chainId ?? 4], JSBI.BigInt(100000000000000000000)), contractAddress)
  const [fromInputValue, setFromInputValue] = useState("")
  const [toInputValue, setToInputValue] = useState("")
  const [isShowBalanceError, setIsShowBalanceError] = useState(false)
  const idoModalOpen = useModalOpen(ApplicationModal.IDO)
  const toggleIdoModal = useIdoModalToggle()
  const userBalance = useCurrencyBalance(account ?? undefined, configIdoList[contractAddress].useToken[chainId || 4] ?? undefined)
  const fixedSwapContract = useFixedSwapContract(contractAddress)

  const [ idoInfo, setIdoInfo ] = useState<idoInfoInterface>({
    poolName: "",
    tokenName: "",
    tokenDescription: "",
    tokenLogo: "",
    tokenCode: "",
    erc20: "",
    decimals: 0,
    tradeValue: 0,
  })
  const resIdoInfo: any = useIdoInfo(contractAddress)
  const configIdoInfo = configIdoList[contractAddress]
  const addTransaction = useTransactionAdder()
  useEffect(() => {
    if (Object.keys(resIdoInfo).length) {
      setIdoInfo({
        ...resIdoInfo,
        ...configIdoInfo
      })
    }
  }, [resIdoInfo, configIdoInfo])

  async function onSwap(isTokenTrade: boolean | undefined) {
    setIsShowBalanceError(false)

    if (ethers.utils.parseUnits(fromInputValue,18).gt(ethers.utils.parseUnits(userBalance?.toSignificant(18).toString() || "1", 18))) {
      console.log("insuffient")
      setIsShowBalanceError(true)
      return
    }
    if (fixedSwapContract) {
      console.log("fixedSwapContract", fixedSwapContract)
      if (isTokenTrade) {
        if (approval !== ApprovalState.APPROVED) {
          console.log("not approve")
          approveCallback()
        } else {
          await fixedSwapContract
            .swap(ethers.utils.parseUnits(toInputValue, 18))
            .then((response: TransactionResponse) => {
              console.log("response", response)
              toggleIdoModal()
              addTransaction(response, {
                summary: `Add ${fromInputValue} ${idoInfo.useToken?.symbol} and ${toInputValue} ${idoInfo?.tokenCode}`
              })
            })
            .catch((error: any) => {
              alert(error.message)
              console.log(error)
            })
        }
      } else {
        await fixedSwapContract 
        .swap(ethers.utils.parseUnits(toInputValue, 18),
          {"value": ethers.utils.parseUnits(fromInputValue, 18)})
          .then((response: TransactionResponse) => {
            console.log("response", response)
            addTransaction(response, {
              summary: `Add ${fromInputValue} ${idoInfo.useToken?.symbol} and ${toInputValue} ${idoInfo?.tokenCode}`
            })
          })
          .catch((error: any) => {
            alert(error.message)
            console.log(error)
          })
      }
    }
  }
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)
  const onUserInput = (fromValue: string) => {
    if (fromValue === '' || inputRegex.test(fromValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))) {
      setToInputValue("")
      if (Number(fromValue) !== 0) {
        const bigNumberValue = ethers.utils.parseUnits(fromValue, 18)
        const ratio = BigNumber
          .from(10)
          .pow(idoInfo?.useToken?.decimals || 6)
          .div(idoInfo?.tradeValue || 1)
          setToInputValue(ethers.utils.formatUnits(ratio.mul(bigNumberValue), 18))
      }
      setFromInputValue(fromValue)
    }
  }
  return (
    <STS>
      <Container>
        <IdoDetailContent>
          <IdoContent>
            <IdoCurrency>
              <IdoCurrencyImg src={getTokenLogoURL(idoInfo.tokenCode)} />
              <IdoCurrencyRight>
                <IdoNameWraper>
                  <IdoName>{idoInfo.poolName}</IdoName>
                </IdoNameWraper>
                <IdoContract>
                  {contractAddress}
                </IdoContract>
              </IdoCurrencyRight>
            </IdoCurrency>
            <IdoInfo>
              <SwapAmount>
                <TitleText>
                  {t("swap_amount")}
                </TitleText>
                <AmountContent>
                  <TotalAmount>
                    {numeral(idoInfo.tokensForSale?.div(String(10 ** 18)).toNumber().toLocaleString("en")).format('0,0')} {idoInfo.tokenCode}
                  </TotalAmount>
                  <RatioNumber>
                    1 {idoInfo?.isTokenTrade ? idoInfo?.useToken?.symbol : "ETH"} = {numeral(BigNumber.from(10).pow(idoInfo?.isTokenTrade ? (idoInfo?.useToken?.decimals || 6) : 18).div(idoInfo.tradeValue || 1).toString()).format('0,0')} {idoInfo.tokenCode}
                  </RatioNumber>
                </AmountContent>
              </SwapAmount>
              <ProgressWraper>
                <TitleText>
                  {t("swap_progress")}
                </TitleText>
                <ProgressContent>
                  <Progress
                    strokeLinecap="square"
                    percent={ethers.FixedNumber.from(idoInfo?.tokensAllocated?.toString() || "1").divUnsafe(ethers.FixedNumber.from(idoInfo?.tokensForSale?.toString() || "1")).mulUnsafe(ethers.FixedNumber.from("100")).toUnsafeFloat()}
                    showInfo={false}
                  />
                  <ProgressTextWraper>
                    <ProgressTextContent>
                    {ethers.FixedNumber.from(idoInfo?.tokensAllocated?.toString() || "1").divUnsafe(ethers.FixedNumber.from(idoInfo?.tokensForSale?.toString() || "1")).mulUnsafe(ethers.FixedNumber.from("100")).toUnsafeFloat()} %
                    </ProgressTextContent>
                    <ProgressTextContent>
                      {numeral(idoInfo.tokensAllocated?.div(String(10 ** 18)).toString()).format('0,0')} / {numeral(idoInfo.tokensForSale?.div(String(10 ** 18)).toString()).format('0,0')}
                    </ProgressTextContent>
                  </ProgressTextWraper>
                </ProgressContent>
              </ProgressWraper>
              <CountDownWraper>
                <TitleText >
                  {t("end_in")}
                </TitleText>
                {idoInfo.endDate?.mul(1000).gt(String(Date.now())) ? (
                  <Countdown
                    date={idoInfo.endDate?.mul(1000).toNumber() || 1}
                    renderer={({ days, hours, minutes, seconds, completed }) => {
                      return (
                        <StyledCountdown>
                          {days}d:{hours}h:{minutes}m:{seconds}s
                        </StyledCountdown>
                        )
                    }}
                  />
                ) : <b>Closed</b>}
              </CountDownWraper>
              {approval !== ApprovalState.APPROVED && idoInfo.isTokenTrade? (
                <Button
                  onClick={() => approveCallback()}
                  disabled={
                    approval === ApprovalState.PENDING ||
                    approval === ApprovalState.UNKNOWN
                  }
                >Approve</Button>
              ) : (
              <Button
                onClick={() => {
                  // handleSwap()
                  toggleIdoModal()
                  // onSwap(idoInfo.isTokenTrade)
                  // setIsShowModal(true)

                }}>{t("join_pool")}</Button>
              )}

            </IdoInfo>
            <Row gutter={24}>
              <Col lg={12} xs={24}>
                <CardWraper>
                  <CardHeader>
                    {t("pool_information")}
                  </CardHeader>
                  <CardContent>
                    <CardContentLeft onClick={() => {onSwap(idoInfo.isTokenTrade)}}>
                      {t("token_distribution_date")}
                    </CardContentLeft>
                    <CardContentright>
                      {new Date(idoInfo.startDate?.mul(1000).toNumber() || 0).toString()}
                    </CardContentright>
                  </CardContent>
                  <CardContent>
                    <CardContentLeft>
                      {t("minimum_allocation")}
                    </CardContentLeft>
                    <CardContentright>
                      {numeral(ethers.utils.formatUnits(idoInfo.individualMinimumAmount || 1, 18)).format('0,0')}
                      &nbsp; {idoInfo?.tokenCode}
                    </CardContentright>
                  </CardContent>
                  <CardContent style={{ border: "none" }} >
                    <CardContentLeft>
                      {t("max_allocation")}
                    </CardContentLeft>
                    <CardContentright>
                      {numeral(ethers.utils.formatUnits(idoInfo.individualMaximumAmount || 1, 18)).format('0,0')}
                      &nbsp; {idoInfo?.tokenCode}
                    </CardContentright>
                  </CardContent>
                </CardWraper>
              </Col>
              <Col lg={12} xs={24}>
                <CardWraper>
                  <CardHeader>
                    {t("token_information")}
                  </CardHeader>
                  <CardContent>
                    <CardContentLeft>
                      {t("name")}
                    </CardContentLeft>
                    <CardContentright>
                      {idoInfo.tokenName}
                    </CardContentright>
                  </CardContent>
                  <CardContent>
                    <CardContentLeft>
                      {t("contract_address")}
                    </CardContentLeft>
                    <CardContentright>
                      {idoInfo.erc20}
                    </CardContentright>
                  </CardContent>
                  <CardContent style={{ border: "none" }} >
                    <CardContentLeft>
                      {t("token_supply")}
                    </CardContentLeft>
                    <CardContentright>
                      {numeral(idoInfo.tokensForSale?.div(String(10 ** 18)).toString()).format('0,0')}
                    </CardContentright>
                  </CardContent>
                </CardWraper>
              </Col>
            </Row>
            <CardWraper>
              <CardHeader>
                {t("about")}
              </CardHeader>
              <CardContentRow>
                Purchase IDO <br/>
                Use test coins to purchase IDO test items <br/>
                [User guides + IDO rules —<a href="https://app.gitbook.com/@rai-finance/s/rai-finance/how-to-guides/ido" >https://app.gitbook.com/@rai-finance/s/rai-finance/how-to-guides/ido</a>]<br/>

                Activity notice:<br/>
                1. This test activity uses the Rinkeby test chain test currency issued by the RAI Finance team, and other assets are currently not available for testing.<br/>
                2. Test users can only use their own test coins to conduct on-site test activities. Please do not transfer coins to each other. Transferring coins to each other will be considered cheating, and no rewards will be issued and result in disqualification.<br/>
                3. If you have any questions during the event, you can join the RAI Finance community for exchanges and inquiries
              </CardContentRow>
            </CardWraper>
          </IdoContent>
          {idoModalOpen ? (
            <Modal isOpen={idoModalOpen} onDismiss={toggleIdoModal} minHeight={false} maxHeight={90} >
              <Wraper>
                <ModalHeader>
                  <HeaderTitle>{t("join_pool")}</HeaderTitle>
                  <CloseIcon onClick={toggleIdoModal} >
                    <CloseColor/>
                  </CloseIcon>
                </ModalHeader>
                <ModalContent>
                  <InputWraper>
                    <ItemWraper>
                      <InputItem>{t("from")}</InputItem>
                      <BalanceWraper>{t("avaliable_balance")} &nbsp;
                        {Number(userBalance?.toSignificant(4)).toLocaleString("en")}
                        {idoInfo?.useToken?.symbol}</BalanceWraper>
                    </ItemWraper>
                    <StyleMaxButton onClick={() => {
                      onUserInput(userBalance?.toSignificant(4) || "")
                    }}>{t("max")}</StyleMaxButton>
                    <Input
                      size={"large"}
                      addonAfter={idoInfo?.useToken?.symbol}
                      value={fromInputValue}
                      onChange={(e) => {
                        onUserInput(e.target.value)
                        // setToInputValue(String(ratio * Number(value)))
                      }}
                    />
                  </InputWraper>
                  <SVGWraper>
                    <ArrowDownOutlined />
                  </SVGWraper>
                  <InputWraper>
                    <ItemWraper>
                      {t("to")}
                    </ItemWraper>
                    <Input
                      size={"large"}
                      addonAfter={idoInfo?.tokenCode}
                      value={toInputValue}
                      onChange={(e) => {
                        setToInputValue(e.target.value)
                      }}
                    />
                  </InputWraper>
                  <ButtonWraper>
                    {isShowBalanceError ? (
                      <StyledError>
                        {t("you_have_insufficient")} {idoInfo?.useToken?.name}
                      </StyledError>
                    ) : ""}
                    <ButtonPrimary
                      onClick={() => {
                        onSwap(idoInfo.isTokenTrade)
                      }}
                    >{t("join_pool")}</ButtonPrimary>
                  </ButtonWraper>
                </ModalContent>
              </Wraper>
            </Modal>
          ) : ""}
        </IdoDetailContent>
      </Container>
    </STS>
  )
}
const StyleMaxButton = styled.span`
  cursor: pointer;
  z-index: 2;
  position: absolute;
  bottom: 9px;
  right: 76px;
  font-size: 12px;
  color: ${({ theme }) => theme.bg6};
  background-color:#F4F5F8;
  padding: 2px 8px;
`
const StyledError = styled.div`
  color: #FF0202;
  font-size: 12px;
  margin-bottom: 7px;
`

const CloseColor = styled(Close)`
  color: #FFFFFF;
`
const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  right: 48px;
  transform: translate(0, -50%);
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const HeaderTitle = styled.div`
  color: #FFFFFF;
  font-size: 24px;
`
const ModalContent = styled.div`
  padding: 30px;
`
const ModalHeader = styled.div`
  position: relative;
  height: 85px;
  padding: 27px 48px 28px 59px;
  background-color: ${({ theme }) => theme.bg6};
`
const IdoDetailContent = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
`
const IdoContent = styled.div`
`
const IdoCurrency = styled.div`
  display: flex;
  align-items: flex-end;
`
const IdoCurrencyImg = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
`
const TitleText = styled.div`
  font-size: 14px;
`
const IdoInfo = styled.div`
  width: 100%;
  padding: 26px 50px;
  background-color: #FFFFFF;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    align-items: flex-start;
    .ant-btn {
      margin: 0 auto;
    }
  }
  
`
const TotalAmount = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #000000;
`
const RatioNumber = styled.div`
  font-size: 12px;
`
const SwapAmount = styled.div`
 @media (max-width: 767px) {
   display: flex;
 }
`
const ProgressWraper = styled.div`

  .ant-progress-line {
    width: 313px;
  }
  @media(max-width: 767px) {
    display: flex;
    width: 100%;
  .ant-progress-line {
    width: 100%;
  }
  }
`
const ProgressTextWraper = styled.div`
  display: flex;
  justify-content: space-between;
`
const ProgressTextContent = styled.div`
  color: #666666;
  font-size: 12px;
  margin-top: 8px;
`
const CountDownWraper = styled.div`
  @media (max-width: 767px) {
    display: flex;
    b {
      margin-left: 19px;
    }
  }
`
const IdoCurrencyRight = styled.div`
  margin-left: 19px;
`
const IdoNameWraper = styled.div`
  display: flex;
`
const IdoName = styled.span`
  font-size: 24px;
  margin-right: 20px;
`
const IdoContract = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #000000;
  @media (max-width: 767px) {
    width: 200px;
    word-break: break-all;
  }
`
const StyledCountdown = styled.div`
  font-size: 24px;
  color: #000000;
  margin-top: 6px;
  @media(max-width: 767px) {
    margin-left: 19px;
  }
`
const CardWraper = styled.div`
  margin-bottom: 15px;
  background-color: #FFFFFF;
  padding-bottom: 30px;
/* &> .CardContent:last-child {
  border-bottom: none;
} */
`
const CardHeader = styled.div`
  border-bottom: 2px solid #F4F5F8;
  padding: 25px 0 16px 51px;
  color: #0062DF;
  font-size: 18px;
  font-weight: 600;
`
const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 16px 50px;
  border-bottom: 2px solid #F4F5F8;
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }

`
const CardContentLeft = styled.div`
  color: #666666;
`
const CardContentright = styled.div`
  color: #000000;
  @media(max-width: 767px) {
    width: 200px;
    word-break: break-all;
  }
`
const CardContentRow = styled.div`
  padding: 27px 52px 44px 52px;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
`
const InputWraper = styled.div`
  position: relative;
`
const ItemWraper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const InputItem = styled.span`
  font-size: 14px;
  color: #000000;
`
const BalanceWraper = styled.div`
  color: #666666;
  font-size:12px;
`
const SVGWraper = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
svg {
  color: #0062DF;
  width: 16px;
}
`
const ButtonWraper = styled.div`
  min-height: 67px;
  margin-top: 53px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`
const Wraper = styled.div`
  width: 430px;
  display: flex;
  flex-direction: column;
`

const AmountContent = styled.div`
  @media (max-width: 767px) {
    margin-left: 19px;
    flex: 2;
  }
`

const ProgressContent = styled.div`
  @media (max-width: 767px) {
   margin-left: 19px; 
   flex: 2;
  }
`