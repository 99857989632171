import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	IChartingLibraryWidget,
	ResolutionString,
} from '../../../charting_library/charting_library';
import { useActiveWeb3React } from '../../../hooks'
import { DEFAULT_API_URL, APILIST } from '../../../constants'

export interface ChartContainerProps {
	isAggregate?: boolean;
	symbol?: ChartingLibraryWidgetOptions['symbol'];
	interval?: ChartingLibraryWidgetOptions['interval'];

	// BEWARE: no trailing slash is expected in feed URL
    datafeedUrl?: string;
    
	libraryPath?: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl?: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion?: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId?: ChartingLibraryWidgetOptions['client_id'];
	userId?: ChartingLibraryWidgetOptions['user_id'];
	fullscreen?: ChartingLibraryWidgetOptions['fullscreen'];
	autosize?: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides?: ChartingLibraryWidgetOptions['studies_overrides'];
	containerId?: ChartingLibraryWidgetOptions['container_id'];
}

function getLanguageFromURL(): LanguageCode | null {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}

export default function Chart(props: ChartContainerProps) {
  const { chainId, account } = useActiveWeb3React()
  const { i18n }: any = useTranslation()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];

	const defaultProps: ChartContainerProps = {
		symbol: 'USDT-RAI',
		interval: '15' as ResolutionString,
		containerId: 'tv_chart_container',
		datafeedUrl: props.isAggregate ? `${api_url}/proxy` : api_url,
		libraryPath: '/charting_library/',
		chartsStorageUrl: '',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	let tvWidget: IChartingLibraryWidget | null = null;

	useEffect(() => {
		if (!props.symbol) {
			return;
		}
		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: props.symbol as string,
			// BEWARE: no trailing slash is expected in feed URL
			// tslint:disable-next-line:no-any
			datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
			interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
			container_id: defaultProps.containerId as ChartingLibraryWidgetOptions['container_id'],
			library_path: defaultProps.libraryPath as string,

			locale: i18n.language === 'en' ? "en" : "ko" as LanguageCode,
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			preset: document.body.clientWidth < 720 ? 'mobile': undefined
		};

		tvWidget = new widget(widgetOptions);
		tvWidget.onChartReady(() => {
			if (tvWidget) {
				tvWidget.headerReady().then(() => {
					if (tvWidget) {
						const button = tvWidget && tvWidget.createButton();
						button.setAttribute('title', 'Click to show a notification popup');
						button.classList.add('apply-common-tooltip');
						button.addEventListener('click', () => tvWidget && tvWidget.showNoticeDialog({
							title: 'Notification',
							body: 'TradingView Charting Library API works correctly',
							callback: () => {
								console.log('Noticed!');
							},
						}));
						button.innerHTML = 'Check API';
					}
				});
			}
        });
        return () => {
            if (tvWidget !== null) {
                tvWidget.remove();
                tvWidget = null;
            }
        }
	}, [props.symbol, i18n.language])

	return (
		<div
			id={defaultProps.containerId }
			className={'TVChartContainer'}
			style={{height: 'calc(100% - 120px)'}}
		/>
	);
}
