import React, {useState} from 'react'
import styled from 'styled-components'
import { ethers, BigNumber } from 'ethers';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ChainId, Currency, JSBI, TokenAmount } from '@uniswap/sdk'
import { TransactionResponse } from '@ethersproject/providers'
import { CheckCircle } from 'react-feather'
import { useActiveWeb3React } from '../../hooks'
import { TRAI, TSOFI } from '../../constants'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { useModalOpen, useIdoModalToggle } from 'state/application/hooks'
import { ButtonPrimary, ButtonLight } from 'components/Button'
import { RowBetween, RowFixed } from 'components/Row'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { configIdoList } from '../../config'
import { useReedemContract } from 'hooks/useContract'
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback'
import { useTransactionAdder } from 'state/transactions/hooks'
import { ReactComponent as Close } from 'assets/images/x.svg'
import MetaMaskLogo from 'assets/images/metamask.png'

const Wraper = styled.div`
  width: 430px;
  display: flex;
  flex-direction: column;
`
const ModalContent = styled.div`
  padding: 30px;
`
const ModalHeader = styled.div`
  position: relative;
  height: 85px;
  padding: 27px 30px 28px 30px;
  background-color: ${({ theme }) => theme.bg6};
`
const HeaderTitle = styled.div`
  color: #FFFFFF;
  font-size: 24px;
`
const CloseColor = styled(Close)`
  color: #FFFFFF;
`
const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const InputWraper = styled.div`
  position: relative;
  .ant-input[disabled]{
      background-color: #fff;
      color: #000;
  }
`
const ItemWraper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const InputItem = styled.span`
  font-size: 14px;
  color: #000000;
`
const BalanceWraper = styled.div`
  color: #666666;
  font-size:12px;
`
const SVGWraper = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
svg {
  color: #0062DF;
  width: 16px;
}
`
const ButtonWraper = styled.div`
  min-height: 67px;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

const ReedemModal = ({isOpen, handleCancel, managerInfo}: any) => {
    const contractAddress = '0xf1b2104b4f02e0ce5116f4dc820a405ec6ecb0d1';
    const { chainId, account, library } = useActiveWeb3React()
    const [approval, approveCallback] = useApproveCallback(new TokenAmount(TRAI[chainId ?? 4], JSBI.BigInt(100000000000000000000)), contractAddress)
    const userBalance = useCurrencyBalance(account ?? undefined, TRAI[chainId ?? 4])
    const contract = useReedemContract(contractAddress)
    const addTransaction = useTransactionAdder()
    const { addToken, success } = useAddTokenToMetamask(TSOFI[chainId ?? 4])

    const { t } = useTranslation()

    async function onReedem() {
      console.log('rai:', userBalance?.raw.toString())
      if (contract && userBalance?.toSignificant(18).toString()) {
        console.log("contract", contract)
        if (approval !== ApprovalState.APPROVED) {
          console.log("not approve");
          approveCallback()
        } else {
          await contract
            .swap(`0x${userBalance?.raw.toString(16)}`)
            .then((response: TransactionResponse) => {
              console.log("response", response)
              addTransaction(response, {
                summary: `Reedem RAI to SOFI`
              })
            })
            .catch((error: any) => {
              alert(error.message)
              console.log(error)
            })
        }
      }
    }
    
    return (
        <Modal visible={isOpen} footer={null} width={430} bodyStyle={{padding: 0}} closable={false}>
            <Wraper>
                <ModalHeader>
                  <HeaderTitle>Convert to SOFI</HeaderTitle>
                  <CloseIcon onClick={handleCancel} >
                    <CloseColor/>
                  </CloseIcon>
                </ModalHeader>
                <ModalContent>
                  <InputWraper>
                    <ItemWraper>
                      <InputItem>{t("from")}</InputItem>
                      <BalanceWraper>{t("avaliable_balance")} &nbsp;
                        {Number(userBalance?.toSignificant(4)).toLocaleString("en")}
                        RAI</BalanceWraper>
                    </ItemWraper>
                    {/* <StyleMaxButton onClick={() => {
                      onUserInput(userBalance?.toSignificant(4) || "")
                    }}>{t("max")}</StyleMaxButton> */}
                    <Input
                      size={"large"}
                      addonAfter={"RAI"}
                      value={Number(userBalance?.toSignificant(4)).toLocaleString("en")}
                      disabled
                      // onChange={(e) => {
                      //   onUserInput(e.target.value)
                      //   // setToInputValue(String(ratio * Number(value)))
                      // }}
                    />
                  </InputWraper>
                  <SVGWraper>
                    <ArrowDownOutlined/>
                  </SVGWraper>
                  <InputWraper>
                    <ItemWraper>
                      {t("to")}
                    </ItemWraper>
                    <Input
                      size={"large"}
                      addonAfter={"SOFI"}
                      value={Number(userBalance?.toSignificant(4)).toLocaleString("en")}
                      disabled
                      // onChange={(e) => {
                      //   setToInputValue(e.target.value)
                      // }}
                    />
                  </InputWraper>
                  <h2 style={{textAlign:'right'}}>1 RAI = 1 SOFI</h2>
                  <ButtonWraper>
                  {approval !== ApprovalState.APPROVED ? (
                    <ButtonPrimary
                      onClick={() => approveCallback()}
                      disabled={
                        approval === ApprovalState.PENDING ||
                        approval === ApprovalState.UNKNOWN
                      }
                    >Approve</ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                    onClick={() => {
                      onReedem()
                    }}
                  >Swap</ButtonPrimary>
                  )}
                  </ButtonWraper>
                  <p>Contract Address: <a target="_blank" href="https://rinkeby.etherscan.io/token/0xb5781d40827625e28da52e688432474146c74a40">0xb5781d40827625e28da52e688432474146c74a40</a></p>
                  {library?.provider?.isMetaMask && (
                    <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
                      {!success ? (
                        <RowFixed>
                          Add SOFI to Metamask <StyledLogo src={MetaMaskLogo} />
                        </RowFixed>
                      ) : (
                        <RowFixed>
                          Added SOFI{' '}
                          <CheckCircle size={'16px'} stroke={'#27AE60'} style={{ marginLeft: '6px' }} />
                        </RowFixed>
                      )}
                    </ButtonLight>
                  )}
                </ModalContent>
              </Wraper> 
        </Modal>
    ) 
}

export default ReedemModal;