import { ChainId, Currency, Token } from '@uniswap/sdk'
import { TUSDT } from './constants'
export interface configInfoInterface {
  poolName: string;
  tokenName: string;
  tokenDescription: string;
  tokenLogo: string;
  chainId: number,
  tokenCode: string;
  decimals: number;
  contractAddress: string;
  useToken: { [chainId in ChainId]: Token };
  useTokenSymbol: string;
  rate: number;
}
export const configIdoList: { [contractAddress: string]: configInfoInterface } = {
  "0x7dd7fc6232abcedd5785b40f2244f14d1891acc4": {
    poolName: "TEST",
    tokenName: "TEST",
    chainId: 4,
    tokenLogo: "./images/token/rai.png",
    useToken: TUSDT,
    useTokenSymbol: "USDT",
    tokenCode: "TEST",
    decimals: 18,
    rate: 1,
    contractAddress: "0x7dd7fc6232abcedd5785b40f2244f14d1891acc4",
    tokenDescription: ""
  }
}
