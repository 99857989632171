import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
// import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import menuIcon from '../../assets/images/ido/vector.png'
import { useDarkModeManager } from '../../state/user/hooks'
import { Link } from "react-router-dom";
import { useActiveWeb3React } from '../../hooks'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  img {
    margin-left: 22px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    @media(max-width: 767px) {
      margin-left: 0;
    }
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 12.125rem;
  background-color: ${({ theme }) => theme.bg6};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid #C4C4C4;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`

  `};
`

const MenuItem = styled.div`
  cursor: pointer;
  flex: 1;
  padding: 0.25rem 0.5rem;
  color: #C4C4C4;
`
const LanguageWraper = styled.div`
  padding: 0.5rem 0.5rem;
`

const SelectTag = styled.span`
  font-size: 12px;
  padding: 2px 10px;
  width: 40px;
  height: 20px;
  border: 1px solid #C4C4C4;
  color: #666666;
  background-color: #FFFFFF;
  margin-right: 9px;
  cursor: pointer;
`

export default function Menu() {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)
  const [isShowLanguageSelect, setIsShowLanguageSelect] = useState(true)
  const [isShowNetworkSelect, setIsShowNetworkSelect] = useState(true)
  const [isShowThemeSelect, setIsShowThemeSelect] = useState(true)
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const location = useLocation()
  const { pathname } = location
  const isIdoPage = pathname.indexOf("/ido") > -1
  // const [ i18n ] = useTranslation();
  const changeLocale = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('rai_lang', selectedLanguage)
}
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <img src={menuIcon} alt="menu_tools" />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          {isIdoPage ? (
            <Link to={`/idoHistory/${account}`} >
              <MenuItem>
                {t("ido_history")}
              </MenuItem>
            </Link>
          ) : ""}
          <MenuItem onClick={() => {setIsShowLanguageSelect(!isShowLanguageSelect)}}>
            {t("language")}
          </MenuItem>
          {isShowLanguageSelect ? (
            <LanguageWraper>
              <SelectTag onClick={() => {changeLocale("ko")}}>한국어</SelectTag>
              <SelectTag onClick={() => {changeLocale("en")}} >English</SelectTag>
            </LanguageWraper>
          ) : ""}

          <MenuItem onClick={() => {setIsShowNetworkSelect(!isShowNetworkSelect)}}>
            {t("network")}
          </MenuItem>
          {isShowNetworkSelect ? (
            <LanguageWraper>
              <SelectTag>Rinkeby</SelectTag>
            </LanguageWraper>
          ): ""}
          <MenuItem onClick={() => {setIsShowThemeSelect(!isShowThemeSelect)}}>
            {t("theme")}
          </MenuItem>
          {isShowThemeSelect ? (
            <LanguageWraper>
              <SelectTag onClick={() => !darkMode && toggleDarkMode()}>blue</SelectTag>
              <SelectTag onClick={() => darkMode && toggleDarkMode()} >purple</SelectTag>
            </LanguageWraper>
          ): ""}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
