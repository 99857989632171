import React, { useContext } from 'react'
import Modal from '../../../components/Modal'
import { AutoColumn, ColumnCenter } from '../../../components/Column'
import { RowBetween, RowFixed } from '../../../components/Row'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
import { CloseIcon, CustomLightSpinner } from '../../../theme/components'
import { ArrowDown, AlertTriangle } from 'react-feather'
import CurrencyLogo from '../../../components/CurrencyLogo'
import { TruncatedText, SwapShowAcceptChanges } from '../../../components/swap/styleds'
import { ButtonPrimary } from '../../../components/Button'
import { Token } from '@uniswap/sdk'

interface AggregateModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  handleSwap: () => void;
  inputValue: string;
  outputValue: string;
  inputCurrency: Token | undefined;
  outputCurrency: Token | undefined;
}

export default function AggregateModal({
    isOpen,
    onDismiss,
    handleSwap,
    inputValue,
    outputValue,
    inputCurrency,
    outputCurrency
  }: AggregateModalProps) {
    // confirmation screen
    const theme = useContext(ThemeContext)
    return (
      <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
        <Wrapper>
        <Section>
          <RowBetween justify="space-between" >
            <Text fontWeight={500} fontSize={20}>
              Confirm Swap
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
            <RowBetween align="flex-end">
              <RowFixed gap={'0px'}>
                <CurrencyLogo currency={inputCurrency} size={'24px'} style={{ marginRight: '12px' }} />
                <TruncatedText
                  fontSize={24}
                  fontWeight={500}
                  color={theme.primary1}
                >
                  {inputValue}
                </TruncatedText>
              </RowFixed>
              <RowFixed gap={'0px'}>
                <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
                  {inputCurrency?.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
            <RowFixed>
              <ArrowDown size="16" color={theme.text2} style={{ marginLeft: '4px', minWidth: '16px' }} />
            </RowFixed>
            <RowBetween align="flex-end">
              <RowFixed gap={'0px'}>
                <CurrencyLogo currency={outputCurrency} size={'24px'} style={{ marginRight: '12px' }} />
                <TruncatedText
                  fontSize={24}
                  fontWeight={500}
                  color={theme.primary1}
                >
                  {outputValue}
                </TruncatedText>
              </RowFixed>
              <RowFixed gap={'0px'}>
                <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
                  {outputCurrency?.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
          </AutoColumn> 
        </Section>
        <BottomSection gap="12px">
          <ButtonPrimary onClick={handleSwap} >Confirm Swap</ButtonPrimary>
        </BottomSection>
        </Wrapper>
      </Modal>
    )
  }

const Section = styled(AutoColumn)`
  padding: 24px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const Wrapper = styled.div`
  width: 100%;
`