import React, { useEffect, useState } from 'react'
import { Button, Radio } from 'antd';
import numeral from 'numeral'
import moment from 'moment';
import styled from 'styled-components'
import {useTokenChartData} from './hooks'

import { ResponsiveContainer, AreaChart, Area, LineChart, Line, YAxis, Tooltip, XAxis } from 'recharts'

interface TokenChartProps {
    data: string
}

const TokenChart: React.FC<TokenChartProps> = ({data}: any) => {
  const formatToolTip = (data: any) => {
    if (!data) return ['--', 'No Data Available']
    const {
      payload: { value, ts },
    } = data
    const timeString = moment(ts*1000).format('YYYY-MM-DD HH:mm:ss');
    console.log("value-ts",value, ts)
    return [timeString, `$${numeral(value).format('0,0.00')}`]
  }

  const renderTooltip = (props: any) => {
    const tooltipData = props.payload?.[0]
    const [label, value] = formatToolTip(tooltipData)

    return <TooltipBox>
      <p>{label}</p>
      <p>Price: {value}</p>
    </TooltipBox>
  }


  return (
    <Container>
      <ChartContainer>
        <AreaChart
          data={data}
        >
          <Area
            type='monotone'
            dataKey='value'
            dot={false}
            stroke="#F64242" 
            fill="rgba(252, 194, 194, 0.3)" 
            animationEasing='ease'
            animationDuration={800}
          />
         <YAxis
            axisLine={false}
            tickLine={false}
            mirror={true}
            orientation='right'
            dataKey="value"
            hide={true}
            dy={7}
            dx={1}
            domain={['dataMin', 'dataMax']}
          />
          <XAxis  
            axisLine={false}
            tickLine={false}
            dataKey="ts"
            hide={true}
          />
          <Tooltip
            content={renderTooltip}
          />
          <defs>
            <linearGradient id='gradient' gradientTransform='rotate(90)'>
              <stop offset='5%' stopColor='#8150E6' />
              <stop offset='95%' stopColor='#E825A3' />
            </linearGradient>
          </defs>
        </AreaChart>
      </ChartContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 60x;
  position: relative;
`

const ChartContainer = styled(ResponsiveContainer)`
  min-height: 60px;
`

const TooltipBox = styled.div`
  border: 1px solid rgba(252, 194, 194, 0.3);
  padding: 4px;
  p{
    margin: 0;
    font-size: 10px;
  }
`


export default TokenChart
