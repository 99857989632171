import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import AddLiquidity from '../Swap/components/AddLiquidity'
import { RouteComponentProps } from 'react-router-dom'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import Pairs from './components/liquidityPair'
import { usePairs, PairState } from '../../data/Reserves'
import PairsArr from '../../pairs_develop.json'
import JSBI from 'jsbi';
import { Currency } from '@uniswap/sdk';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import STS from '../STS'
import { Container } from '../STS/Markets'
 interface liquidityInterface {
  currencyA: string;
  currencyB: string;
 }



export default function Liquidity(props: RouteComponentProps<{ currencyA: string, currencyB: string }>) {
  const { t } = useTranslation()
  const {
    match: {
      params: { currencyA, currencyB }
    }
  } = props
  const search = useParsedQueryString()
  let history = useHistory();
  const type: any = search.type
  const [currencyIdA, setCurrencyIdA] = useState<Currency | null | undefined>()
  const [currencyIdB, setCurrencyIdB] = useState<Currency | null | undefined>()
  const currencyObjA = useCurrency(currencyA)
  const currencyObjB = useCurrency(currencyB)
  useEffect(() => {
    setCurrencyIdA(currencyObjA)
    setCurrencyIdB(currencyObjB)
  }, [currencyA, currencyB, currencyObjA, currencyObjB]);
  const allPairsData = usePairs(PairsArr.map((pair: any) => [pair.token0, pair.token1]));
  const allPairs = PairsArr.map((pair: any, i: number) => {
      const pairInfo = allPairsData[i][1]
      return allPairsData[i][0] === PairState.EXISTS ?
          {
              ...pair,
              totalValue: pairInfo?.token1Price.raw.multiply(pairInfo?.reserve1.raw).add(pairInfo?.reserve0.raw).divide(JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(pairInfo.token0?.decimals ?? 1))).toFixed(2)
          } : { ...pair }
  });
  const [currentSymbol, setCurrentSymbol] = useState<string>(PairsArr[0].symbol);
  const [pairBase, setPairBase] = useState<any>("USDT")
  console.log("currentSymbol", currentSymbol)
  useEffect(() => {
    const currentPair = allPairs.filter((pair: any) => pair.symbol === currentSymbol)[0]
    console.log("currentPair", currentPair)
    history.replace(`/liquidity/${currentPair.base === 0 ? currentPair.token0.address : currentPair.token1.address}/${currentPair.base === 0 ? currentPair.token1.address : currentPair.token0.address}`)
  }, [currentSymbol]);
  return (
    <STS>
      <Container>
        <LiquidityWraper>
          <LiquidityTitle>
            {t("liquidity")}
          </LiquidityTitle>
          <LiquidityContent>
            <LiquidityLeft>
              <PairContent>
                {currencyIdA && currencyIdB &&
                <DoubleCurrencyLogo
                  currency0={currencyIdA}
                  currency1={currencyIdB}
                  size={34}
                  margin={true}
                />
              }
                {currencyIdA && currencyIdB && `${currencyIdB.symbol}-${currencyIdA.symbol}`}
              </PairContent>
              <AddLiquidity
                currencyIdA={currencyA}
                currencyIdB={currencyB}
                operationType={type}
              />
            </LiquidityLeft>
            <LiquidityRight>
              <Pairs
                allPairs={allPairs}
                currentPair={allPairs.filter((pair: any) => pair.symbol === currentSymbol)[0]}
                setCurrentSymbol={setCurrentSymbol}
                pairBase={pairBase}
                setPairBase={setPairBase}
              />
            </LiquidityRight>
          </LiquidityContent>
        </LiquidityWraper>
      </Container>
    </STS>
  )
}
const PairContent = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding-left: 22px;
  font-size: 24px;
  font-weight: bold;
`
const LiquidityWraper = styled.div`
  width: 100%; 
  @media(max-width: 767px) {
    width: 100%;
    padding-top: 0;
  }
`

const LiquidityTitle = styled.div`
  font-size: 24px;
  text-align: left;
  color: #0062DF;
`
const LiquidityContent = styled.div`
  display: flex;
  margin-top: 53px;
  @media(max-width: 767px) {
    flex-direction: column-reverse;
  }
`

const LiquidityLeft = styled.div`
  flex: 2;
  margin-right: 12px;
  @media(max-width: 767px) {
    margin-right: 0;
  }
`

const LiquidityRight = styled.div`
  flex: 1;
  @media(max-width: 767px) {
    margin-bottom: 20px;
  }
`


