import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, Tabs, Table } from 'antd';
import { useActiveWeb3React } from '../../../hooks'
import PairsArr from '../../../pairs.json'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral';

const { Search } = Input;
const { TabPane } = Tabs;

const PairsContent = styled.div`
height: 100%;
background: #fff;
.ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    border-bottom: 1px solid #B7CAEB;
    padding: 10px;
}
.ant-input-affix-wrapper{
    border: none;
    height: 50px;
    line-height: 50px;
    box-shadow: none;
    &-focused{
        height: 50px;
        line-height: 50px;
        box-shadow: none; 
    }
}
.ant-input-search-button{
    border: none;
    height: 50px;
    box-shadow: none;
}
.ant-input-group{
    height: 50px;
}
.ant-tabs{
    border-top: 1px solid #D2D3D7;
    .ant-tabs-nav{
        /* background: #0062DF; */
        margin: 0;
    }
    .ant-tabs-ink-bar {
        background: ${({ theme }) => theme.bg6};
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: ${({ theme }) => theme.bg6}
    }
    .ant-tabs-tab{
        color: #000000;
        padding: 0 30px;
        margin: 0;
        height:40px;
        line-height: 40px;
    }
}
.ant-table-thead > tr > th{
    background: #F9FAFC;
    &:nth-of-type(2), &:last-of-type{
        text-align: right;
    }
}
.ant-table-tbody > tr > td{
    font-size: 12px;
    cursor: pointer;
    &:nth-of-type(2), &:last-of-type{
        text-align: right;
    }
}
.ant-table-tbody > tr.ant-table-row:hover > td{
    background: #EEF4FF!important;
}
.ant-table-column-sorters{
    padding:0;
}
.ant-table-column-sort{
    background: none;
}
.ant-table-row{
    cursor: pointer;
    &.active{
        background: #EEF4FF;
    }
}
`

export default function Pairs({allPairs, currentPair, setCurrentSymbol, pairBase, setPairBase, closeDrawer} : any) {
    const { t } = useTranslation()
    const [searchText, setSearchText] = useState<any>("")
    const { chainId } = useActiveWeb3React()
    const isBNB = chainId === 56 || chainId === 97

    const onSearch = (e: any) => {
        setSearchText(e.target.value);
    }
    const handleTabchange = (key: string) => {
        setPairBase(key)
    }

    const handleRowClick = (record: any) => {
        setCurrentSymbol(record.symbol);
        if (closeDrawer) {
            closeDrawer()
        }
    }

    const columns = [
        {
            title: t("pair"),
            dataIndex: 'symbol',
            key: 'symbol',
        },
        {
            title: "Total Liquidity",
            key: 'price',
            dataIndex: 'totalValue',
            sorter: (a: any, b:any) => a.totalValue - b.totalValue,
            render: (text: any, record: any, i: number) =>
                <span>
                 {numeral(text).format("$ 0,00.00")}
                </span>
        },
        // {
        //     title: "My Liquidity",
        //     key: 'change',
        //     dataIndex: 'change',
        //     sorter: (a: any, b:any) => a.change - b.change,
        //     render: (text: any, record: any, i: number) =>
        //         <span>
        //             {
        //                 text && `${Number(text) > 0 ? '+' : ''}${text}%`
        //             }
        //         </span>
        // },
        // {
        //     title: 'Liquidty',
        //     key: 'liqudity',
        //     dataIndex: 'liqudity',
        // }
    ]

    return (
        <PairsContent>
            <Search placeholder={t("search_name_symbol_text")} allowClear onChange={onSearch} style={{ width: '100%' }} />
            <Tabs defaultActiveKey="USDT" onChange={handleTabchange}>
                <TabPane tab="USDT" key="USDT">
                </TabPane>
                {/* {!isBNB && <TabPane tab="ETH" key="WETH">
                </TabPane>}
                {isBNB && <TabPane tab="BNB" key="BNB">
                </TabPane>} */}
            </Tabs>
            <Table
                columns={columns}
                dataSource={allPairs.filter((pair: any) => (!searchText && (pair.token0.symbol === pairBase || pair.token1.symbol === pairBase) || (searchText && pair.symbol.includes(searchText.toUpperCase()))) && pair.chainId === chainId)}
                pagination={false}
                size="small"
                showSorterTooltip={false}
                rowClassName={(record) => `${currentPair && record.id === currentPair.id && chainId === record.chainId ? 'active' : ''}`}
                onRow={record => {
                    return {
                        onClick: event => {
                          handleRowClick(record)  
                        }, // 点击行
                    };
                }}
            />
        </PairsContent>
    )
}
