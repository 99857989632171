import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'ethers';
import numeral from 'numeral';
import { useWeb3React } from '@web3-react/core'
import ethers from 'ethers'
import { Select, Input, Table, Progress } from 'antd'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import IDOBanner from '../../assets/images/IDO.png'
// import { useSingleCallResult } from '../../state/multicall/hooks'
// import useIdoInfo from '../../hooks/useIdoInfo'
import useIdoList from '../../hooks/useIdoList'
import { ButtonPrimary } from '../../components/Button'

import STS from '../STS'
import { Container } from '../STS/Markets'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'

interface idoInfoInterface {
  poolName?: string;
  tokenName?: string;
  tokenDescription?: string;
  tokenLogo?: string;
  tokenCode?: string;
  erc20?: string;
  individualMinimumAmount?: BigNumber;
  individualMaximumAmount?: BigNumber;
  desc: number;
  tradeValue?: number;
  startDate?: BigNumber;
  endDate?: BigNumber;
  tokensAllocated?: BigNumber;
  tokensForSale?: BigNumber;
  isSaleFunded?: boolean;
  contractAddress?: string;
  isOpen?: string;
}

const { Option } = Select
const { Search } = Input
const IdoWraper = styled.div`
  width: 100%;
`
const IdoBanner = styled.div`
  width: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
  }
`
const IdoContentTop = styled.div`
  display: flex;
  justify-content:space-between;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 39px;
  padding-bottom: 42px;
`

const IdoContent = styled.div`
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    border-bottom: 3px solid #E5E5E5;
  }
  .ant-table-thead > tr > th {
    background: #F9FAFC;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`
const PoolNameColumn = styled.div`
  img {
    width: 36px;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
  }
`

const PoolStatusTage = (record: idoInfoInterface | null) => {
  let backgroundColor = ""
  let textColor = ""
  let text = ""
  let type = ""
  if (record?.isOpen) {
    if (record.isSaleFunded) {
      type ="open"
    } else {
      type = "filled"
    }
  } else {
    type = "close"
  }
  switch (type) {
    case "open":
      backgroundColor = "#CCFFDA"
      textColor = "#03CE3C"
      text = "Open"
      break;
    case "filled":
      backgroundColor = "#EEF4FF"
      textColor = "#0062DF"
      text = "Filled"
      break;
    case "close":
      backgroundColor = "#FFE1E7"
      textColor = "#FF0202"
      text = "Close"
      break;
    default:
      break;
  }

  return (
    <PoolStatusWraper backgroundColor={backgroundColor} >
      <PoolStatusText textColor={textColor} >
        {text}
      </PoolStatusText>
    </PoolStatusWraper>
  )

}

export default function Ido () {
  const { t } = useTranslation();
  const { chainId } = useWeb3React()
  const columns = [
    {
      title: t("pool_name"),
      dataIndex: "poolName",
      render: (text: string, record: idoInfoInterface) => {
        return (
          <PoolNameColumn>
            <img src={getTokenLogoURL(record.tokenCode)} alt=""/>
            <span>{record.poolName}</span>
          </PoolNameColumn>
        )
      }
    },
    {
      title: t("ratio"),
      dataIndex: "tradeValue",
      render: (text: BigNumber, record: any) => {
        if (!text) return ""
        return (
          <span>1 {record?.isTokenTrade ? record?.useToken[chainId || 4].symbol : "ETH"} = {numeral(BigNumber.from(10).pow(record?.isTokenTrade ? record?.useToken[chainId || 4].decimals : 18).div(text).toNumber()).format('0,0')} {record.tokenCode}</span>
        )
      }
    },
    {
      title: t("progress"),
      dataIndex: "progress",
      render: (text: string, record: any) => {
        return (
          <Progress
            type="circle"
            percent={Number(ethers.FixedNumber.from(record?.tokensAllocated?.toString() || "1").divUnsafe(ethers.FixedNumber.from(record?.tokensForSale?.toString() || "1")).mulUnsafe(ethers.FixedNumber.from("100")).toUnsafeFloat().toFixed(2))}
            width={42}
          />
        )
      }
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (record: idoInfoInterface) => {
        return PoolStatusTage(record)
      }
    },
    {
      title: "",
      dataIndex: "contractAddress",
      fixed: "right",
      render: (record: idoInfoInterface) => {
        const renderButton = () =>{
          if (record?.isOpen) {
            if (record.isSaleFunded) {
              return <ButtonPrimary>{t("detail")}</ButtonPrimary>
            } else {
              return <ButtonPrimary disabled>Filled</ButtonPrimary>
            }
          } else {
            return <ButtonPrimary disabled>Closed</ButtonPrimary>
          }
        }
        return (
          <Link to={{
            pathname: `/ido/${record.contractAddress}`
          }} >
            {renderButton()}
          </Link>
        )
      }
    }
  ]

  const [ idoList, setIdoList ] = useState<idoInfoInterface[]>([])
  const [searchText, setSearchText] = useState("")
  const [selectDefaultValue, setSelectDefaultValue] = useState("USDT");
  // const contractAddress = "0x6d21F3c0BF3D153cC8A225F3310720b6Df8975CA"
  // const resIdoInfo: any = useIdoInfo(contractAddress)
  const resIdoList: any = useIdoList()
  useEffect(() => {
    setIdoList(resIdoList)
  }, [resIdoList])
  const onSearch = (e: any) => {
    setSearchText(e.target.value)
  }
  return (
    <STS>
      <Container>
        <IdoWraper>
          <IdoBanner>
            <img src={IDOBanner} alt="banner" />
          </IdoBanner>
          <IdoContentTop>
            <Select
                defaultValue={selectDefaultValue}
                size={"large"}
                style={{ width: 120 }}
                onChange={(value) => {
                console.log(value)
                setSelectDefaultValue(value)
              }}>
                {/* ETH network */}
                {
                  chainId === 4 ? <>
                    <Option value="ETH">ETH</Option>
                    <Option value="USDT">USDT</Option>
                  </> : ""
                }
                {/* BNb network */}
                {
                  chainId === 97 ? <>
                    <Option value="BNB">BNB</Option>
                    <Option value="BETH">BETH</Option>
                    <Option value="USDT">USDT</Option>
                  </> : ""
                }
              </Select>
              <Search
                style={{ width: 450 }}
                placeholder={t("search_name_symbol_text")}
                allowClear
                onChange={onSearch}
                size={"large"}
              />
          </IdoContentTop>
          <IdoContent>
            <Table
              columns={columns as any}
              dataSource={resIdoList.filter((idoData: any) => {
                console.log("idoData.useTokenSymbol", idoData.useTokenSymbol)
                if (!searchText) return idoData.useTokenSymbol === selectDefaultValue
                return (
                    idoData.useTokenSymbol === setSelectDefaultValue
                  || idoData.tokenCode === searchText
                  || idoData.useToken.symbol === searchText
                  || idoData.poolName === searchText)
              })}
              loading={resIdoList.length ? false : true}
              scroll={{ x: true }}
            />
          </IdoContent>
        </IdoWraper>
      </Container>
    </STS>
  )
}
  const PoolStatusWraper = styled.div<{backgroundColor: string}>`
    width: 85px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    background-color: ${props => props.backgroundColor};
    text-align: center;
  `
  const PoolStatusText = styled.span<{textColor: string}>`
    position: relative;
    color: ${props => props.textColor};
    transform: translate(12px, 0);
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-12px, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: ${props => props.textColor};
    }
  `
