import React, { useEffect, useState, useCallback } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import styled from 'styled-components'
import { AutoComplete, Button, Input, Form, notification, Modal, Select, Row, Col, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/es/select';
import { useWalletModalToggle } from 'state/application/hooks'
import STS from '../../STS'
import { Container } from '../../STS/Markets'
import moment, { Moment } from 'moment';
import { useVoteContract } from '../../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers';
import { useApproveCallback, ApprovalState } from '../../../hooks/useApproveCallback'
import { TSOFI, SOFI_DAO_contract_address, portfolioFactory } from '../../../constants';
import { ProposalContract } from 'constants/proposals/ProposalContract';
import { ChainId, JSBI, Token, TokenAmount } from '@uniswap/sdk';
import { useActiveWeb3React } from '../../../hooks'
import { useCurrencyBalance, useTokenBalances } from '../../../state/wallet/hooks'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import numeral from 'numeral';
import { formatUnits } from '@ethersproject/units';
import AggregateModal from './ConfirmModal'
import { BigNumber } from '@ethersproject/bignumber';
import { retry, RetryableError } from 'utils/retry';
import { useTransactionAdder } from 'state/transactions/hooks';
import { defaultAbiCoder } from '@ethersproject/abi';
import { getAddress } from '@ethersproject/address';
import { useTokens } from '../../STS/hooks';
import {ReactComponent as EditIcon} from 'assets/svg/edit.svg'
import './index.less'


export default function CreateVote() {
    const { tokens } = useTokens()
    const { t, i18n }: any = useTranslation();
    const toggleWalletModal = useWalletModalToggle()
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [endTime, setEndTime] = useState<Moment | null>(moment().add(5, "days"))
    const history = useHistory();
    const [needTokenAmount, setNeedTokenAmount] = useState(BigNumber.from(0))
    const [voteTokenAddress, setVoteTokenAddress] = useState("")
    const [voteDays, setVoteDays] = useState(0)
    const [minDuration, setMinDuration] = useState(259200 * 1000)  //最短
    const [maxDuration, setMaxDuration] = useState(2592000 * 1000) //最长
    const [globalPendingTime, setGlobalPendingTime] = useState(0)
    const { account, chainId, library } = useActiveWeb3React()
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
    const [isCreateLoading, setIsCreateLoading] = useState(false)
    const [governanceAction, setGovernanceAction] = useState("create")
    const [operationTokenAddreses, setoperationTokenAddreses] = useState([])
    const [proposalContractFunction, setProposalContractFunction] = useState<any>([])
    const [contractProposal, setContractProposal] = useState<any>([])
    const [editProposalIndex, setEditProposalIndex] = useState<number>(-1)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showAddField, setShowAddField] = useState(true);
    const [showFunction, setShowFunction] = useState(true);
    const [showCreateAction, setShowCreateAction] = useState(true);
    const tokenBalance = useCurrencyBalance(account || undefined, voteTokenAddress ? new Token(chainId ?? 1, voteTokenAddress, 18, "SOFI", "SOFI") : undefined ?? undefined)
    const voteContract = useVoteContract(SOFI_DAO_contract_address[chainId || ChainId.RINKEBY])
    const addTransaction = useTransactionAdder()
    const [form] = Form.useForm();
    const [approval, approveCallback] = useApproveCallback(new TokenAmount(
      !voteTokenAddress ? TSOFI[chainId ?? 1] : new Token(chainId ?? 1, voteTokenAddress, 18, "SOFI", "SOFI"),
      JSBI.BigInt(100000000000000000000)), SOFI_DAO_contract_address[chainId || ChainId.RINKEBY])
    const disabledEnd = (current: any) => {
      return current && current < moment(Date.now() + minDuration + globalPendingTime).add(1, "seconds") || current > moment(Date.now() + globalPendingTime + maxDuration).add(1, "seconds");
    }

    const [selectedTab, setSelectedTab] = useState<any>("write");

    const showModal = () => {
      setIsModalVisible(true);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handleRemove = () => {
      const _arr = [...contractProposal]
      if(editProposalIndex >= 0){
        _arr.splice(editProposalIndex, 1) 
      }
      setContractProposal(_arr);
      setShowFunction(true)
      handleCancel();
    }

    const handleContractAddressChange = (value: any) => {
      const index = ProposalContract.findIndex((v: any) => v.contractName === value || v.address === value);
      if(index >= 0){
        const arr = ProposalContract[index]?.function.map((v: any) => {return {value: v.label, label: v.label}})
        setProposalContractFunction(arr);
        setShowFunction(true)
        setShowAddField(false)
      }else if(value === 'Marketing Campaign'){
        setShowAddField(false)
        setShowFunction(false)
        form.setFieldsValue({attribute: []})
      }else{        
        setProposalContractFunction([]);
        setShowAddField(true)
        setShowFunction(true)
        form.setFieldsValue({fcName: ''})
      }
    }

    const handleFunctionChange = useCallback((value: any) => {
      const index = proposalContractFunction.findIndex((v: any) => v.label === value);
      if(index >=0 ){
        const i = ProposalContract.findIndex((v: any) => v.contractName === form.getFieldValue('address'));
        form.setFieldsValue({attribute: ProposalContract[i]?.function[index]?.inputs.map((v: any) => {return {key: v.internalType, value: v.type=== 'address[]' ? "['']" : ""}})})
        setShowAddField(false)
      }else {
        setShowAddField(true)
      }
    },[proposalContractFunction])

    const onFinish = (values: any) => {
      console.log('Received values of form:', values);
      let _arr: any = [...contractProposal]
      if(editProposalIndex < 0){
        _arr.push(values)
      }else {
        _arr.splice(editProposalIndex, 1, values) 
      }
      setContractProposal(_arr);
      form.resetFields();
      handleCancel();
    };

    useEffect(() => {
      if(contractProposal[0]?.address === "Marketing Campaign"){
        setShowCreateAction(false);
      }else{
        setShowCreateAction(true);
      }
    },[contractProposal])

    const validateParams = (): boolean => {
      let isValidate = true
      if (!description) {
        isValidate = false
        notification['error']({
          message: "",
          description: "Please input content"
        })
      }
      if(contractProposal.length === 0){
        isValidate = false
        notification['error']({
          message: "",
          description: "Please add Governance Action"
        })
      }
      return isValidate
    }

    const submitProposal = () => {
      if (!validateParams()) return
      if (voteContract && library) {
        let arr1: any = [];
        let arr2: any = [];
        let arr3: any = [];
        let arr4: any = [];
        if(contractProposal[0]?.address !== "Marketing Campaign"){
          let arr: any = [...contractProposal];
          arr = arr.map((v: any) => {
            const i = ProposalContract.findIndex((_v: any) => _v.contractName === v.address);
            const j = i >= 0 ? ProposalContract[i].function.findIndex((_v: any) => _v.label === v.fcName) : -1;
            return i >= 0 ? {
              ...v,
              address: ProposalContract[i].address,
              fcName: ProposalContract[i].function[j].name
            } : v
          })
          arr1 = arr.map((v: any) => v.address);
          arr2 = arr.map(() => 0);
          const keys = arr.map((v: any) => v.attribute.map((attr: any) => attr.key))
          arr3 = arr.map((v: any, i: number) => `${v.fcName}(${keys[i].toString()})`);
          const values = arr.map((v: any) => v.attribute.map((attr: any) => attr.key.includes('[]') ? JSON.parse(attr.value.replace(/'/g, '"')) : attr.value))
          arr4 = arr.map((_: any, i: number) => {
            return defaultAbiCoder.encode(keys[i], values[i])
          })
        }
        // voteContract.propose(
        //   [portfolioFactory[chainId ?? ChainId.RINKEBY]],
        //   [0],
        //   [governanceAction === "remove" ? "removeTokens(address[])" : "addTokens(address[])"],
        //   [defaultAbiCoder.encode(["address[]"], [operationTokenAddreses.map(address => getAddress(address))])],
        //   moment(endTime).format("X"),
        //   getTitle(),
        //   description
        // )
        
        voteContract.propose(
          arr1,
          arr2,
          arr3,
          arr4,
          moment(endTime).format("X"),
          title,
          description
        )
        .then((res: TransactionResponse) => {
          console.log(res)
          setIsShowConfirmModal(false)
          setIsCreateLoading(true)
          addTransaction(res, {
            summary: t('create_proposals')
          })
          retry(() => {
              return library
              .getTransactionReceipt(res.hash)
              .then(receipt => {
                  if (receipt === null) {
                      console.debug('Retrying for hash', res.hash)
                      throw new RetryableError()
                    }
                    if (receipt) {
                      setIsCreateLoading(false)
                      history.replace("/proposals")
                    }
              })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        }).catch((error: any) => {
          console.error('Vote Failed', error);
          if(error.code === 4001){
              notification['error']({
                  message: "",
                  description: t('cancelled_transaction')
                })
          }else {
              notification['error']({
                  message: "",
                  description: error.message
              })
          }
          setIsCreateLoading(false)
        })
      }   
    }
    const getProposalToken = async () => {
      if (voteContract) {
        voteContract.voteToken()
          .then((res: any) => {
            setVoteTokenAddress(res)
          })
        voteContract.proposalThresholdCount()
          .then((res: any) => {
            console.log(res)
            setNeedTokenAmount(res)
          })
        voteContract.minDuration()
          .then((minDuration: any) => {
            setMinDuration(minDuration * 1000)
          })
        voteContract.maxDuration()
          .then((maxDuration: any) => {
            setMaxDuration(maxDuration * 1000)
          })
        voteContract.globalPendingTime()
          .then((globalPendingTime: any) => {
            setGlobalPendingTime(globalPendingTime * 1000)
          })
      }
    }

    useEffect(() => {
      getProposalToken()
    }, [])

    useEffect(() => {
      if(document.getElementsByTagName("textarea")[0]){
        document.getElementsByTagName("textarea")[0].placeholder = t('body_placeholder');
      }
    },[i18n.language])

    const searchAddressResult = (contractProposal.length === 0 || (contractProposal.length === 1 && editProposalIndex !== -1)) ?
    ProposalContract.map((v: any) => {
      return {
        value: v.contractName,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {v.contractName}
          </div>
        ),
      };
    }).concat([{
      value: 'Marketing Campaign',
      label: <>Marketing Campaign</>
    }]) :
    ProposalContract.map((v: any) => {
      return {
        value: v.contractName,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {v.contractName}
          </div>
        ),
      };
    })

    const editAction = (index: number,contract: any) => {
      setEditProposalIndex(index);
      form.setFieldsValue(contract);
      handleContractAddressChange(contract.address);
      showModal();
    }
    
    const createAction = () => {
      setEditProposalIndex(-1);
      setProposalContractFunction([])
      form.resetFields();
      showModal();
      setShowFunction(true)
      setShowAddField(true)
    }
      
    return (
        <STS>
            <Container>
              <Button type="default" onClick={() => {history.go(-1)}}>{t("back")}</Button>
              <CreateContent>
                <CreateLeft>
                  <ContractAddress>
                    {contractProposal.map((_contract: any, index: number) => 
                      <li>
                        <div>
                          <p>{index + 1}. &nbsp;{_contract.address}</p>
                          <p>{_contract.fcName}</p>
                          {_contract.attribute && _contract.attribute.map((atr: any) => 
                            <p>{atr.key}: {atr.value}</p>
                          )}
                        </div>
                        <div>
                          <Button type="link" icon={<EditIcon/>} onClick={() => {editAction(index, _contract)}}>
                            {t('edit')}
                          </Button>
                        </div>
                      </li>
                    )}
                  </ContractAddress>
                  {showCreateAction && 
                    <Button 
                      type="primary" 
                      ghost 
                      block 
                      size="large" 
                      style={{textAlign: 'left', border: '1px solid #DEDEDE'}}
                      onClick={createAction}
                    >
                    + {t('governance_action')}
                    </Button>
                  }
                  
                  {/* <Title>{t("governance_action")}</Title>
                  <Select
                    size="large"
                    value={governanceAction}
                    style={{ width: "300px" }}
                    onChange={value => {
                      setGovernanceAction(value)
                    }}
                  >
                    <Select.Option key="create" value="create">{t("add_portfolio_trading_token")}</Select.Option>
                    <Select.Option key="delete" value="remove">{t("delete_portfolio_trading_pair")}</Select.Option>
                    <Select.Option key="fix" value="fix">Fix bug</Select.Option>
                    <Select.Option key="expense" value="expense">Marketing expense</Select.Option>
                  </Select>
                  <Title>{t("custom_token")}</Title>
                  {governanceAction === "create" ? (
                    <Select
                      mode="tags"
                      style={{ width: "300px" }}
                      placeholder={t("token_contract_address")}
                      value={operationTokenAddreses}
                      onChange={(value) => {
                        setoperationTokenAddreses(value)
                      }}
                    ></Select>
                  ) : (
                    <Select
                      mode="multiple"
                      style={{ width: "300px" }}
                      placeholder="Token select"
                      value={operationTokenAddreses}
                      onChange={(value) => {
                        setoperationTokenAddreses(value)
                      }}
                    >
                      {tokens.length && tokens.map((d: any) => <Select.Option key={d.symbol} value={d.address}><img className="tokenAvator" src={getTokenLogoURL(d.symbol)}/>{d.symbol}</Select.Option>)}
                    </Select>
                  )} */}
                  <Title>{t('title')}</Title>
                  <Input
                    placeholder={t('proposal_title')}
                    value={title} onChange={(e) => {
                    setTitle(e.target.value)
                  }}/>
                  <Title>{t("content")}</Title>
                  {/* <Input.TextArea
                    placeholder={t('body_placeholder')}
                    size={"large"}
                    autoSize={{ minRows: 10, maxRows: 12 }}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  /> */}
                  <ReactMde
                    value={description}
                    onChange={setDescription}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown: any) =>
                      Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                    }
                    minEditorHeight={280}
                    childProps={{
                      writeButton: {
                        tabIndex: -1
                      }
                    }}
                  />
                  {/* <Title>Vote options</Title> */}
                </CreateLeft>
                <CreateRight>
                  {/* <RightItem>
                    <Title>Start time</Title>
                    <ItemContent>In 3 days</ItemContent>
                  </RightItem>
                  <RightItem>
                    <Title>End time</Title>
                    <ItemContent><DatePicker value={endTime} showTime onOk={(v: any) => {setEndTime(v)}} /></ItemContent>
                  </RightItem> */}
                  <RightItem>
                    <Title>{t("vote_days")}</Title>
                    <ItemContent>5 {t('days')}</ItemContent>
                  </RightItem>
                  <RightItem>
                    <Title>{t("your_balance")}</Title>
                    <ItemContent>{numeral(tokenBalance?.toSignificant(8).toString()).format('0,0.00')} SOFI</ItemContent>
                  </RightItem>
                  <RightItem>
                    <Title>{t("minimum_SOFI_needed")}</Title>
                    <ItemContent>{numeral(formatUnits(needTokenAmount, 18)).format('0,0.00')} SOFI</ItemContent>
                  </RightItem>
                  <VoteDescription>
                    {t('vote_description1')}
                    <br/>
                    <br/>
                    {t('vote_description2')}
                  </VoteDescription>
                  {!account ? (
                  <Button type="primary" onClick={toggleWalletModal}>{t("conect_wallet")}</Button>
                  ) : 
                  tokenBalance?.equalTo(JSBI.BigInt(0)) || (Number(tokenBalance?.toSignificant(8).toString() || 0) < Number(formatUnits(needTokenAmount, 18))) ? (
                    <Button
                      type="primary"
                      size="large"
                      disabled
                      onClick={() => {
                        if(!validateParams()) return
                        setIsShowConfirmModal(true)
                      }}
                    >
                      {t('insufficient_balance')}
                    </Button>
                  ) :
                    (
                      approval !== ApprovalState.APPROVED ? (
                        <Button
                          type="primary"
                          size="large"
                          onClick={approveCallback}
                        >
                          {t("approve")} SOFI 
                        </Button>
                      ) :
                      <Button
                        type="primary"
                        size="large"
                        loading={isCreateLoading}
                        onClick={() => {
                          if(!validateParams()) return
                          setIsShowConfirmModal(true)
                        }}
                      >
                        {t("governance_title4")}
                      </Button>
                    )}
                </CreateRight>
              </CreateContent>
            </Container>
            <AggregateModal
              isOpen={isShowConfirmModal}
              onDismiss={() => setIsShowConfirmModal(false)}
              handleSubmit={submitProposal}
              title={title}
              startTime={"123"}
              endTime={endTime}
              stakeAccount={needTokenAmount}
            />
            <Modal className="create_action_modal" title={`${editProposalIndex < 0 ? t('add'): t('edit')} ${t('governance_action')}`} visible={isModalVisible} onCancel={handleCancel} footer={null}>
              <Form hideRequiredMark form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
                  <Form.Item label={t('contract_address')} name="address" initialValue="0x0..." rules={[{required: true, message: 'Please enter Contract address'}]}>
                      <AutoComplete
                        onChange={handleContractAddressChange}
                        options={searchAddressResult}
                      >
                        <Input placeholder="0x0..." addonAfter={<DownOutlined />}/>
                      </AutoComplete>
                  </Form.Item>
                  {showFunction && 
                    <Form.Item label={t('function_name')} name="fcName" rules={[{required: true, message: 'Please enter Function name'}]}>
                      <AutoComplete
                        options={proposalContractFunction}
                        onChange={handleFunctionChange}
                      >
                        <Input placeholder="_setFunction" addonAfter={<DownOutlined />}/>
                      </AutoComplete>
                    </Form.Item>
                  }
                  <Form.List name="attribute">
                      {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }:any, i: number) => (
                          <Row key={key} gutter={10} style={{alignItems: 'center'}}>
                            <Col flex={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'key']}
                                fieldKey={[fieldKey, 'key']}
                                rules={[{ required: true, message: 'Missing key' }]}
                              >
                                <Input placeholder={`${t('key')}${i+1}`} disabled={!showAddField}/>
                              </Form.Item>
                            </Col>
                            <Col flex={3}>
                              <Form.Item
                                {...restField}
                                name={[name, 'value']}
                                fieldKey={[fieldKey, 'value']}
                                rules={[{ required: true, message: 'Missing value' }]}
                                style={{width: '100%'}}
                              >
                                <Input placeholder={`${t('value')}${i+1}`} />
                              </Form.Item>
                            </Col>
                            {showAddField && 
                              <Col flex={1}>
                                <MinusCircleOutlined onClick={() => remove(name)} style={{marginBottom: '24px'}}/>
                              </Col>
                            }
                          </Row>
                          ))}
                          {showAddField && 
                            <Form.Item>
                              <Button type="primary" ghost onClick={() => add()} icon={<PlusOutlined />}>
                                {t('add_field')}
                              </Button>
                            </Form.Item>
                          }
                      </>
                      )}
                  </Form.List>
                  <Form.Item>
                    <Row gutter={20}>
                      <Col flex={1}>
                      <Button type="default" onClick={handleRemove} block>
                          {t('remove')}
                        </Button>
                      </Col>
                      <Col flex={1}>
                        <Button type="primary" htmlType="submit" block>
                          {t('save')}
                        </Button>
                      </Col>
                    </Row> 
                  </Form.Item>
              </Form>
            </Modal>
        </STS>    
    )
}

const BackButton = styled.div`
  display: inline-block;
  border: 1px solid #EBEAED;
  background: #FFFFFF;
  padding: 11px 42px;
  font-size: 16px;
  color: #798488;
  margin-bottom: 19px;
`

const CreateContent = styled.div`
  width: 100%;
  border: 1px solid #EBEAED;
  background: #FFFFFF;
  padding: 43px 32px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 21px;
  margin-top: 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const CreateLeft = styled.div`

`

const CreateRight = styled.div`
  .ant-btn {
    float: right;
  }
`

const Title = styled.div`
  margin: 12px 0;
  font-size: 14px;
  color: #000000;
`

const RightItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ItemContent = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const VoteDescription = styled.div`
  padding: 13px 16px;
  background: #F4F4F4;
  border-radius: 6px;
  padding-bottom: 54px;
  margin-bottom: 20px;

`

const ContractAddress = styled.ul`
  padding-left: 0;
  li{
    border: 1px solid #DEDEDE;
    list-style-position: inside;
    list-style: decimal;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    p{
      margin-left: 26px;
      margin-bottom: 0;
      &:first-of-type{
        margin-left: 10px;
        display: inline-block;
        margin-bottom: 0;
      }
    }
    .ant-btn{
      font-size: 14px;
      svg{
        margin-right: 5px;
      }
    }
  }
`