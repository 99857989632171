import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import { useAllTransactions, isTransactionRecent } from '../../../state/transactions/hooks'
import { TransactionDetails } from '../../../state/transactions/reducer'
import { getEtherscanLink } from '../../../utils'
import { useActiveWeb3React } from '../../../hooks'
import { ChainId } from '@uniswap/sdk'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}


export default function TransactionList() {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pendingTransactions = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmedTransactions = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)
  const renderTransactions = (transactions: string[]) => {
    let count = 0
    const transactionList = transactions.map(hash => {
      if (count > 8) {
        return {}
      }
      const tx = allTransactions?.[hash]
      const summary = tx?.summary
      const pending = !tx?.receipt
      const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')
      count += 1
      return {
        hash,
        time: pending ? tx.addedTime : tx.confirmedTime,
        summary,
        status: pending ? t('pending'): success ? t('completed') : t('failed')
      }
    }).filter(tx => {
      if (!tx || !tx.summary) {
        return false
      }
      return tx?.summary?.indexOf("Swap") > -1
    })
    const columns = (chainId: ChainId) => {
      return [
        {
          title: t("transaction"),
          dataIndex: "summary",
          key: "summary",
          render: (text: string, record: any) => {
            
            return (
              <a target="_blank" rel="noopener noreferrer" href={getEtherscanLink(chainId || 1, record.hash, 'transaction')} >{text}</a>
            )
          }
        },
        {
          title: t("time"),
          dataIndex: "time",
          key: "time",
          render: (text: number) => {
            return <span>{dayjs(text).format("YYYY-MM-DD hh:mm:ss")}</span>
          }
        },
        {
          title: t("status"),
          dataIndex: "status",
          key: "status",
          render: (text: string) => {
            const statusColor: { [status: string]: string } = {
              "Pending": "#0062DF",
              "Completed": "#03CE3C",
              "Failed": "#FF0202"
            }
            return (
              <span style={{ color: statusColor[text] }}>{text}</span>
            )
          }
        }
      ]
    }
    return (
      <TransactionListWrapper>
        <Table
          dataSource={transactionList}
          columns={columns(chainId || 1)}
          pagination={false}
          locale={{
            emptyText: t("no_transaction")
          }}
        />
      </TransactionListWrapper>
    )
  }

  return (
    <TransactionContent>
      <TransactionTitle>{t("recent_transaction")}</TransactionTitle>
      {renderTransactions([...pendingTransactions, ...confirmedTransactions])}
    </TransactionContent>
  )
}



const TransactionTitle = styled.div`
  font-size: 18px;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 22px;
`

const TransactionContent = styled.div`

`
const TransactionListWrapper = styled.div`
  .ant-table-thead {
    th {
      font-size: 12px;
      color: #666666;
      background: #F9FAFC;
      padding: 8px 16px;
    }
  }
  .ant-table-tbody {
    tr {
      td {
        border-bottom: none;
      }
    }
    tr:hover {
      td {
        background: transparent !important;
      }
    }
    tr:nth-child(even) {
      background: #F9FAFC;
    }
    .ant-table-cell {
      font-size: 12px;
      padding: 8px 16px;
    }
  }


`
