import React, { useCallback, useState } from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table';
import { RedoOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Progress, Card, Tabs, Input, Table, PageHeader, Button, Row, Col, Tooltip } from 'antd';
//@ts-ignore
import randomColor from 'randomcolor';
import { usePortfolioDetail, useChartData, useTokens } from './hooks';
import SimplePriceChart from './SimplePriceChart';
import { useActiveWeb3React } from '../../hooks'
import { PriceChartRangeOption } from 'constants/priceChartEnums'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'
import { useSetTokenContract, useBasicIssuanceModuleContract, useTradeModuleContract, useSofiManageContract } from '../../hooks/useContract'
import { managerModule, portfolioFactory, portfolioModule, trading } from '../../constants'
import { retry, RetryableError } from '../../utils/retry'
import { getEtherscanLink, shortenAddress, toK } from '../../utils'
import tokenColor from 'constants/tokenColor';


import './index.less';
import STS from './index'
import { relative } from 'path';
import { useEffect } from 'react';

const { Meta } = Card;
const { TabPane } = Tabs;
const { Search } = Input;

const Container = styled.div`
    padding: 50px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
`
const Title = styled.h1`
    font-size:32px;
    color: #5542F6;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-bottom: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 20px;
    `};
    span{
        font-size: 14px;
        color: #000;
    }
`

const SubTitle = styled.h2`
    font-size:14px;
    margin-top: 18px;
    margin-bottom: 12px;
`

export default function Portfolio(props: RouteComponentProps<{ address: string }>) {
  const {
    match: {
      params: { address }
    }
  } = props
  const { t } = useTranslation();
  const { account, chainId, library } = useActiveWeb3React()
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.DAILY_PRICE_RANGE)
  const { portfolio } = usePortfolioDetail(address);
  const [refresh, setRefresh] = useState<number>(1);
  const [refreshTime, setRefreshTime] = useState<any>(new Date());
  const { chartData } = useChartData(address, chartRange, refresh);
  const setTokenContract = useSetTokenContract(address)
  const [isTrade, setIsTrade] = useState<boolean>(false)
  const [isBuySell, setIsBuySell] = useState<boolean>(false)
  const [tradeStatus, setTradeStatus] = useState<number>(2)
  const [loading, setLoading] = useState<boolean>(false)
  const basicIssuanceModuleContract = useBasicIssuanceModuleContract(portfolioModule[chainId ? chainId: 4])
  const tradeModuleContract = useTradeModuleContract(trading[chainId ? chainId: 4])
  const sofiManageContract = useSofiManageContract(managerModule[chainId ? chainId : 4])
  const addTransaction = useTransactionAdder()

  useEffect(() => {
    getIsTrade();
    getIsBuySell();
  }, [address])

  const getIsBuySell = async () => {
    if(setTokenContract){
      const _isBuySell = await setTokenContract.isInitializedModule(portfolioModule[chainId ? chainId: 4])
      setIsBuySell(_isBuySell)
    }
  }
 
  const getIsTrade = async () => {
    if(setTokenContract){
      const status = await setTokenContract.moduleStates(managerModule[chainId ? chainId: 4])
      setTradeStatus(status);
      const _isTrade = status === 2;
      setIsTrade(_isTrade)
    }
  }

  const enableBuySell = () => {
    if(library){
      basicIssuanceModuleContract?.initialize(address)
      .then((res: any) => {
        addTransaction(res, {
          summary: t("buy_and_sell_initialize") 
        })
        retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setIsBuySell(true);
                    console.log("trade receipt", receipt)
                  }
            })
        }, {
            n: Infinity,
            minWait: 2500,
            maxWait: 3500
        })
      })
    }
  }

  const enableTrade = useCallback(async() => {
    if(library && setTokenContract){
      if(tradeStatus === 0){
        setTokenContract.addModule(managerModule[chainId ? chainId: 4])
        .then((res: any) => {
          addTransaction(res, {
            summary: t("add_trading_module")
          })
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setTradeStatus(1);
                    console.log("trade receipt", receipt)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
      }else if(tradeStatus === 1){
        sofiManageContract?.initialize(address)
        .then((res: any) => {
          addTransaction(res, {
            summary: t("trade_initialize")
          })
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setIsTrade(true);
                    console.log("trade receipt", receipt)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
      }
    }
  }, [library, tradeStatus, chainId])
 
  const columns: ColumnsType<any> = [
    {
      title: t("token"),
      dataIndex: 'symbol',
      fixed: 'left',
      width: '120px',
      render: (symbol: string, row: any) => <><img style={{width: '15px', height: '15px'}} src={getTokenLogoURL(row.symbol)}/>&nbsp;{symbol}</>
    },
    {
      title: t("token_price"),
      dataIndex: 'price_usd',
      width: '120px',
      render: (v: any) => v ? `$${numeral(v).format('0,0.00')}` : '-',
    },
    {
      title: t("quantity"),
      dataIndex: 'amount',
      width: '150px',
      render: (amount: number, row: any) => <Tooltip title={`${numeral(amount === 0 ? 0 : amount/portfolio.total_amount).format('0,0.00000000')} ${row.symbol}`}>{numeral(amount === 0 ? 0 : amount/portfolio.total_amount).format('0,0.000')}</Tooltip>
    },
    {
      title: t("value_per_set"),
      dataIndex: 'value_per_set',
      width: '150px',
      render: (v: any) => v ? `$${numeral(v).format('0,0.00')}` : '-',
    },
    {
      title: t("allocation"),
      dataIndex: 'value_per_set',
      width: '120px',
      fixed: 'right',
      //@ts-ignore
      render: (value_per_set: number, row: any) => <Progress strokeColor={`${tokenColor?.[row.name] || randomColor()}`} type="circle" width={55} format={percent => `${percent}%`} percent={Number((value_per_set/portfolio.value_per_set*100).toFixed(2))} />
    },
  ];

  const Avatar = styled.img`
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-right: 12px;
    position: relative;
    border-radius: 50%;
  `

  const refreshChart = () => {
    setRefresh(refresh + 1);
    setRefreshTime(new Date())
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <STS>
      <Container>
          <PageHeader
            title={<><Avatar src={portfolio?.icon || portfolio?.avatar_uri} />{portfolio?.name}</>}
            className="portfolio-page-header"
            extra={
              <>
                {isBuySell &&
                  <>
                    <Button type="primary" ghost size="large" key="1"><Link to={`/sts/buy/${address}`}>{t("buy")}</Link></Button>
                    <Button type="primary" ghost size="large" key="2"><Link to={`/sts/sell/${address}`}>{t("sell")}</Link></Button>
                  </> 
                }
                {!isBuySell &&
                  <>
                    {portfolio?.manager === account ? 
                      <div className="hoverBtn">
                        <Button size="large" key="1">{t("buy")}</Button>
                        <Button size="large" key="2">{t("sell")}</Button>
                        <div className="top">
                          <Button size="small" key="1" onClick={enableBuySell}>{t('enable_to_use')}</Button>
                        </div>
                      </div>
                      :
                    <Button size="large" key="1">{t('not_enable_to_trading')}</Button>}
                  </>
                }
                {portfolio?.manager === account &&
                  <>
                  { isTrade ?
                    <Button size="large" key="3" type="primary">
                      <Link to={`/sts/manage/${address}`}>
                        {t("manage")}
                      </Link>
                    </Button>
                    :
                    <Button size="large" key="1" onClick={enableTrade}>{tradeStatus === 0 ? t('add_trading_module'): t('enable_to_trading')}</Button>
                    }
                  </>
                }
              </>
            }
          >
                <Row gutter={16}>
                  <Col sm={16} xs={24}>
                      <Card 
                        className="chartCard" 
                        title={<span>{t('portfolio_set_price')}<div className="price_box"><b className="price">${portfolio?.value_per_set ? numeral(portfolio?.value_per_set).format('0,0.00') : '-'}</b><b className={portfolio.price_changed_24hr > 0 ? "up":"down"}>{`${portfolio.price_changed_24hr > 0 ? `+${portfolio.price_changed_24hr}%`: `${portfolio.price_changed_24hr}%`}`}</b></div></span>} 
                        extra={<></>}
                      >
                        <SimplePriceChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
                        <div className="card_footer">{t('updated_on')} {moment(refreshTime).format('YYYY-MM-DD HH:mm:ss')}&nbsp;&nbsp;<button onClick={refreshChart}>{loading ? <LoadingOutlined /> : <RedoOutlined />}&nbsp;Refresh</button></div>
                      </Card>
                      <Card className="infoCard">
                          <div>
                              <div>
                                  <div>{t("market_cap")}</div>
                                  <div>${numeral(portfolio?.total_value).format('0,0.00')  || '-'}</div>
                              </div>
                              <div>
                                <div>{t("cumulative_APY")}</div>
                                <div>{`${portfolio?.cumulative_apy}%` || '-'}</div>
                              </div>
                          </div>
                          <div>
                            <div>
                              <div>{t("inception_date")}</div>
                              <div>{moment(portfolio?.created_at*1000).format('YYYY-MM-DD')}</div>
                            </div>
                            <div>
                              <div>{t("month_APY")}</div>
                              <div>{`${portfolio?.month_apy}%` || '-'}&nbsp;<span>({t("last_3_months")})</span></div>
                            </div>
                          </div>
                      </Card>
                      <Card className="assetCard">
                        <SubTitle>{t("asset_distribution")}</SubTitle>
                        <Table className="marketTable" scroll={{ x: 500 }} columns={columns} dataSource={portfolio.components} pagination={false} rowKey="address"/> 
                      </Card>
                      <Card style={{marginTop: '30px'}}>
                        <SubTitle>{t('about')}</SubTitle>
                        <p>{portfolio.intro}</p>
                      </Card>
                  </Col>
                  <Col sm={8} xs={24}>
                      <Card 
                        className="infoCard2"  
                        title={<div className="managerInfo">
                        {portfolio?.address && shortenAddress(portfolio?.address)}
                        <div className="created">Created by <Link to={`/sts/manager/${portfolio?.manager}`}>{portfolio?.manager && shortenAddress(portfolio?.manager)}</Link></div>
                        </div>}
                      >
                          <Card.Grid style={{width: '50%', border: 'none', boxShadow:'none'}}>
                              <p>{t("portfolio_holders")}</p>
                              <b>{numeral(portfolio?.holders).format('0,0') || '-'}</b>
                              <p>{t("exit_fee")}</p>
                              <b>{portfolio.fee}%</b>    
                          </Card.Grid>
                          <Card.Grid style={{width: '50%', border: 'none', boxShadow:'none'}}>       
                            <p>{t("portfolio_address")}</p>
                            <b><a href={`${getEtherscanLink(chainId ?? 137, address, "address")}`} target="_blank">{t("view_on_explorer")}</a></b>
                          </Card.Grid>
                      </Card>
                  </Col>
              </Row>    
          </PageHeader>
      </Container>
    </STS>
  )
}
