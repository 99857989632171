
import styled from 'styled-components'
import { Radio } from "antd"
import React, { useEffect, useState } from 'react'
import { BigNumber } from "ethers"
import { ButtonError, ButtonPrimary } from '../../../components/Button'
import { useActiveWeb3React } from '../../../hooks'
import { Field } from '../../../state/swap/actions'
import {
  tryParseAmount,
} from '../../../state/swap/hooks'
import { useCurrencyBalance } from '../../../state/wallet/hooks'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral';
import { useRAIProxyContract } from '../../../hooks/useContract'
import { ChainId, CurrencyAmount, ETHER, JSBI, Token, TokenAmount } from '@uniswap/sdk'
import { formatUnits } from "@ethersproject/units"
import { ApprovalState, useApproveCallback } from '../../../hooks/useApproveCallback'
import useDebounce from 'hooks/useDebounce'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import RaiCurrencyInputPanel from '../../../components/RaiCurrencyInputPanel'
import AggregateModal from './AggregateModal'
import { RAIProxy_contract_address, WETH, WMATIC } from '../../../constants/index'
import { TransactionResponse } from '@ethersproject/providers'

interface TradeSwapInterface {
  currentPair: any;
}

const routerConfig: { [chainId in ChainId]?: object } = {
  4: {
    "RaiSwap":"0x673813639d010713FDf119a9b1585fDC69f6B893",
    "UniSwap":"0xf164fC0Ec4E93095b804a4795bBe1e041497b92a",
    "SushiSwap":"0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
  },
  80001: {
    "RaiSwap":"0x5472B83EC28d5d4DB943A9C08CA72a6e40554222"
  },
  137: {
    "QuickSwap": "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    "SushiSwap": "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
  },
  1: {
    "UniSwap": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    "SushiSwap": "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F"
  }
}

export default function TradeSwap ({
  currentPair
}: TradeSwapInterface) {

  const { chainId, account } = useActiveWeb3React()
  const [isBuy, setIsBuy] = useState(true)
  const [inputToken, setInputToken] = useState<Token>()
  const [inputValue, setInputValue] = useState("")
  const [outputToken, setOutputToken] = useState<Token>()
  const [outputTokenValue, setoutputTokenValue] = useState("")
  const { t } = useTranslation()
  const RAIProxy = useRAIProxyContract(RAIProxy_contract_address[chainId || ChainId.RINKEBY])
  const inputBalance = useCurrencyBalance(account ?? undefined, inputToken?.symbol === "ETH" || inputToken?.symbol === "MATIC" ? ETHER : inputToken ?? undefined)
  const outputBalance = useCurrencyBalance(account ?? undefined, outputToken?.symbol === "ETH" || outputToken?.symbol === "MATIC" ? ETHER : outputToken ?? undefined)
  const addTransaction = useTransactionAdder()
  const [useRouter, setUseRouter] = useState("")
  const [isShowSearchText, setIsShowSearchText] = useState(false)
  const [switchRouterName, setSwitchRouterName] = useState("")
  const [showConfirm, setShowConfirm] = useState(false)
  const [getSearchResult, setGetSearchResult] = useState(false)
  const getTokenBalance = isBuy
    ? {
      [Field.INPUT]: inputBalance ?? undefined,
      [Field.OUTPUT]: outputBalance ?? undefined
    }
    : {
      [Field.INPUT]: outputBalance ?? undefined,
      [Field.OUTPUT]: inputBalance ?? undefined
    }
  const currencies: { [field in Field]?: Token } = isBuy ?
  {
    [Field.INPUT]: inputToken ?? undefined,
    [Field.OUTPUT]: outputToken ?? undefined
  } : {
    [Field.INPUT]: outputToken ?? undefined,
    [Field.OUTPUT]: inputToken ?? undefined
  }
  const [inputTokenApproval, inputTokenApproveCallback] = useApproveCallback(inputToken ? inputToken.symbol === "ETH" || inputToken?.symbol === "MATIC" ? CurrencyAmount.ether(JSBI.BigInt(100000000000000000000)) : new TokenAmount(inputToken, JSBI.BigInt(100000000000000000000)): undefined, RAIProxy_contract_address[chainId || ChainId.RINKEBY])
  const [outputTokenApproval, outputTokenApproveCallback] = useApproveCallback(outputToken ? outputToken.symbol === "ETH" || outputToken?.symbol === "MATIC" ? CurrencyAmount.ether(JSBI.BigInt(100000000000000000000)) : new TokenAmount(outputToken, JSBI.BigInt(100000000000000000000)): undefined, RAIProxy_contract_address[chainId || ChainId.RINKEBY])
  const parsedAmounts = isBuy
    ? {
        [Field.INPUT]: inputValue,
        [Field.OUTPUT]: outputTokenValue
      }
    : {
        [Field.INPUT]: outputTokenValue,
        [Field.OUTPUT]: inputValue
      }
  const inputValueDebounce = useDebounce(parsedAmounts[Field.INPUT], 150)
  const setTokenValueMethod = isBuy
  ? {
      [Field.INPUT]: setInputValue,
      [Field.OUTPUT]: setoutputTokenValue
    }
  : {
      [Field.INPUT]: setoutputTokenValue,
      [Field.OUTPUT]: setInputValue
    }
  useEffect(() => {
    if (currentPair) {
      setInputToken(currentPair.token1)
      setOutputToken(currentPair.token0)
    }
  }, [currentPair])

  useEffect(() => {
    if (!inputValueDebounce) {
      setUseRouter("")
      setTokenValueMethod[Field.OUTPUT]("")
      setGetSearchResult(false)
      setIsShowSearchText(false)
      return
    }
    const parseAmount = tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT])
    if ((currentPair.symbol === "ETH-WETH" || currentPair.symbol === "MATIC-WMATIC") && chainId) {
      setUseRouter(currentPair.symbol === "ETH-WETH" ?
      "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F" :
      "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506")
      setTokenValueMethod[Field.OUTPUT](parsedAmounts[Field.INPUT])
      setGetSearchResult(false)
      setIsShowSearchText(true)
      return
    }
    if (RAIProxy && parseAmount) {
      const getAddress = (currency: Token | undefined): Token | undefined => {
        if (!chainId) return undefined
        if (currency?.symbol === "ETH") {
          return WETH[chainId]
        }
        if (currency?.symbol === "MATIC") {
          return WMATIC[chainId]
        }
        return currency
      }
      RAIProxy
      .getUniV2Routers(
        `0x${parseAmount?.raw.toString(16)}`,
        getAddress(currencies[Field.INPUT])?.address,
        getAddress(currencies[Field.OUTPUT])?.address
      ).then((res: any) => {
        console.log("res", res)
        const maxOutputAmount = res[1].reduce((preAmount: BigNumber, nextAmount: BigNumber) => {
          return preAmount.gt(nextAmount) ? preAmount : nextAmount
        })
        const findRouter = res[0][res[1].findIndex((outputAmount: BigNumber) => outputAmount.eq(maxOutputAmount))]
        setUseRouter(findRouter)
        setTokenValueMethod[Field.OUTPUT](formatUnits(maxOutputAmount, currencies[Field.OUTPUT]?.decimals))
        setGetSearchResult(false)
        setIsShowSearchText(true)
      })
    }
  }, [inputValueDebounce])

  useEffect(() => {
    
    if (isShowSearchText) {
      let routerNameArray: any = routerConfig[chainId || 137]
      Object.keys(routerNameArray).forEach((router: string, index: number) => {
       setTimeout(() => {
         setSwitchRouterName(router)
       }, 100 + (index + 1) * 500);
     })
     setTimeout(() => {
      const routerInfo = routerConfig[chainId || 4]
      if (routerInfo) {
        const valueIndex = Object.values(routerInfo).findIndex((router: string) => router === useRouter)
        setSwitchRouterName(Object.keys(routerInfo)[valueIndex])
        setIsShowSearchText(false)
        setGetSearchResult(true)
      }
     }, 100 + Object.keys(routerNameArray).length * 500 + 200);
    }
  }, [isShowSearchText])

  const handleSwap = async () => {
    if (RAIProxy) {
      const parseInputAmount = tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT])
      let resTrade: TransactionResponse
      if (currencies[Field.INPUT]?.symbol === "ETH" || currencies[Field.INPUT]?.symbol === "MATIC") {
        resTrade = await RAIProxy.tradeTokenByExactETH(
          useRouter,
          currencies[Field.OUTPUT]?.address,
          0,
          account,
          {"value": `0x${parseInputAmount?.raw.toString(16)}`}
        )
      } else if (currencies[Field.OUTPUT]?.symbol === "ETH" || currencies[Field.OUTPUT]?.symbol === "MATIC") {
        resTrade = await RAIProxy.tradeETHByExactToken(
          useRouter,
          currencies[Field.INPUT]?.address,
          `0x${parseInputAmount?.raw.toString(16)}`,
          0,
          account
        )
      } else {
        resTrade = await RAIProxy.tradeTokenByExactToken(
          useRouter,
          currencies[Field.INPUT]?.address,
          currencies[Field.OUTPUT]?.address,
          `0x${parseInputAmount?.raw.toString(16)}`,
          0,
          account
        )
      }
      setShowConfirm(false)
      addTransaction(resTrade, {
        summary: `Swap ${parsedAmounts[Field.INPUT]} ${currencies[Field.INPUT]?.symbol} for ${numeral(parsedAmounts[Field.OUTPUT]).format("0.00")} ${currencies[Field.OUTPUT]?.symbol}`
      })
    }
  }
  return (
    <TradeSwapWraper>
      <AggregateModal
        isOpen={showConfirm}
        onDismiss={() => setShowConfirm(false)}
        handleSwap={handleSwap}
        inputValue={parsedAmounts[Field.INPUT]}
        outputValue={parsedAmounts[Field.OUTPUT]}
        inputCurrency={currencies[Field.INPUT]}
        outputCurrency={currencies[Field.OUTPUT]}
      />
      <Radio.Group
        defaultValue="buy" size="large"
        onChange={(e) => {
          setIsBuy(!isBuy)
        }}
      >
        <Radio.Button value="buy" checked={isBuy}>{t("buy")}</Radio.Button>
        <Radio.Button value="sell" checked={!isBuy}>{t("sell")}</Radio.Button>
      </Radio.Group>
      <OperationWraper>
          <InputWraper>
            <InputItem>{t("avaliable_balance")}</InputItem>
            <InputItem>
              <AmountColor>
                {getTokenBalance[Field.INPUT] ? numeral(getTokenBalance[Field.INPUT]?.toFixed(currencies[Field.INPUT]?.decimals)).format("0,00.00000", Math.floor) : '--'}
              </AmountColor>&nbsp; {currencies[Field.INPUT]?.symbol}
            </InputItem>
          </InputWraper>
          <InputWraper style={{ alignItems: "flex-start" }}>
            <InputItem>{t("amount")}</InputItem>
            <InputItem>
              <RaiCurrencyInputPanel
                value={parsedAmounts[Field.INPUT]}
                onUserInput={(value: string) => {
                  setTokenValueMethod[Field.INPUT](value)
                }}
                showMaxButton={false}
                currency={currencies[Field.INPUT]}
                id="swap-currency-input"
              />
            </InputItem>
          </InputWraper>
          <InputWraper>
            <InputItem></InputItem>
            {/* <Slider
              style={{ width: "181px" }}
              marks={marks}
              step={10}
              value={sliderValue}
              defaultValue={0}
              onChange={(value: number) => {
                onUserInput(Field.INPUT, maxAmountInput?.multiply(
                  new Fraction(JSBI.BigInt(value), JSBI.BigInt(100)) ?? JSBI.BigInt(1)
                ).toFixed(2, undefined, Rounding.ROUND_DOWN) || "")
                setSliderValue(value)
              }}
            /> */}
          </InputWraper>
          <InputWraper style={{ alignItems: "flex-start" }} >
            <InputItem>{t("receive")}</InputItem>
            <InputItem>
              <RaiCurrencyInputPanel
                value={parsedAmounts[Field.OUTPUT]}
                onUserInput={(value: string) => {
                  setTokenValueMethod[Field.OUTPUT](value)
                }}
                showMaxButton={false}
                currency={currencies[Field.OUTPUT]}
                id="swap-currency-output"
              />
            </InputItem>
          </InputWraper>
          {getSearchResult ? 
          <SearchText isShowSearchText={true} >Get the best price from {switchRouterName}</SearchText>
          : 
            <SearchText isShowSearchText={isShowSearchText} >Getting price from {switchRouterName}</SearchText>
          }
          {isBuy ? (
            inputTokenApproval !== ApprovalState.APPROVED ? (
            <ButtonPrimary onClick={inputTokenApproveCallback} > Approve</ButtonPrimary>
            ) : (
              getTokenBalance[Field.INPUT]?.lessThan(tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT]) ?? JSBI.BigInt(0)) ?
              <ButtonError disabled >Insufficient balance</ButtonError>
              :
              <ButtonPrimary onClick={() => setShowConfirm(true)} >Swap</ButtonPrimary>
            )
          ) : (
            outputTokenApproval !== ApprovalState.APPROVED ? (
              <ButtonPrimary onClick={outputTokenApproveCallback} > Approve</ButtonPrimary>
              ) : (
                getTokenBalance[Field.INPUT]?.lessThan(tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT]) ?? JSBI.BigInt(0)) ?
                <ButtonError disabled >Insufficient balance</ButtonError>
                :
                <ButtonPrimary onClick={() => setShowConfirm(true)} >Swap</ButtonPrimary>
              )
          )}
          {/* {approval !== ApprovalState.APPROVED ?
            <ButtonPrimary
              onClick={approveCallback}
            >Approve</ButtonPrimary> :
            <ButtonPrimary onClick={handleSwap} >Swap</ButtonPrimary>} */}
        </OperationWraper>
    </TradeSwapWraper>
  )
}
const BottomData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 8px;
  color: #666666;
`
const BottomDataItem = styled.div`
  
`
const BottomValue  = styled.span`
  margin-left: 6px;
  font-weight: 600;
`
const TradeSwapWraper = styled.div`
  .ant-radio-group {
    width: 100%;
    .ant-radio-button-wrapper:focus-within{
      box-shadow: none;
      border-left: none;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
      display: none;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background: transparent;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${({ theme }) => theme.bg6};
      border-color: ${({ theme }) => theme.bg6};
      border-width: 2px;
      border-right: none;
      border-left: none;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .ant-radio-button-wrapper {
      border: none;
      width: 50%;
      text-align: center;
      border-bottom: 1px solid #D2D3D7;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
      .ant-tabs-tab-btn {
        font-size: 18px;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.bg6}
      }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background: ${({ theme }) => theme.bg6}
    }
    .ant-tabs-tab:hover {
      color: ${({ theme }) => theme.bg6}
    }
  }
`

const OperationWraper = styled.div`
  padding: 0 20px 15px 20px;
`

const InputWraper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  .ant-slider-with-marks {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const InputItem = styled.div`
  font-size: 12px;
  color: #666666;
  #swap-currency-input, #swap-currency-output {
    width: 195px;
  }
`

const AmountColor = styled.span`
  color: #000000;
`

const SearchText = styled.div<{isShowSearchText: boolean}>`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  visibility: ${props => props.isShowSearchText ? "visible" : "hidden"};
`
