

// import { BigNumber } from 'ethers';
import { useMemo } from 'react'
import { useFixedSwapContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
// import { useActiveWeb3React } from './index'
// import { useCurrencyBalance } from "../state/wallet/hooks"
// import { configIdoList } from '../config'
// import { Currency } from '@uniswap/sdk'
// import { TUSDT } from '../constants'
const useIdoInfo = (contractAddress: string) => {
  // const { account } = useActiveWeb3React()
  // const { useToken } = configIdoList[contractAddress]
  // const tradeTokenBalance = useCurrencyBalance(
  //   account ?? undefined, useToken === "ETH" ? Currency.ETHER : TUSDT)
  const fixedSwapContract = useFixedSwapContract(contractAddress)

  const isSaleFunded = useSingleCallResult(fixedSwapContract, 'isSaleFunded')?.result?.[0]
  const erc20 = useSingleCallResult(fixedSwapContract, 'erc20')?.result?.[0]
  const isTokenTrade = useSingleCallResult(fixedSwapContract, 'isTokenTrade')?.result?.[0]
  const tradeValue = useSingleCallResult(fixedSwapContract, 'tradeValue')?.result?.[0]
  const startDate = useSingleCallResult(fixedSwapContract, 'startDate')?.result?.[0]
  const endDate = useSingleCallResult(fixedSwapContract, 'endDate')?.result?.[0]
  const individualMinimumAmount = useSingleCallResult(fixedSwapContract, 'individualMinimumAmount')?.result?.[0]
  const individualMaximumAmount = useSingleCallResult(fixedSwapContract, 'individualMaximumAmount')?.result?.[0]
  const minimumRaise = useSingleCallResult(fixedSwapContract, 'minimumRaise')?.result?.[0]
  const tokensAllocated = useSingleCallResult(fixedSwapContract, 'tokensAllocated')?.result?.[0]
  const tokensForSale = useSingleCallResult(fixedSwapContract, 'tokensForSale')?.result?.[0]
  const isTokenSwapAtomic = useSingleCallResult(fixedSwapContract, 'isTokenSwapAtomic')?.result?.[0]
  const totalRaiseCost = useSingleCallResult(fixedSwapContract, 'totalRaiseCost')?.result?.[0]
  const tokensLeft = useSingleCallResult(fixedSwapContract, 'tokensLeft')?.result?.[0]
  const isOpen = useSingleCallResult(fixedSwapContract, 'isOpen')?.result?.[0]
  const isPreStart = useSingleCallResult(fixedSwapContract, 'isPreStart')?.result?.[0]

  return useMemo(() => {
    return {
      isSaleFunded,
      erc20,
      isTokenTrade,
      tradeValue,
      startDate,
      endDate,
      individualMinimumAmount,
      individualMaximumAmount,
      minimumRaise,
      tokensAllocated,
      tokensForSale,
      isTokenSwapAtomic,
      totalRaiseCost,
      tokensLeft,
      isOpen,
      isPreStart,
      // tradeTokenBalance
    }
  }, [
    isSaleFunded,
    erc20,
    isTokenTrade,
    tradeValue,
    startDate,
    endDate,
    individualMinimumAmount,
    individualMaximumAmount,
    minimumRaise,
    tokensAllocated,
    tokensForSale,
    isTokenSwapAtomic,
    totalRaiseCost,
    tokensLeft,
    isOpen,
    isPreStart,
    // tradeTokenBalance
  ])

}

export default useIdoInfo
