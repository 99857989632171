import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Card, Modal, Table, Button, message, Avatar, Tag, Upload, Statistic, Badge } from 'antd';
import { useHistory, Link } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table';
import { ExclamationCircleOutlined, TwitterOutlined, GithubOutlined, ClockCircleOutlined, CalendarOutlined, RedoOutlined, LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import { useAllPortfolios, useMyPortfolioChartData, useMyPortfolios, useAlertDetail, useManagerInfo, postManagerProfile } from './hooks';
import { useActiveWeb3React } from '../../hooks'
import { useSetTokenContract, useSofiManageContract } from '../../hooks/useContract'
import { PriceChartRangeOption } from 'constants/priceChartEnums'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import Copy from 'components/AccountDetails/Copy'
import { useToken } from '../../hooks/Tokens'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { floatMul, floatDiv } from 'utils/calculate';
import { shortenAddress } from 'utils';
import { post } from 'utils/request';
import { retry, RetryableError } from 'utils/retry'
import WaveSettingModal from './WaveSettingModal'
import MediaModal from './MediaModal';
import ProfileModal from './ProfileModal'
import { APILIST, managerModule } from '../../constants'
import {ReactComponent as Email} from 'assets/svg/email.svg'
import './index.less';
import STS from './index'

const { confirm } = Modal;
const { Countdown } = Statistic;

const Container = styled.div`
    padding: 50px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
`
const Title = styled.h1`
    font-size:32px;
    color: #5542F6;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-bottom: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 20px;
    `};
    span{
        font-size: 14px;
        color: #000;
    }
`

const SubTitle = styled.h2`
    font-size:14px;
    margin-bottom: 20px;
    margin-top: 40px;
`

const SetBalance = ({account, address, value_per_set} : any) => {
  const setsCurrencyInfo = useToken(address)
  const setsTokenBalance = useCurrencyBalance(account ?? undefined, setsCurrencyInfo ?? undefined)
  return <>${setsTokenBalance?.toSignificant(4) ? numeral(floatMul(Number(setsTokenBalance?.toSignificant(4)), value_per_set)).format('0,0.00') : '-'}</>;
}

const SetAmount = ({account, address} : any) => {
  const setsCurrencyInfo = useToken(address)
  const setsTokenBalance = useCurrencyBalance(account ?? undefined, setsCurrencyInfo ?? undefined)
  return <>{setsTokenBalance?.toSignificant(4) ? numeral(setsTokenBalance?.toSignificant(4).toString()).format('0,0.00000000') : '-'}</>;
}

const TokenAmount = ({account, address, total_amount, amount} : any) => {
  const setsCurrencyInfo = useToken(address)
  const setsTokenBalance = useCurrencyBalance(account ?? undefined, setsCurrencyInfo ?? undefined)
  return <>{setsTokenBalance?.toSignificant(4) ? numeral(floatMul(floatDiv(Number(setsTokenBalance?.toSignificant(4)), total_amount),  amount)).format('0,0.00000000') : '-'}</>;
}

const AlertStatus = ({address, account, stsInfo, setShowWaveModal, setStsInfo, forceUpdate, setForceUpdate}: any) => {
  const detail = useAlertDetail(address, account, forceUpdate);
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation();

  const cancelAlert = async () => {
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    const res = await post(`${api_url}/sofi/alert`, {
      ...detail,
      price_alert: 0,
      trade_alert: 0,
    })
    if(res){
      message.success('Cancel success!');
      setForceUpdate();
    }
  };

  const showCancelConfirm = () => {
    confirm({
      title: t('please_note'),
      icon: <ExclamationCircleOutlined />,
      content: t('deleting_price_alarm_settings'),
      okText: t('delete'),
      okType: 'danger',
      cancelText: t('cancel'),
      onOk() {
        console.log('OK');
        cancelAlert()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  return <>
    {!detail?.price_alert && <Button type="default" onClick={(e) => {e.stopPropagation(); setStsInfo({...stsInfo,...detail}); setShowWaveModal(true)}}>{t('edit_price_alert')}</Button>}
    {!!detail?.price_alert && <><Button type="default" onClick={(e) => {e.stopPropagation(); setStsInfo({...stsInfo,...detail}); setShowWaveModal(true);}}>{t('edit_price_alert')}</Button><br/><Button danger style={{width: '80px',  marginTop: '10px'}} onClick={(e) => {e.stopPropagation(); showCancelConfirm()}}>{t('cancel')}</Button></>}
  </>
}

const ManagerStatus = ({address}: any) => {
  const { t } = useTranslation();
  const { chainId, library } = useActiveWeb3React()
  const setTokenContract = useSetTokenContract(address);
  const sofiManageContract = useSofiManageContract(managerModule[chainId ? chainId : 4])
  const [isTrade, setIsTrade] = useState<boolean>(false)
  const [tradeStatus, setTradeStatus] = useState<number>(2)
  const addTransaction = useTransactionAdder()
  const history = useHistory();

  const getIsTrade = async () => {
    if(setTokenContract){
      const status = await setTokenContract.moduleStates(managerModule[chainId ? chainId: 4])
      setTradeStatus(status);
      const _isTrade = status === 2;
      setIsTrade(_isTrade)
    }
  }
  useEffect(() => {
    getIsTrade();
  }, [address])

  const enableTrade = useCallback(async() => {
    if(library && setTokenContract){
      if(tradeStatus === 0){
        setTokenContract.addModule(managerModule[chainId ? chainId: 4])
        .then((res: any) => {
          addTransaction(res, {
            summary: t("add_trading_module")
          })
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setTradeStatus(1);
                    console.log("trade receipt", receipt)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
      }else if(tradeStatus === 1){
        sofiManageContract?.initialize(address)
        .then((res: any) => {
          addTransaction(res, {
            summary: t("trade_initialize")
          })
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setIsTrade(true);
                    console.log("trade receipt", receipt)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
      }
    }
  }, [library, tradeStatus, chainId])
 

  return <>{ isTrade ?
    <Button key="3" type="primary" onClick={(e) => {e.stopPropagation(); history.push(`/sts/manage/${address}`)}}>
      {t("manage")}
    </Button>
    :
    <Button key="1" onClick={(e) => {e.stopPropagation();enableTrade()}}>{tradeStatus === 0 ? t('add_trading_module'): t('enable_to_trading')}</Button>
    }
  </>
}

export default function MyPortfolio() {
  const { t } = useTranslation();
  const { chainId, account, library } = useActiveWeb3React()
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.DAILY_PRICE_RANGE)
  const [refresh, setRefresh] = useState<number>(1);
  const { chartData } = useMyPortfolioChartData(account, chartRange, refresh);
  const [forceUpdate, setForceUpdate] = useState(0)
  const [refreshInfo, setRefreshInfo] = useState<number>(1);
  const [refreshTime, setRefreshTime] = useState<any>(new Date());
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const { managerInfo } = useManagerInfo(account, refreshInfo);
  const { allPortfolios: myCreatedPortfolios  } = useAllPortfolios(account);
  const { allPortfolios: myPortfolios  } = useMyPortfolios(account);
  const [showWaveModal,  setShowWaveModal] = useState(false)
  const [showMediaModal,  setShowMediaModal] = useState(false)
  const [showProfileModal,  setShowProfileModal] = useState(false)
  const [mediaType, setMediaType] = useState('')
  const history = useHistory();
  const [stsInfo, setStsInfo] = useState({})
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const columns: ColumnsType<any> = [
    {
      title: t("portfolios"),
      dataIndex: 'symbol',
      width: '100px',
      fixed: 'left',
      render: (v: string, row: any) => <div>{v}{!!row?.promote_end_at && <ClockCircleOutlined className='promote_tag' style={{fontSize: '14px', color: '#52C41A'}}/>}</div>
    },
    {
      title: t("price"),
      width: '100px',
      dataIndex: 'value_per_set',
      render: (value_per_set: number) => `$${numeral(value_per_set).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.value_per_set - b.value_per_set,
      },
    },
    {
      title: t("market_cap"),
      width: '100px',
      dataIndex: 'total_value',
      render: (toatl_value: number) => `$${numeral(toatl_value).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.total_value - b.total_value,
      },
    },
    {
      title: '',
      width: '100px',
      dataIndex: 'components',
      render: (components: any) => {
        return components.map(({symbol, address} : any) => <img key={address} style={{width: '15px', height: '15px'}} src={getTokenLogoURL(symbol)}/>)
      }
    },
    {
      title: '',
      width: '200px',
      dataIndex: 'address',
      render: (address: string, row: any) => <>
        <AlertStatus address={address} account={account} stsInfo={row} setShowWaveModal={setShowWaveModal} setStsInfo={setStsInfo} forceUpdate={forceUpdate} setForceUpdate={() => {setForceUpdate(forceUpdate + 1)}}/>
        &nbsp;
        <ManagerStatus address={address}/>
      </>
    }
  ];

  const mycolumns: ColumnsType<any> = [
    {
      title: t("tokens"),
      dataIndex: 'symbol',
      fixed: 'left',
      width: '100px',
      render: (v: string, row: any) => <div>{v}{!!row?.promote_end_at && <ClockCircleOutlined className='promote_tag' style={{fontSize: '14px', color: '#52C41A'}}/>}</div>
    },
    {
      title: t("current_price"),
      dataIndex: 'value_per_set',
      width: '150px',
      render: (value_per_set: number) => `$${numeral(value_per_set).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.value_per_set - b.value_per_set,
      },
    },
    {
      title: t("amount"),
      dataIndex: 'address',
      width: '160px',
      render: (address: string) => <SetAmount account={account} address={address} />
    },
    {
      title: t("balance"),
      dataIndex: 'value_per_set',
      width: '150px',
      render: (value_per_set: any, row: any) => {
        return <SetBalance account={account} address={row.address} value_per_set={value_per_set}/>
      }
    },
    {
      title: '24 (24h)',
      dataIndex: 'price_changed_24hr',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.price_changed_24hr - b.price_changed_24hr,
      },
    },
    {
      title: '',
      dataIndex: 'address',
      // render: (address: string, row: any) => <AlertStatus address={address} account={account} stsInfo={row} setShowWaveModal={setShowWaveModal} setStsInfo={setStsInfo} forceUpdate={forceUpdate} setForceUpdate={() => {setForceUpdate(forceUpdate + 1)}}/>
      render: () => <></>
    }
  ];
  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
  
const beforeUpload = (file: any) => {
    setLoading(true)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return false;
    }
    getBase64(file, async (url: any) => { 
      setImageUrl(url);
      if(account && chainId && library){
        const res = await postManagerProfile({
          photo_url: url,
        }, account, chainId, library);
        if(res){
            message.success(t('setting_success'))
            setLoading(false)
            setRefreshInfo(refreshInfo+1)
        }
      }
    });
    setLoading(false)
    return isJpgOrPng && isLt2M;
}

const refreshBlance = () => {
  setRefresh(refresh + 1);
  setRefreshLoading(true)
  setRefreshTime(new Date())
  setTimeout(() => {
    setRefreshLoading(false)
  }, 1000)
}

const uploadButton = (
    <div className="upload_btn">
      {loading ? <LoadingOutlined style={{color: '#5542F6', fontSize: '14px'}}/> : <div className='default'><Avatar size={100} icon={<UserOutlined />}/><PlusOutlined className='upload'/></div>}
    </div>
  );

  return (
    <STS>
      <Container>
          {/* <div style={{textAlign: 'right'}}><Button type="default" style={{marginBottom: '30px'}} onClick={() => {setShowProfileModal(true)}}>Edit Profile</Button></div> */}
          {/* <Card className="chartCard" title={<span>{`${t("total_set_value")} (${t("updated_on")} ${moment(chartData?.updated_at*1000).format('YYYY-MM-DD HH:mm:ss')})`}<br/><b className="price">${chartData?.price >= 0 ? chartData?.price.toFixed(2) : '-'}</b><b className={chartData.price_changed_24hr > 0 ? "up":"down"}>{chartData.price_changed_24hr ? `${chartData.price_changed_24hr}%`: '' }</b></span>} extra={<></>}>
            <SimplePriceChart data={chartData.history} chartRange={chartRange} setChartRange={setChartRange}/>
          </Card> */}
          <Card className="myInfo">
              <div>
                <div className="avatar">
                  <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                      {managerInfo?.photo_url ? <Avatar src={managerInfo?.photo_url} /> : uploadButton}
                    </Upload>
                </div>
                <div className="links">
                  <div>{account && shortenAddress(account)}<Copy toCopy={account || ''}></Copy></div>
                  <div>
                    <Tag className="date" icon={<CalendarOutlined style={{color: '#5542F6'}}/>} color="#EEECFE">{managerInfo.created_at ? -moment(managerInfo.created_at * 1000).diff(moment(), 'days') : 0} days</Tag>
                  </div>
                  <div>
                    <Tag className="twitter" onClick={() => {setMediaType('twitter'); setShowMediaModal(true)}} icon={<TwitterOutlined style={{color: '#00ACED'}}/>} color="#E7F8FF">{managerInfo.twitter_url ? t('edit'):`${t('add')} +`}</Tag>
                    <Tag onClick={() => {setMediaType('github'); setShowMediaModal(true)}} icon={<GithubOutlined style={{color: '#000'}}/>} color="#F0F0F0">{managerInfo.github ? t('edit'):`${t('add')} +`}</Tag>
                    <Tag onClick={() => {setMediaType('email'); setShowMediaModal(true)}} icon={<Email className="anticon" style={{fill: '#000'}}/>} color="#F0F0F0">{managerInfo.email ? t('edit'):`${t('add')} +`}</Tag>
                  </div>
                </div>
              </div>
              <div>
                  <div>{t('total_portfolio_balance')}</div>
                  <div><b className="price">${chartData.price}</b><b className={chartData.price_changed_24hr > 0 ? "up":"down"}>{`${chartData.price_changed_24hr > 0 ? `+${chartData.price_changed_24hr}%`: `${chartData.price_changed_24hr}%`}`}</b></div>
                  <div className="update_time">{t('updated_on')} {moment(refreshTime).format('YYYY-MM-DD HH:mm:ss')}&nbsp;&nbsp;<button onClick={refreshBlance}>{refreshLoading ? <LoadingOutlined /> : <RedoOutlined />}&nbsp;Refresh</button></div>
              </div>
          </Card>
          <SubTitle>{t("my_portfolio")}</SubTitle>
          <Table 
            className="marketTable" 
            columns={mycolumns} 
            scroll={{ x: 500 }}
            dataSource={myPortfolios}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: row => 
                {
                  return row.components.map(({symbol, name, amount, price_usd, value_per_set} : any) => 
                  <ul className="expandList" key="address"> 
                    <li><img style={{width: '15px', height: '15px'}} src={getTokenLogoURL(symbol)}/>&nbsp;{name}</li>
                    <li>${numeral(price_usd.toFixed(2)).format('0,0.00')}</li>
                    <li><TokenAmount account={account} address={row.address} amount={amount} total_amount={row.total_amount}/></li>
                    <li><SetBalance account={account} address={row.address} value_per_set={value_per_set}/></li>
                  </ul>
                )}
              ,
            }}
            pagination={false}
            showSorterTooltip={false}
            rowKey="address"
            onRow={(record: any) => {
              return {
                onClick: event => {
                  console.log('event', event);
                  history.push(`/sts/portfolio/${record.address}`)
                }, // 点击行
              };
            }}
          />
          <SubTitle>{t("my_created_portfolio")}</SubTitle>
          <Table 
            className="marketTable" 
            columns={columns}
            scroll={{ x: 500 }}
            dataSource={myCreatedPortfolios} 
            pagination={false}
            showSorterTooltip={false}
            rowKey="address"
            onRow={(record: any) => {
              return {
                onClick: event => {
                  console.log('event', event);
                  history.push(`/sts/portfolio/${record.address}`)
                }, // 点击行
              };
            }}
          />
          <MediaModal isOpen={showMediaModal} type={mediaType} managerInfo={managerInfo} refreshManagerInfo={() => {setRefreshInfo(refreshInfo+1)}} handleCancel={() => {setShowMediaModal(false)}}/>
          <WaveSettingModal isOpen={showWaveModal} stsInfo={stsInfo} setForceUpdate={() => {setForceUpdate(forceUpdate + 1)}} handleCancel={() => {setShowWaveModal(false)}}/>
          <ProfileModal isOpen={showProfileModal}  managerInfo={managerInfo} setForceUpdate={() => {setForceUpdate(forceUpdate + 1)}} handleCancel={() => {setShowProfileModal(false)}}/>
        </Container>
    </STS> 
  )
}
