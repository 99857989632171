import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { TokenAmount } from '@uniswap/sdk';
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import { useStakingInfo } from '../../state/stake/hooks'
import CurrencyLogo from '../../components/CurrencyLogo'
import Modal from './Modal'
import { ButtonPrimary, ButtonLight } from '../../components/Button'
import STS from '../STS'
import { Container } from '../STS/Markets'
import banner from '../../assets/images/FARM.png'
import numeral from 'numeral';

export default function Farm() {
  const stakingInfos = useStakingInfo()
  console.log("stakingInfos", stakingInfos)
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnStakingModal, setShowUnStakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  const [stakingInfo, setStakingInfo] = useState<any>()
  const { t } = useTranslation()
  const history = useHistory();
  const FarmColumn: ColumnsType<any> = [
    {
      title: t('pool'),
      dataIndex: 'tokens',
      key: 'tokens',
      align: 'center',
      render: (text: any) => {
        return (
          <StyleTokenName>
            <CurrencyLogo currency={text[0]} size={'24px'} />
            <CurrencyLogo currency={text[1]} size={'24px'} />
            {/* <DoubleCurrencyLogo currency0={text[0].symbol} currency1={text[1].symbol} /> */}
            &nbsp;
            {`${text[0].symbol}-${text[1].symbol} RLP`} 
          </StyleTokenName>
        )
      }
    },
    {
      title: t('APR'),
      dataIndex: 'APR',
      key: 'APR',
      align: 'center',
    },
    {
      title: `${t('staked')}(RLP)`,
      dataIndex: 'stakedAmount',
      key: 'stakedAmount',
      align: 'center',
      render: (stakedAmount: TokenAmount) => {
        return numeral(stakedAmount?.toFixed(2)).format("0,0.00")
      }
    },
    {
      title: t('rewards'),
      dataIndex: 'earnedAmount',
      key: 'earnedAmount',
      align: 'center',
      render: (earnedAmount: TokenAmount, record: any) => {
        return (
          <>
          {numeral(earnedAmount?.toFixed(2)).format("0,0.00")}
          {Number(earnedAmount?.toFixed(2)) > 0 && <ButtonLight onClick={() => {setStakingInfo(record); setShowClaimRewardModal(true)}}>{t('claim')}</ButtonLight>}    
          </>
        )
      }
    },
    {
      title: `${t('balance')}(RLP)`,
      dataIndex: 'lpBalanceAmount',
      key: 'lpBalanceAmount',
      align: 'center',
      render: (lpBalanceAmount: TokenAmount, record: any) => {
        return (
          <>
          {numeral(lpBalanceAmount?.toFixed(2)).format("0,0.00")}
          <ButtonLight onClick={() => {history.push(`/liquidity/${record.tokens[1]?.address}/${record.tokens[0]?.address}`)}}>{t('get_RLP')}</ButtonLight>
          </>
        )
      }
    },
    {
      title: t('operations'),
      dataIndex: 'operations',
      key: 'operations',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <ButtonContainer>
            {record.stakedAmount?.toSignificant(4) > 0 && <ButtonLight onClick={() => { setStakingInfo(record); setShowUnStakingModal(true); }}>{t('unstake')}</ButtonLight>}
            <ButtonPrimary onClick={() => {
              setStakingInfo(record)
              setShowStakingModal(true)
            }}>{t('stake')}</ButtonPrimary>
          </ButtonContainer>
        )
      }
    },
  ]

  return (
    <STS>
      <Container>
        <UserFarm>
          <FarmContent>
            <FarmWraper>
              <FarmBanner>
                <img src={banner} alt="banner" />
              </FarmBanner>
              <FarmTitle>Farm</FarmTitle>
              <Table
                dataSource={stakingInfos}
                columns={FarmColumn}
                pagination={false}
                rowKey="stakingRewardAddress"
              />
            </FarmWraper>
          </FarmContent>
          <Modal 
            showStakingModal={showStakingModal}
            setShowStakingModal={setShowStakingModal} 
            showUnstakingModal={showUnStakingModal} 
            setShowUnstakingModal={setShowUnStakingModal}
            showClaimRewardModal={showClaimRewardModal} 
            setShowClaimRewardModal={setShowClaimRewardModal}
            stakingInfo={stakingInfo}
          />
        </UserFarm>
      </Container>
    </STS>
  ) 
}

const UserFarm = styled.div`
  /* padding-top: 68px; */
`

const FarmContent = styled.div`
  /* padding-top: 70px; */
  margin: 0 auto;
`

const FarmTitle = styled.div`
  color: ${({ theme }) => theme.bg6};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
`
const FarmBanner = styled.div`
  width: 100%;
  margin-bottom: 30px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
`

const CommonWraper = styled.div`
  padding-bottom: 50px;
`
const FarmWraper = styled(CommonWraper)`
  .ant-table-thead > tr > th {
    background: #FFFFFF;
    color: ${({ theme }) => theme.bg6};
  }
`

const StyleTokenName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  img, svg {
    margin-right: 8px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button{
    &:first-of-type{
      margin-right: 10px;
    }
  }
`