import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
// import { ThemeContext } from 'styled-components'
import { Tabs, Input } from "antd"
import RaiCurrencyInputPanel from '../../../components/RaiCurrencyInputPanel'
// import { useSwapState } from '../../../state/swap/hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, ETHER, TokenAmount } from '@uniswap/sdk'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { ButtonPrimary, ButtonError } from '../../../components/Button'
import { LightCard } from '../../../components/Card'
import { AutoColumn } from '../../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../../components/TransactionConfirmationModal'
// import CurrencyInputPanel from '../../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../../components/DoubleLogo'
// import { AddRemoveTabs } from '../../../components/NavigationTabs'
// import { MinimalPositionCard } from '../../../components/PositionCard'
import { Dots, Wrapper } from '../../Pool/styleds'
import { ROUTER_ADDRESS } from '../../../constants'
// import { PairState } from '../../../data/Reserves'
import { useActiveWeb3React } from '../../../hooks'
import { useCurrency } from '../../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../../hooks/useApproveCallback'
import useTransactionDeadline from '../../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../../state/application/hooks'
import { Field } from '../../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../../state/mint/hooks'

import { useTransactionAdder } from '../../../state/transactions/hooks'
import { useIsExpertMode, useUserSlippageTolerance } from '../../../state/user/hooks'
import { TYPE } from '../../../theme'
import { calculateGasMargin, calculateSlippageAmount, getRaiRouterContract } from '../../../utils'
import { maxAmountSpend } from '../../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../../utils/wrappedCurrency'
// import AppBody from '../../AppBody'
// import { Dots, Wrapper } from '../../Pool/styleds'
import { ConfirmAddModalBottom } from '../../AddLiquidity/ConfirmAddModalBottom'
import { currencyId } from '../../../utils/currencyId'
// import { PoolPriceBar } from '../../AddLiquidity/PoolPriceBar'
import { useIsTransactionUnsupported } from 'hooks/Trades'
// import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import Row, { RowBetween, RowFlat } from '../../../components/Row'
import Review from '../../AddLiquidity/'
import Liquidity from 'pages/Liquidity'
import RemoveLiquidity from '../../Liquidity/components/RemoveLiquidity'

const { TabPane } = Tabs;
interface AddLiquidityInterface {
  currencyIdA: string;
  currencyIdB: string;
  operationType: string | undefined;
}
export default function AddLiquidity ({
  currencyIdA,
  currencyIdB,
  operationType
}: AddLiquidityInterface) {
  console.log("operationType", operationType)
  function callback(key: string) {
    console.log(key);
  }

  const { account, chainId, library } = useActiveWeb3React()
  // const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  // const oneCurrencyIsWETH = Boolean(
  //   chainId &&
  //     ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
  //       (currencyB && currencyEquals(currencyB, WETH[chainId])))
  // )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    // pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const {
    onFieldAInput,
    onFieldBInput
  } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [
    showConfirm,
    setShowConfirm
  ] = useState<boolean>(false)
  const [
    attemptingTxn,
    setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
  )


  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRaiRouterContract(chainId, library, account)

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString()
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString()
      ]
      value = null
    }
    console.log("args", args.toString())
    setAttemptingTxn(true)

    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              `${t("add")} ` +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
              ' ' +
              currencies[Field.CURRENCY_A]?.symbol +
              ` ${t("and")} ` +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
              ' ' +
              currencies[Field.CURRENCY_B]?.symbol
          })

          setTxHash(response.hash)
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ` ${t("pool_tokens")}`}
          </Text>
        </Row>
        <TYPE.italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '}>
          {t("output_tips", { percent: allowedSlippage /
            100 })}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        
      } else {
        
      }
    },
    [currencyIdB, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
        
        } else {
        
        }
      } else {
        
      }
    },
    [currencyIdA, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS)
  return (
    <TradeSwapWraper>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => (
          <ConfirmationModalContent
            title={noLiquidity ? t("you_are_creating_a_pool") : t("you_will_receive")}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
        currencyToAdd={pair?.liquidityToken}
      />
      <Tabs centered defaultActiveKey={operationType} onChange={callback}>
        <TabPane tab="Add" key="add">
          <AddWraper>
            <OperationWraper>
              <InputWraper>
                <InputItem>
                {currencies[Field.CURRENCY_A]?.symbol}
                {/* <BalanceWraper>
                  <BalanceText>Balance: {currencyBalances[Field.CURRENCY_A]?.toSignificant(6)}</BalanceText>
                  <MaxButton
                    onClick={() => {onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')}}>Max</MaxButton>
                </BalanceWraper> */}
                </InputItem>
                <InputItem>
                  <RaiCurrencyInputPanel
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onFieldAInput}
                    onMax={() => {
                      onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                    }}
                    onCurrencySelect={handleCurrencyASelect}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                    currency={currencies[Field.CURRENCY_A]}
                    id="addLiquidity-input-tokena"
                    showCommonBases
                  />
                </InputItem>
              </InputWraper>
              <InputWraper>
                <InputItem>
                {currencies[Field.CURRENCY_B]?.symbol}
                {/* <BalanceWraper>
                  <BalanceText>Balance: {currencyBalances[Field.CURRENCY_B]?.toSignificant(6)}</BalanceText>
                  <MaxButton onClick={() => {
                    onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                  }}>Max</MaxButton>
                </BalanceWraper> */}
                </InputItem>
                <InputItem>
                  <RaiCurrencyInputPanel
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onFieldBInput}
                    onMax={() => {
                      onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                    }}
                    onCurrencySelect={handleCurrencyASelect}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                    currency={currencies[Field.CURRENCY_B]}
                    id="addLiquidity-input-tokenb"
                    showCommonBases
                  />
                </InputItem>
              </InputWraper>
              <InputWraper>
                <InputItem>{t("share_of_pool")}</InputItem>
                <InputItem>
                  {poolTokenPercentage?.toFixed(4) ?? '0'} {'%'}
                </InputItem>
              </InputWraper>
              {!account ? (
                <ButtonPrimary onClick={toggleWalletModal}>{t("conect_wallet")}</ButtonPrimary>
              ) : (
                <AutoColumn gap={'md'}>
                  {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING)
                    &&
                    isValid && (
                      <RowBetween>
                        {approvalA !== ApprovalState.APPROVED && (
                          <ButtonPrimary
                            onClick={approveACallback}
                            disabled={approvalA === ApprovalState.PENDING}
                            width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                          >
                            {approvalA === ApprovalState.PENDING ? (
                              <Dots>{t("approving")} {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                            ) : (
                              `${t("approve")} ` + currencies[Field.CURRENCY_A]?.symbol
                            )}
                          </ButtonPrimary>
                        )}
                        {approvalB !== ApprovalState.APPROVED && (
                          <ButtonPrimary
                            onClick={approveBCallback}
                            disabled={approvalB === ApprovalState.PENDING}
                            width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                          >
                            {approvalB === ApprovalState.PENDING ? (
                              <Dots>{t("approving")} {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                            ) : (
                              `${t("approve")} ` + currencies[Field.CURRENCY_B]?.symbol
                            )}
                          </ButtonPrimary>
                        )}
                      </RowBetween>
                    )}
                  <ButtonError
                    onClick={() => {
                      setShowConfirm(true)
                    }}
                    disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                    error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {error ?? t("supply")}
                    </Text>
                  </ButtonError>
                </AutoColumn>
              )}
            </OperationWraper>
            <AddRight>
              <RightContent>
                <ContentTip>
                  {t("add_liquidity_tips")}
                </ContentTip>
                <LiquidityInfo>
                  <DataWraper>
                    <span>
                      {currencies[Field.CURRENCY_A]?.symbol} {t("balance")}
                    </span>
                    <span>
                      {currencyBalances[Field.CURRENCY_A]?.toSignificant(6)}
                    </span>
                  </DataWraper>
                  <DataWraper>
                    <span>
                      {currencies[Field.CURRENCY_B]?.symbol} {t("balance")}
                    </span>
                    <span>
                      {currencyBalances[Field.CURRENCY_B]?.toSignificant(6)}
                    </span>
                  </DataWraper>
                  <DataWraper>
                    <span>
                      {t("rate")}
                    </span>
                    <span>
                      {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
                        currencies[Field.CURRENCY_B]?.symbol
                      }`}
                    </span>
                  </DataWraper>
                  <DataWraper>
                    <span>
                      {t("share_of_pool")}
                    </span>
                    <span>
                      {poolTokenPercentage?.toFixed(4) ?? '0'} {'%'}
                    </span>
                  </DataWraper>
                  {/* <DataWraper>
                    <span>
                      Transaction Fee Earned
                    </span>
                    <span>

                    </span>
                  </DataWraper> */}
                </LiquidityInfo>
              </RightContent>
            </AddRight>
          </AddWraper>
        </TabPane>
        <TabPane tab="Remove" key="remove">
          <RemoveLiquidity
            currencyIdA={currencyIdA}
            currencyIdB={currencyIdB}
          />
        </TabPane>
      </Tabs>
    </TradeSwapWraper>
  )
}
const ContentTip = styled.div`
  background: #EEF4FF;
  font-size: 10px;
  text-align: center;
  padding: 11px 17px;
  color: #666666;
`

const AddRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 767px) {
    width: 100%;
  }
`

const RightContent = styled.div`
  margin-top: 7px;
  width: 300px;
  /* height: 179px; */
  background: #F4F5F8;
  padding: 4px 12px 12px;
  @media(max-width: 767px) {
    width: 100%;
  }
`

const AddWraper = styled.div`
  display: flex;
  @media(max-width: 767px) {
    flex-direction: column;
  }
`

const BalanceWraper = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.bg6};

`
const BalanceText = styled.span`

`

const MaxButton = styled.span`
  margin-left: 6px;
  cursor: pointer;
  font-size: 8px;
  padding: 3px;
  background: #F4F5F8;
  width: 22px;
  height: 11px;
  color: ${({ theme }) => theme.bg6};
`
const TradeSwapWraper = styled.div`
  background: #FFFFFF;
  padding-bottom: 55px;

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
      .ant-tabs-tab-btn {
        font-size: 18px;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.bg6}
      }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background: ${({ theme }) => theme.bg6}
    }
    .ant-tabs-tab:hover {
      color: ${({ theme }) => theme.bg6}
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  @media(max-width: 767px) {
    padding: 30px;
  }
`

const OperationWraper = styled.div`
  margin-top: 32px;
  padding: 0 20px 15px 20px;
  width: 50%;
  @media(max-width: 767px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const InputWraper = styled.div`
  height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  .ant-slider-with-marks {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const InputItem = styled.div`
  position: relative;
  font-size: 12px;
  color: #666666;
  #addLiquidity-input-tokena, #addLiquidity-input-tokenb {
    width: 195px;
  }
`


const LiquidityInfo = styled.div`
  margin-top: 13px;

`

const DataWraper = styled.div`
 display: flex;
 justify-content: space-between;
 font-size: 10px;
 color: #000000;
 margin-bottom: 6px;
`