import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import numeral from 'numeral';
import STS from '../STS'
import { Tabs, Table, Switch } from 'antd';
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import BSCLogo from '../../assets/svg/bsc-logo.svg'
import { Container } from '../STS/Markets'
import PairsArr from '../../pairs_develop.json'
import { usePairs, PairState } from '../../data/Reserves'
import { Token, Pair, JSBI, Fraction, TokenAmount, ChainId, Price } from '@uniswap/sdk';
import { useActiveWeb3React } from '../../hooks'
import unionBy from 'lodash.unionby'
import { useTokenBalances, useETHBalances } from '../../state/wallet/hooks'
import { ColumnsType } from 'antd/es/table';
import CurrencyLogo from '../../components/CurrencyLogo'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useMyPortfolios } from '../STS/hooks'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useStakingInfo } from '../../state/stake/hooks'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import { formatUnits } from 'ethers/lib/utils';
import { TUSDT } from '../../constants'
import { tryParseAmount } from '../../state/swap/hooks'
import MaticLogo from '../../assets/images/matic-token-icon.png'

interface WalletInfo {
  token: Token;
  price: string;
  balance: string | undefined;
  value: string | undefined;
  isSTS: boolean | undefined;
  operations: string | undefined;
  avatar: string | undefined;
}

interface LiquidityInfo {
  name: string;
  totalValue: any;
  liquidity: Pair;
  poolTokenAmount: string;
  operations: string | undefined;
}

export default function Wallet() {
  const { t } = useTranslation()
  const { TabPane } = Tabs;
  const { chainId, account } = useActiveWeb3React()
  const { allPortfolios: myPortfolios  } = useMyPortfolios(account);
  const allPairsData = usePairs(PairsArr.map((pair: any) => [pair.token0, pair.token1]));
  const allPairs = PairsArr.map((pair: any, i: number) => {
    return allPairsData[i][0] === PairState.EXISTS ?
        {
            ...pair,
            pairInfo: allPairsData[i][1]
        } : { ...pair }
})
.filter(pair => pair.chainId === chainId)
.filter(pair => pair.symbol.indexOf("USDT") > -1)
  const tokensAmount = allPairs.map((pair: any) => {
    return [
      {
        token: pair.token0,
        tokenPrice: pair?.pairInfo?.token0Price
      },
      {
        token: pair.token1,
        tokenPrice: pair?.pairInfo?.token1Price
      },
    ]
  })
  .reduce((prev: any, curr: any) => (prev.concat(curr)), [])

  const filterTokensAmount = unionBy(tokensAmount, "token.address")
  const [TotalSTSAmount, setTotalSTSAmount] = useState(0);
  const tokens = filterTokensAmount.map((tokenAmount: any) => tokenAmount.token)
  const LPAddressArray = allPairs.map(pair => pair.pairInfo?.liquidityToken)
  const LPArraySupplies: any = useMultipleContractSingleData(LPAddressArray.map((lpToken: any) => lpToken?.address), ERC20_INTERFACE, 'totalSupply')
  const userLPBalance = useTokenBalances(account ?? undefined, LPAddressArray)
  const userTokensBalance = useTokenBalances(account ?? undefined, tokens)
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [totalAssetsValue, setTotalAssetsValue] = useState("");
  const [totalStakingValue, setTotalStakingValue] = useState("");
  const [isNoShowBalanceEqualZero, setIsNoShowBalanceEqualZero] = useState(true)
  const stakingInfos = useStakingInfo()

  const TabContent = (ChainType: string) => {

    return (
      <TabItem>
        <TabItemLeft>
          <ChainImg src={ChainType === "Ethereum" ? EthereumLogo : MaticLogo} />
          <ChainName>{ChainType === 'Ethereum' ? "Ethereum" : "Matic"}</ChainName>
        </TabItemLeft>
        <TabItemRight>
          {/* $2500 | <PercentText>50%</PercentText> */}
        </TabItemRight>
      </TabItem>
    )
  }
  const walletColumn: ColumnsType<WalletInfo> = [
    {
      // width: 150,
      title: t("token_name"),
      dataIndex: 'token',
      key: 'tokenName',
      // fixed: 'left',
      render: (text: Token, record: any) => {
        return (
          <StyleTokenName>
            {record.isSTS ? <StsLogo src={record.avatar} /> : <CurrencyLogo currency={text} />}
            {` `}
            {text.symbol}
          </StyleTokenName>
        )
      }
    },
    {
      title: t('price'),
      dataIndex: 'price',
      render: (v: Price | string) => v ? `$${numeral(v instanceof Price ? v?.toFixed(6) : v).format('0,0.000000')}` : '-',
    },
    {
      title: t("balance"),
      dataIndex: 'balance',
      key: 'balance',
      render: (v: any) => v ? numeral(v).format('0,0.00000000') : '-',
    },
    {
      title: t("value"),
      dataIndex: 'value',
      key: 'value',
      render: (v: any) => v ? `${numeral(v).format('$ 0,0.00')}` : '-',
    },
    {
      // width: 150,
      title: t("operations"),
      dataIndex: 'operations',
      key: 'operations',
      fixed: "right",
      render: (text: boolean, record: any) => {
        console.log("record", record)
        if (record.isSTS) {
          return (
            <Link to={{
              pathname: `/sts/portfolio/${record.token.address}`
            }}>
              <TradeButton>{t("trade")}</TradeButton>
            </Link>
          )
        }
        return (
          <Link to={{
            pathname: `${text ? "/" : "/aggregateSwap"}`
          }}>
            <TradeButton>{t("trade")}</TradeButton>
          </Link>
        )
      }
    },
  ]

  const WalletData: WalletInfo[] = filterTokensAmount.map((token: any) => {
    const tokenValue = (token: any) => {
      if (!token?.tokenPrice) return ""
      if (userTokensBalance[token.token.address]?.equalTo(JSBI.BigInt(0))) return "$ 0"
      if (token?.token.symbol === "USDT") {
        return userTokensBalance ? userTokensBalance[token.token.address]?.toFixed(2) : ""
      }
      return formatUnits(token?.tokenPrice?.raw.multiply(userTokensBalance[token?.token?.address]?.raw || JSBI.BigInt(0)).toFixed(0), 18) || "0"
    }

    const tokenBalance = (token: any) => {
      return userTokensBalance ? userTokensBalance[token.token.address]?.toFixed(6) : ""
    }

    return {
      token: token?.token,
      price:  token?.token.symbol === "USDT" ? "1" : token?.tokenPrice,
      balance:  tokenBalance(token),
      value: Object.keys(userTokensBalance).length ? tokenValue(token) : "",
      isSTS: false,
      avatar: "",
      operations: ""
    }
  })

  if (myPortfolios.length) {
    myPortfolios.forEach((myPortfolio: any) => {
      WalletData.push({
        token: new Token(ChainId.RINKEBY, myPortfolio.address, 18, myPortfolio.symbol),
        price: `${String(myPortfolio.value_per_set)}`,
        balance: String(myPortfolio.total_amount.toFixed(2)),
        value: String((myPortfolio.value_per_set * myPortfolio.total_amount).toFixed(2)),
        isSTS: true,
        avatar: myPortfolio.avatar_uri,
        operations: ""
      })
    });

  }
  const liquidityColumn: ColumnsType<LiquidityInfo> = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        return (
          <PairName>
            <DoubleCurrencyLogo currency0={text?.token0} currency1={text?.token1} size={20} margin={true}/>
            {text.symbol}
          </PairName>
        )
      }
    },
    {
      title: t("total_value"),
      dataIndex: "totalValue",
      key: "totalValue",
      render: (text: any) => {
        return (
          <StyledTotalValue>
            {text.base === 0 ? `$ ${numeral(text?.pairInfo?.token1Price?.quote(text?.pairInfo.reserve1).add(text?.pairInfo.reserve0)).format('0,0.00')}`: ""}
            {text.base === 1 ? `$ ${numeral(text?.pairInfo?.token0Price?.quote(text?.pairInfo.reserve0).add(text?.pairInfo.reserve1)).format('0,0.00')}`: ""}
          </StyledTotalValue>
        )
      }
    },
    {
      title: t("liquidity"),
      dataIndex: "liquidity",
      key: "liquidity",
      render: (text: Pair) => {
        return (
          <PairLiquidity>
            {numeral(JSBI.divide(text?.reserve0.raw ?? JSBI.BigInt(10), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(text?.reserve0.token.decimals ?? 10))).toString()).format('0,0.00000000')} {` ${text?.reserve0.token.symbol || ""}`}
            <br />
            {numeral(JSBI.divide(text?.reserve1.raw ?? JSBI.BigInt(10), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(text?.reserve1.token.decimals ?? 10))).toString()).format('0,0.00000000')} {` ${text?.reserve1.token.symbol || ""}`}
          </PairLiquidity>
        )
      }
    },
    {
      title: t("pool_tokens_LP"),
      dataIndex: "poolTokenAmount",
      key: "poolToken",
      render: (v: any) => v ? numeral(v).format('0,0.00000000') : '-',
    },
    {
      title: t("operations"),
      dataIndex: "Operations",
      key: "Operations",
      fixed: "right",
      render: (text: string, record: any) => {
        const pathnameString = `/liquidity/${record.liquidity?.token0.address}/${record.liquidity?.token1.address}`
        return (
          <>
            <Link to={{
              pathname: pathnameString,
              search: "?type=add",
            }}>
              <TradeButton>{t("add")}</TradeButton>
            </Link>
            <Link to={{
              pathname: pathnameString,
              search: "?type=remove",
            }}>
              <TradeButton>{t("remove")}</TradeButton> 
            </Link>
            
          </>
        )
      }
    }
  ]
  const liquidityData: LiquidityInfo[] = allPairs.map(pair => {
    return {
      name: pair,
      totalValue: pair,
      liquidity: pair?.pairInfo,
      poolTokenAmount: userLPBalance[pair?.pairInfo?.liquidityToken?.address]?.toSignificant(5) || "0",
      operations: "2222"
    }
  })
  const TotalSTSAssets = myPortfolios.reduce((pre: any, next: any) => {
    return pre + next.value_per_set * next.total_amount
  }, 0)
  let totalUsdValue: any = ""
  if (userTokensBalance && userEthBalance) {
    // debugger
    totalUsdValue  = filterTokensAmount.map((token: any) => {
      if (!token?.tokenPrice) return new Fraction(JSBI.BigInt(0), JSBI.BigInt(1))
      if (token?.token.symbol === "USDT") {
        return userTokensBalance ? userTokensBalance[token.token.address]?.raw: ""
      }
      return token?.tokenPrice?.raw.multiply(userTokensBalance[token.token.address]?.raw)
    })
    .reduce((pre, next) => {
      return pre.add(next);
    }, new Fraction(JSBI.BigInt(1), JSBI.BigInt(1)))
  }

  useEffect(() => {
    setTotalSTSAmount(TotalSTSAssets.toFixed(2))
    setTotalAssetsValue(TotalSTSAssets.toFixed(2))
  }, [TotalSTSAssets])

  useEffect(() => {
    if (totalUsdValue && TotalSTSAssets && totalStakingValue) {
      setTotalAssetsValue(TotalSTSAssets.toFixed(2))
      // setTotalAssetsValue(formatUnits(totalUsdValue.add(tryParseAmount(String(TotalSTSAssets), chainId ? TUSDT[chainId] : TUSDT[4])?.raw).add(totalStakingValueFraction).toFixed(0), 18))
    }
  }, [totalAssetsValue]);

  let totalStakingValueFraction: any = ""
  if (LPArraySupplies) {
    totalStakingValueFraction = allPairs.map((pair, index) => {
      const stakingInfo = stakingInfos.find((stakingInfo: any) => stakingInfo.stakedAmount.token.address === pair?.pairInfo?.liquidityToken.address)
      if (stakingInfo) {
        if (LPArraySupplies[index].result) {
          const rate = stakingInfo.stakedAmount.divide(new TokenAmount(pair?.pairInfo?.liquidityToken ,LPArraySupplies[index]?.result[0]) || JSBI.BigInt(1))
          return pair.base === 0 ? pair?.pairInfo?.token1Price?.quote(pair?.pairInfo.reserve1).add(pair?.pairInfo.reserve0).multiply(rate): 
          pair?.pairInfo?.token0Price?.quote(pair?.pairInfo.reserve0).add(pair?.pairInfo.reserve1).multiply(rate)
        }
      }
      return new Fraction(JSBI.BigInt(0), JSBI.BigInt(100000))
    })
    .reduce((pre, next) => {
      return pre.add(next)
    }, new Fraction(JSBI.BigInt(0), JSBI.BigInt(100000)))
  }
  useEffect(() => {
    if (totalStakingValueFraction) {
      setTotalStakingValue(totalStakingValueFraction?.toFixed(2))
    }
  }, [totalStakingValueFraction]);

  return (
    <STS>
      <Container>
        <WalletWraper>
          {/* <TotalAssets>
            Total Asset
            <TotalAssetsAmount>
              $300.33
            </TotalAssetsAmount>
          </TotalAssets> */}
          <ChainAssetsWraper>
            <Tabs defaultActiveKey="1" type="card" size={'large'}>
              <TabPane tab={TabContent(chainId === 1 ? "Ethereum" : "Matic")} key="1">
                <TotalAssetsWraper>
                  <TotalAssetsItem>
                    <TotalAssetsLeft>
                      <AssetsTitle>
                        {t("total_asset")}
                      </AssetsTitle>
                      <AssetsAmount>
                        $ {numeral(totalAssetsValue).format('0,0.00')}
                      </AssetsAmount>
                    </TotalAssetsLeft>
                  </TotalAssetsItem>
                  <TotalAssetsItem>
                    <TotalAssetsLeft>
                      <AssetsTitle>
                        {t('sts_assets')}
                      </AssetsTitle>
                      <AssetsAmount>
                        $ {numeral(TotalSTSAmount).format('0,0.00')}
                      </AssetsAmount>
                    </TotalAssetsLeft>
                  </TotalAssetsItem>
                  <TotalAssetsItem>
                    <TotalAssetsLeft>
                      <AssetsTitle>
                      {t("liquidity")}
                      </AssetsTitle>
                      <AssetsAmount>
                        $ {numeral(totalStakingValue).format('0,0.00')}
                      </AssetsAmount>
                    </TotalAssetsLeft>
                  </TotalAssetsItem>
                </TotalAssetsWraper>
              </TabPane>
              {/* <TabPane tab={TabContent("bnb")} disabled key="2">
                1
              </TabPane> */}
            </Tabs>
          </ChainAssetsWraper>
          <TokenListWraper>
            <TokenListTitle>
              {t('token_list')}
              <SwitchWraper>
                <Switch
                  defaultChecked
                  onChange={(checked) => {
                  console.log(checked)
                  setIsNoShowBalanceEqualZero(checked)
                }} />
                <SwitchTitle>Hide 0 balance</SwitchTitle>
              </SwitchWraper>
            </TokenListTitle>
            <Table
              className={"wallet-table"}
              // dataSource={WalletData.sort((pre: any, next: any) => {
              //   return p
              // })}
              dataSource={isNoShowBalanceEqualZero ?
                WalletData.filter((data: WalletInfo) => {
                  return Number(data.balance) !== 0
                }) : WalletData}
              columns={walletColumn}
              scroll={{ x: true }}
              pagination={false}
            />
          </TokenListWraper>
          {/* <LiquidityWraper>
            <TokenListTitle>
              {t("liquidity")}
            </TokenListTitle>
            <Table
              className={"liquidity-table"}
              dataSource={liquidityData}
              columns={liquidityColumn}
              scroll={{ x: true }}
            />
          </LiquidityWraper> */}
        </WalletWraper>
      </Container>
    </STS>
  )
}
const StsLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
`
const WalletWraper = styled.div`

`

const TotalAssets = styled.div`
  font-size: 16px;
  color: #84818A;
  display: flex;
  align-items: center;
`

const TotalAssetsAmount = styled.span`
  margin-left: 6px;
  font-size: 32px;
  color: #5542F6;
`

const ChainAssetsWraper = styled.div`
  margin-top: 40px;
  margin-bottom: 45px;
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab-btn {
    width: 100% !important;
  }
  .ant-tabs-tabpane {
    background: #FFFFFF !important;
    padding: 18px 22px 32px 22px;
    border: 1px solid #EBEAED;
    border-top: none;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      width: 304px;
      height: 54px;
      border: 1px solid #EBEAED !important;
      margin-left: 0!important;
      padding: 0!important;
    }
    .ant-tabs-tab-disabled {
      background: #EBEAED !important;
    }
    .ant-tabs-tab-active {

    }
  }
`

const TabItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 23px;
  color: #000000;
  font-size: 14px;
`

const TabItemLeft = styled.div`
  display: flex;
  align-items: center;
`

const TabItemRight = styled.div`

`

const ChainImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const ChainName = styled.span`

`

const PercentText = styled.span`
  color: #84818A;
`

const TotalAssetsWraper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TotalAssetsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 180px;
  margin-right: 20px;
`

const TotalAssetsLeft = styled.div`

`

const TotalAssetsRight = styled.div`
   img {
     width: 50px;
     height: 50px;
   }
`

const AssetsTitle = styled.div`
  margin-bottom: 23px;
`

const AssetsAmount = styled.div`
  color: #5542F6;
  font-size: 24px;
`

const TokenListWraper = styled.div`
  .ant-table-thead > tr > th {
    background: #FFFFFF!important;
  }
`

const TokenListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: bold;
`

const StyleTokenName = styled.span`
  display: flex;
  align-items: center;
  img, svg {
    margin-right: 8px;
  }
`

const TradeButton = styled.span`
  color: #0062DF;
  background: #EEF4FF;
  padding: 2px 10px;
  cursor: pointer;
  margin-left: 14px;
`

const LiquidityWraper = styled.span`

`

const PairLiquidity = styled.div`
  font-size: 12px;
`

const StyledTotalValue = styled.div`

`

const PairName = styled.div`
  display: flex;
`

const SwitchWraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SwitchTitle = styled.span`
  margin-left: 6px;
`