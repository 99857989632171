import React, { useState } from 'react'
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next'
import { TransactionResponse } from '@ethersproject/providers'
import { ChainId, JSBI, TokenAmount } from '@uniswap/sdk'
import { parseUnits } from "@ethersproject/units";
import { LoadingOutlined, CheckCircleFilled } from '@ant-design/icons';
import { SOFI_Promote_contract_address, TSOFI } from '../../constants'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useSofiPromoteContract } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { getEtherscanLink } from '../../utils'
import { retry, RetryableError } from '../../utils/retry'
import { ReactComponent as RightArrow } from '../../assets/svg/rightArrow.svg'

import './index.less';

export default function ({isOpen, handleCancel, portfolioAddress}: any){
    const { chainId, account, library } = useActiveWeb3React()
    const [loading, setLoding] = useState(false)
    const [hash, setHash] = useState('')
    const promoteContract = useSofiPromoteContract(SOFI_Promote_contract_address[chainId || ChainId.RINKEBY])
    const [approval, approveCallback] = useApproveCallback(new TokenAmount(TSOFI[chainId ?? 4], JSBI.BigInt(100000000000000000000)), SOFI_Promote_contract_address[chainId || ChainId.RINKEBY])
    const { t } = useTranslation()
    const [form] = Form.useForm();
    const addTransaction = useTransactionAdder()

    const onFinish = async (values: any) => {
        if(account && chainId && library && promoteContract){
            if (approval !== ApprovalState.APPROVED) {
                console.log("not approve");
                approveCallback()
            } else {
                setLoding(true)
                await promoteContract
                    .promote(portfolioAddress, parseUnits(values.amount, 18))
                    .then((res: TransactionResponse) => {
                        console.log("response", res)
                        addTransaction(res, {
                            summary: `Promote`
                        })
                        retry(() => {
                            return library
                            .getTransactionReceipt(res.hash)
                            .then(receipt => {
                                if (receipt === null) {
                                    console.debug('Retrying for hash', res.hash)
                                    throw new RetryableError()
                                }
                                if (receipt) {
                                    console.log("trade receipt", receipt)
                                    setHash(res.hash);
                                    setLoding(false);
                                }
                            })
                            }, {
                                n: Infinity,
                                minWait: 2500,
                                maxWait: 3500
                            })
                        })
                    .catch((error: any) => {
                        alert(error.message)
                        setLoding(false);
                        console.log(error)
                    })
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleAmountChange = (e: any) => {
        if(Number(e.target.value) > 0){
            form.setFieldsValue({hours: e.target.value / 100})
        }
    } 

    
    
    return (
        <Modal 
            className="promoteModal" 
            title={<div className="header">{t('promote_your_portfolio')}</div>} 
            width={500} 
            visible={isOpen} 
            footer={
                <div className={`step3 ${hash ? 'success' : ''}`}>{!hash && (loading ? <><LoadingOutlined />{t('wait_a_moment')}</> :`${t('step')} 3: ${t('start_your_promotion')}`)}
                    {hash && chainId && <Button type="link" icon={<CheckCircleFilled style={{color: '#52C41A'}}/>}><a href={getEtherscanLink(chainId, hash, 'transaction')}>&nbsp;{t('view_on_etherscan')}</a></Button>}
                </div>
            } 
            onCancel={handleCancel}
        >
           <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark   
                form={form}
                layout="vertical"
            >
                <div className='step1'>
                    <h2>{t('step')} 1: {t('wallet_approval')}</h2>
                    <Form.Item>
                        <Button type={approval !== ApprovalState.APPROVED ? 'default': 'primary'} block onClick={() => { return approval !== ApprovalState.APPROVED ? approveCallback() : ''}}>
                            {approval !== ApprovalState.APPROVED ? t('approve') : t('approved')}
                        </Button>
                    </Form.Item>
                </div>
                <div className={`step2 ${approval !== ApprovalState.APPROVED ? 'approve': 'approved'}`}>
                    <h2>{t('step')} 2: {t('confirm_your_budget')}</h2>
                    <p>{t('buy_sofi')}, <a target='_blank' href='https://info.quickswap.exchange/#/pair/0xbdf395f950c49ca25f2588192b939edb64375fc4'>{t('click_to_buy_sofi')}</a></p>
                    <Form.Item
                        name="amount"
                        rules={[
                            { required: true, message: 'Please input SOFI amount!' },
                            { pattern: /^\d+(\.\d{1,3})?$/, message: 'Please input SOFI amount'}
                        ]}
                    >
                        <Input addonAfter="SOFI" onChange={handleAmountChange} disabled={approval !== ApprovalState.APPROVED}/>
                    </Form.Item>
                    <RightArrow className="rightArrow"/>
                    <Form.Item
                        name="hours"
                        label="100 SOFI = 1 hours"
                    >
                        <Input addonAfter="Hours" disabled={true}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" htmlType="submit" block disabled={approval !== ApprovalState.APPROVED}>
                            {t('confirm')}
                        </Button>
                    </Form.Item>
                </div>
                
            </Form>
        </Modal>
    ) 
}