import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components'
import { Layout, Menu, Select, Drawer, Button, Radio, Tag, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Web3Status from '../../components/Web3Status'
import { useActiveWeb3React } from '../../hooks'
import { useWindowSize } from '../../hooks/useWindowSize'
import ReedemModal from './ReedemModal';

import './index.less';
import {ReactComponent as  MarketsIcon } from '../../assets/svg/sts/markets.svg'
import {ReactComponent as  IdoIcon } from '../../assets/svg/sts/ido.svg'
import {ReactComponent as  FarmIcon } from '../../assets/svg/sts/farm.svg'
import DexIcon from '../../assets/svg/sts/dex.svg'
import WalletIcon from '../../assets/svg/sts/wallet.svg'
import CrossIcon from '../../assets/svg/sts/cross.svg'
import SettingsIcon from '../../assets/svg/sts/settings.svg'
import ProposalsIcon from '../../assets/svg/proposals.svg'
import {ReactComponent as TwitterLogo} from '../../assets/svg/twitter.svg'
import {ReactComponent as MediumLogo} from '../../assets/svg/medium.svg'
import {ReactComponent as TelegramLogo} from '../../assets/svg/telegram.svg'
import {ReactComponent as WikiLogo } from '../../assets/svg/wikipedia-w.svg'
import {ReactComponent as SocialIcon} from 'assets/svg/social.svg'
import { useTranslation } from 'react-i18next';
import FaucetModal from './AccountFaucetModal'
import STSBuy from './STSBuy'
import i18n from 'i18next';
import RAILogo from "../../assets/images/logo-dark.png"
import { setupEthRinkebyTestNetwork } from '../../utils'
import { ChainId } from '@uniswap/sdk';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;


const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  line-height: 64px;
  height: 64px;
  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }
  .ant-btn{
    height: 40px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};

  #connect-wallet{
      border:1px solid #5542F6;
      height: 32px;
      margin-left: 10px;
      p{
        color: #000;
        height: 30px;
        line-height: 30px;
    }
  }
  #web3-status-connected{
      /* border-color: #5542F6; */
    &:focus{
        box-shadow: none;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0;
    `};
    p{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    span{
        color: #000;
        margin-left: 5px;
    }
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)}; */

  background: transparent;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const SideBarFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>div{
    margin: 10px 0;
    }

`
const StyledLink = styled.a`
  svg{
      margin: 0 10px;
  }
`

const STSMenu = styled.ul<{ isSTS: boolean }>`
    display: flex;
    list-style: none;
    height: 64px;
    margin-bottom: 0;
    visibility: ${ props => props.isSTS ? "visible" : "hidden" };
    li {
        margin: 0 15px;
        color: #5542F6;
        font-weight: bold;
        cursor: pointer;
        a{
            line-height: 1.2;
            display: inline-block;
        }
    }
    @media (max-width: 768px) {
        margin-top: 66px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        li{
            margin:0 15px 0 0;
        }
    }  
`
const Logo = styled.img`
    width: 100px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 80px;
    `};

`
const MarketsImg = styled.img`
    width: 18px;
`
const WikiLink = styled.a`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000000;
    svg {
        width: 18px;
        margin-right: 8px;
    }
`

const HeaderNotification = styled.div`
    width: 100%;
    padding: 10px 0;
    background-color: #5542F6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    line-height: 16px;
    a{
        color: #fff;
        text-decoration: underline;
        &:hover{
            color: #fff;
            text-decoration: underline;
        }
    }
`
const HeaderContent = styled.div`
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0 20px;
    `};
    
`
const SwitchNetwork = styled.div`
    display: flex;
    align-items: center;
    .ant-radio-group {
        display: flex;
    }
    .ant-radio-button-wrapper{
        ${({ theme }) => theme.mediaWidth.upToSmall`
            padding: 0 5px;
        `}
    }
`

const SwitchItem = styled.div`
    padding: 0 10px;
`
const STS: React.FC = ({ children }) =>  {
    const { t } = useTranslation();
    const { account, chainId } = useActiveWeb3React()
    const [networkValue, setNetworkValue] = useState(chainId)
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [isShowFaucetModal, setIsShowFaucetModal] = useState(false)
    const [isShowReedemModal, setIsShowReedemModal] = useState(false)
    const {pathname} = useLocation();
    const { width } = useWindowSize()
    let selectedKey: string = pathname;
    if(pathname === '/' || pathname.includes('sts')){
        selectedKey = '/sts'
    }
    if(pathname.includes('ido')){
        selectedKey = '/ido'
    }

    const onCloseCollapse = () => {
        setCollapsed(false);
    };

    const toggle = () => {
        setCollapsed(!collapsed)
    };

    const handleChange = () => {

    }
    const changeLocale = (selectedLanguage: string) => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('rai_lang', selectedLanguage)
    }
    const isSTS = pathname === '/'
    || pathname.includes("/sts")
    useEffect(() => {
        const ethereum = window.ethereum
        if (ethereum && ethereum.on) {
            ethereum.on('chainChanged', (_chainId: any) => window.location.reload());
            ethereum.on('disconnect', (_err: any) => window.location.reload());
            ethereum.on('accountsChanged', (_err: any) => window.location.reload());
        }
    }, [])

    useEffect(() => {
        const chainIdArray = [1, 137, 4]
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
                if (chainId && !(chainIdArray.indexOf(chainId) > -1)) {
                    setupEthRinkebyTestNetwork(137)
                }
                if(pathname.includes("/proposals") && chainId !== ChainId.MAINNET){
                    setupEthRinkebyTestNetwork(ChainId.MAINNET)
                }
            }
        });
        if (document.visibilityState === 'visible') {
            if (chainId && !(chainIdArray.indexOf(chainId) > -1)) {
                setupEthRinkebyTestNetwork(137)
            }
            if(pathname.includes("/proposals") && chainId !== ChainId.MAINNET){
                setupEthRinkebyTestNetwork(ChainId.MAINNET)
            }
        }
    }, [pathname])

    const SideMenu = () => (
        <Menu
            mode="inline"
            defaultSelectedKeys={[selectedKey]}
            defaultOpenKeys={['sub1', 'sub2', 'sub3']}
            style={{ height: '100%', borderRight: 0 }}
            expandIcon={<></>}
            className="menu"
        >
            <SubMenu key="sub1" title="Social Trading">
                <Menu.Item key="/sts" icon={
                    <MarketsIcon fill={`#5542F6`} />}><Link to="/">STS</Link>
                </Menu.Item>
                <Menu.Item key="/social" icon={
                    <SocialIcon fill={`#5542F6`} />}><Link to="/social">Social Hub</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" title="Beta 3.0 Feature">
                {/* <Menu.Item key="/ido" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={<IdoIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`}/>}><Link to="/ido">IDO</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item> */}
                {/* <Menu.Item key="/farm" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={<FarmIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`}/>}><Link to="/farm">Farm</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item> */}
                <Menu.Item key="/aggregateSwap" icon={<img src={DexIcon} />}><Link to="/aggregateSwap">Aggregator</Link></Menu.Item>
                <Menu.Item key="/wallet" icon={<img src={WalletIcon} />}><Link to="/wallet">My Wallet</Link></Menu.Item>
                <Menu.Item key="/proposals" icon={<img src={ProposalsIcon} />}><Link to="/proposals">Governance</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" title="Coming Soon">
                <Menu.Item key="7" icon={<img src={CrossIcon} />}>Cross-chain Swap</Menu.Item>
            </SubMenu>
        </Menu>
    )
    return (
        <Layout style={{width: '100%', minHeight: '750px'}}>
            <Header className="header" style={{background: '#fff', position: 'fixed', top: 0, width: '100%', zIndex: 1000}}>
                {/* <HeaderNotification>
                    <a href="https://medium.com/rai-finance/rai-finance-launches-global-mainnet-event-ac789ea8756d" target="_blank">Try RAI Finance main net product and win $50000 USD reward!</a>
                </HeaderNotification> */}
                <HeaderContent>
                    <div className="triger">{collapsed ? <MenuUnfoldOutlined style={{ fontSize: '20px'}} onClick={toggle}/> : <MenuFoldOutlined style={{ fontSize: '20px'}} onClick={toggle}/>}</div>
                    <Logo src={RAILogo} />
                    {width && width >= 768 &&
                        <STSMenu isSTS={isSTS}>
                            <li>
                                <Link to="/">
                                    {t("markets")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/myportfolio">
                                    {t("account")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/create">
                                    {t("create_portfolio")}
                                </Link>
                            </li>
                            {account && chainId === 4 ? (
                                <li onClick={() => setIsShowFaucetModal(true)} >
                                    <Button type="primary"  >{t("get_test_token")}</Button>
                                </li>
                            ) : ""}
                        </STSMenu>
                    }
                    <HeaderElement>
                        <SwitchNetwork>
                            <Radio.Group buttonStyle="solid"
                              onChange={(e) => {
                                setNetworkValue(e.target.value)
                                console.log("network value", e.target.value)
                                setupEthRinkebyTestNetwork(e.target.value)
                              }}
                              value={networkValue}
                            >
                                <Radio.Button value={1}>Ethereum</Radio.Button>
                                {/* <Radio.Button value={80001}>MUMBAI</Radio.Button> */}
                                {/* <Radio.Button value={4}>RINKEBY</Radio.Button> */}
                                {!pathname.includes("/proposals") && <Radio.Button value={137} >Polygon</Radio.Button>}
                            </Radio.Group>
                        </SwitchNetwork>
                        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                            <Web3Status />
                        </AccountElement>
                    </HeaderElement>
                </HeaderContent>
            </Header>
            {width && width >= 768 && 
                <Sider 
                    breakpoint="md"
                    width={250}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        paddingTop: '100px',
                        minHeight: '700px'
                    }}
                    collapsedWidth="0"
                    className="sidebar"
                >
                    <SideMenu/>
                    <SideBarFooter>
                        {/* <div><img src={SettingsIcon}/>&nbsp;&nbsp;Settings</div> */}
                        <div>
                            <Select defaultValue={i18n.language.indexOf("en") > -1 ? "en" : "ko"} style={{ width: 120 }} onChange={changeLocale}>
                                <Option value="en">English</Option>
                                <Option value="ko">한국어</Option>
                            </Select>
                        </div>
                        <div>
                            <StyledLink 
                                target='_blank'
                                href="https://twitter.com/RaiFinance">
                                    <TwitterLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://medium.com/rai-finance">
                                    <MediumLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://t.me/RaiFinance">
                                <TelegramLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink
                                target='_blank'
                                href="https://rai-finance.gitbook.io/rai-finance/" >

                            </StyledLink>
                        </div>
                        <WikiLink target="_blank" href="https://rai-finance.gitbook.io/rai-finance/" >
                          <WikiLogo width="20" height="20" fill="#5542F6"/> RAI Finance Wiki
                        </WikiLink>
                    </SideBarFooter>
                </Sider>
            }
            <Layout>
                <Content>
                    {width && width < 768 && isSTS && 
                        <STSMenu isSTS={isSTS}>
                            <li>
                                <Link to="/">
                                    {t("markets")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/myportfolio">
                                    {t("account")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/create">
                                    {t("create")}
                                </Link>
                            </li>
                            {account ? (
                                <li onClick={() => setIsShowFaucetModal(true)} >
                                    <Button type="primary" disabled>{t("get_test_token")}</Button>
                                </li>
                            ) : ""}
                            <li onClick={() => setIsShowReedemModal(true)}>
                                <Button type="primary">Convert to SOFI</Button>
                            </li>
                        </STSMenu>
                    }
                    {children}
                </Content>
            </Layout>
            <Drawer
                title=""
                placement="left"
                width={250}
                closable={false}
                onClose={onCloseCollapse}
                visible={collapsed}
                bodyStyle={{padding: 0}}
            >
                <Sider 
                    width={250}  
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        minHeight: '700px'
                    }}
                    collapsedWidth="0"
                >
                    <SideMenu/>
                    <SideBarFooter>
                        <div><img src={SettingsIcon}/>&nbsp;&nbsp;Settings</div>
                        <div>
                        <Select defaultValue={localStorage.getItem("cobak_lang") || "en"} style={{ width: 120 }} onChange={changeLocale}>
                            <Option value="en">English</Option>
                            <Option value="ko">한국어</Option>
                        </Select>
                        </div>
                        <div>
                            <StyledLink 
                                target='_blank'
                                href="https://twitter.com/RaiFinance">
                                    <TwitterLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://medium.com/rai-finance">
                                    <MediumLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://t.me/RaiFinance">
                                <TelegramLogo fill="#5542F6"/>
                            </StyledLink>
                        </div>
                    </SideBarFooter>
                </Sider>
            </Drawer>
            <FaucetModal
              isOpen={isShowFaucetModal}
              handleCancel={() => {setIsShowFaucetModal(false)}}
            />
            <ReedemModal 
                isOpen={isShowReedemModal}
                handleCancel={() => {setIsShowReedemModal(false)}}
            />
        </Layout> 
    )
}

export default STS;
