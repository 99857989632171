
import { useState, useEffect, useCallback } from "react"
// import { BigNumber } from 'ethers';
// import { useFixedSwapContract } from './useContract'
// import { useSingleCallResult } from '../state/multicall/hooks'
import { useActiveWeb3React } from './index'
import { configIdoList } from '../config'
import { getContract } from '../utils'
import { FIXEDSWAP_ABI } from '../constants/abis/fixedSwap'

const useIdoList = () => {
  // const fixedSwapContract = useFixedSwapContract(contractAddress)
  const { library, chainId, account } = useActiveWeb3React()
  const [idoList, setIdoList] = useState<any[]>([])
  // const [idoInfo, setIdoInfo] = useState({})
  const fetchIdoList = useCallback(async () => {
    const idoList: any[] =[]
    
    for (const idoInfo of Object.values(configIdoList)) {
      if (!library || !account || idoInfo.chainId !== chainId) {
        continue
      }
      const contract = getContract(idoInfo.contractAddress, FIXEDSWAP_ABI, library, account)
      const contractMethodArray = [
        "isSaleFunded",
        "erc20",
        "isTokenTrade",
        "tradeValue",
        "startDate",
        "endDate",
        "individualMinimumAmount",
        "individualMaximumAmount",
        "minimumRaise",
        "tokensAllocated",
        "tokensForSale",
        "isTokenSwapAtomic",
        "totalRaiseCost",
        "tokensLeft",
        "isOpen",
        "isPreStart"
      ]
      const promises = contractMethodArray.map((methodString) => Promise.resolve(contract?.[methodString]()))
      let result = await Promise.all(promises)
      let formatObj: any = {}
      result.forEach((item, index) => {
        formatObj[contractMethodArray[index]] = item
      })
      idoList.push({
        ...idoInfo,
        ...formatObj
      })
    }
    setIdoList(idoList)
  }, [setIdoList, library, account])
  useEffect(() => {
    fetchIdoList()
  }, [fetchIdoList])
  return idoList
}

export default useIdoList
