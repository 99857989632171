import React from 'react'
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next'
import {  TwitterOutlined, GithubOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../hooks'
import { postManagerProfile } from './hooks'
import {ReactComponent as Email} from 'assets/svg/email.svg'

import './index.less';

export default function ({isOpen, handleCancel, type, managerInfo, refreshManagerInfo}: any){
    const { chainId, account, library } = useActiveWeb3React()
    const { t } = useTranslation()
    const [form] = Form.useForm();
    const profileRes = type === 'twitter' ? 'twitter_url':type
    form.setFieldsValue({url: managerInfo[profileRes]})
    const onFinish = async (values: any) => {
        if(account && chainId && library){
            const profileName = type === 'twitter' ? 'twitter_text':type
            const res = await postManagerProfile({
                [profileName]: values.url,
            }, account, chainId, library);
            if(res){
                message.success(t('setting_success'))
                refreshManagerInfo();
                form.resetFields()
                handleCancel()
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const MediaIcon = type === 'twitter' ? <TwitterOutlined style={{color: '#00ACED'}}/> : type === 'github' ? <GithubOutlined style={{color: '#000'}}/> : <Email className="anticon" style={{fill: '#000'}}/>
    
    return (
        <Modal className="mediaModal" title={<div className="header">{MediaIcon} {`${t('connect')} ${type} ${t('account')}`}</div>} width={350} visible={isOpen} footer={null} onCancel={handleCancel}>
           <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark   
                form={form}
            >
                {type === 'twitter' && <>
                    <h2>{t('step')} 1</h2>
                    <p>{t('tweet_your_address')}</p>
                    <Form.Item>
                        <a className="tweet" target="_blank" href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=twitterapi%2Ctwitter&text=${t('welcome_to_sofi', {address: account})}`}>
                            {t("tweet_now")}
                        </a>
                    </Form.Item>
                    <h2>{t('step')} 2</h2>
                    <p>{t('copy_paste_your_tweet_link')}</p>
                </>}
                <Form.Item
                    name="url"
                    initialValue={managerInfo[profileRes]}
                    rules={[ type === 'email' ? { type: 'email', message: t('please_input_right_email')  } : {}]}
                >
                    <Input placeholder={`${type === 'email' ? 'your_email@example.com':'https://'}`}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        {t('connect')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    ) 
}