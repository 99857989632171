// import { ChainId } from '@uniswap/sdk'
import React, { useState, useEffect } from 'react'
// import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

import styled from 'styled-components'

import Logo from '../../assets/images/logo.png'
import { useActiveWeb3React } from '../../hooks'

import DropMenu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { useHistory, useLocation } from "react-router-dom";

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 2;
  position: fixed;
  background-color: ${({ theme }) => theme.bg6};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
    display: flex;
    justify-content: space-between;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  margin-right: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  @media(max-width: 1140px) {
    margin-right: 30px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

// const HeaderElementWrap = styled.div`
//   display: flex;
//   align-items: center;
// `

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: auto;
  `};
  width: 80%;
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    padding-left : 21px;
    justify-content: flex-start;
    width: auto;
`};
 @media(max-width: 767px) {
   display: none;
 }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)}; */

  background: transparent;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

// const HideSmall = styled.span`
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     display: none;
//   `};
// `

// const NetworkCard = styled(YellowCard)`
//   border-radius: 12px;
//   padding: 8px 12px;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     margin: 0;
//     margin-right: 0.5rem;
//     width: initial;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     flex-shrink: 1;
//   `};
// `

// const BalanceText = styled(Text)`
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     display: none;
//   `};
// `

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  padding-left: 182px;
  transition: transform 0.3s ease;
  img {
    width: 150px;
  }
  :hover {
    transform: rotate(-5deg);
  }
  @media(max-width: 1140px) {
    padding-left: 30px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    img {
      width: 100px;
    }
  `};
  @media(max-width: 414px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #C4C4C4;
  font-size: 1rem;
  width: fit-content;
  margin: 0 35px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: #FFFFFF;
  }

  :hover,
  :focus {
    color: #fff;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0 12px;
    
  `};
`
const MobileHeaderLinks = styled.div`
  @media(min-width: 768px) {
    display: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: #FFFFFF;
  }
  .ant-select-arrow {
    color: #FFFFFF;
  }
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

// const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
//   [ChainId.RINKEBY]: 'Rinkeby',
//   [ChainId.ROPSTEN]: 'Ropsten',
//   [ChainId.GÖRLI]: 'Görli',
//   [ChainId.KOVAN]: 'Kovan'
// }

export default function Header() {
  const { account } = useActiveWeb3React()
  const [defaultSelect, setDefaultSelect] = useState("");
  let history = useHistory();
  let { pathname } = useLocation();
  const { t } = useTranslation()
  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  // const [ darkMode ] = useDarkModeManager()
  const { Option } = Select;
  useEffect(() => {
    console.log("pathname", pathname)
    if (pathname.indexOf("/wallet") > -1) {
      setDefaultSelect("MYWALLET")
      return
    }
    if (pathname.indexOf("/ido") > -1) {
      setDefaultSelect("IDO")
      return
    }
    if (pathname.indexOf("/farm") > -1) {
      setDefaultSelect("FARM")
      return
    }
    setDefaultSelect("SWAP")
  }, [pathname, defaultSelect]);
  const getDefaultValue = () => {
    if (pathname.indexOf("/wallet") > -1) {
      return "MYWALLET"
    }
    if (pathname.indexOf("/ido") > -1) {
      return "IDO"
    }
    if (pathname.indexOf("/farm") > -1) {
      return "FARM"
    }
    return "SWAP"
  }
  return (
    <HeaderFrame>
      <HeaderRow>
        <Title href=".">
          <UniIcon>
            <img  src={Logo} alt="logo" />
          </UniIcon>
        </Title>
        <HeaderLinks>
          <StyledNavLink
            id={`swap-nav-link`}
            to={'/'}
          >
            {t("header_swap")}
          </StyledNavLink>
          <StyledNavLink
            id={`ido-nav-link`}
            to={'/ido'}
          >
            {t("header_ido")}
          </StyledNavLink>
          <StyledNavLink
            id={`farm-nav-link`}
            to={'/farm'}
          >
            {t("header_farm")}
          </StyledNavLink>
          {account && 
            <StyledNavLink
              id={`wallet-nav-link`}
              to={'/wallet'}
            >
              My Wallet
            </StyledNavLink>
          }
        </HeaderLinks>
        <MobileHeaderLinks>
          <Select
            defaultValue={getDefaultValue()}
            style={{ width: 120 }}
            onChange={(value: string) => {
              switch (value) {
                case "SWAP":
                  history.push("/")
                  break;
                case "FARM":
                  history.push("/farm")
                  break;
                case "IDO":
                  history.push("/ido")
                  break;
                case "MYWALLET":
                  history.push("/wallet")
                  break; 
                default:
                  break;
              }
            }}
          >
            <Option value="SWAP">SWAP</Option>
            <Option value="IDO">IDO</Option>
            <Option value="FARM">Farm</Option>
            <Option value="MYWALLET">My Wallet</Option>
          </Select>
        </MobileHeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          {/* <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall> */}
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {/* {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} ETH
              </BalanceText>
            ) : null} */}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <DropMenu />
        {/* <HeaderElementWrap>
          <StyledMenuButton onClick={() => toggleDarkMode()}>
            {darkMode ? <Moon size={20} /> : <Sun size={20} />}
          </StyledMenuButton>
          <Menu />
        </HeaderElementWrap> */}
      </HeaderControls>
    </HeaderFrame>
  )
}
