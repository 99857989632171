import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import ethers from 'ethers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import STS from '../STS'
import { Container } from '../STS/Markets'
import { configIdoList } from '../../config'
import { useActiveWeb3React } from '../../hooks/'
import { FIXEDSWAP_ABI } from '../../constants/abis/fixedSwap'
import { getContract } from '../../utils'
import { Table } from 'antd'
import "antd/lib/table/style/css"
export default function IdoHistory(props: RouteComponentProps<{ userAddress: string }>) {
  const {
    match: {
      params: { userAddress }
    }
  } = props
  const { library, account, chainId } = useActiveWeb3React()
  const [result, setResult] = useState([]);
  const { t } = useTranslation()
  useEffect(() => {
    const contractAddresses = Object.keys(configIdoList).map((contractAddress => {
      if (!library || !account) return null
      const contract = getContract(contractAddress, FIXEDSWAP_ABI, library, account)
      return contract
      .getMyPurchases(userAddress)
      .then((res: any) => {
        console.log("res", res)
        return Promise.all(res.map((transactionId: any) => {
          return contract
            .getPurchase(transactionId)
            .then((purchase: any) => {
              console.log("purchase", purchase)
              return purchase.concat([contract.address])
            })
        }))
      })
    }))
    Promise.all(contractAddresses).then((res) => {
      setResult(res.reduce((prev, curr) => prev.concat(curr), []))
    })
  }, []);
  console.log("result", result)
  const tableData = result.map((item: any) => {
    const contractInfo = configIdoList[item[6]]
    return {
      date: item[3].toNumber(),
      swapAmount: item[0],
      paymentToken: contractInfo.useToken[chainId || 4].symbol,
      paymentAmount: item[2],
      ratio: 1,
      contractInfo
    }
  })
  const columns = [
    {
      title: t("date"),
      dataIndex: "date",
      align: "center",
      render: (text: any) => {
        return (<span>{dayjs(text * 1000).format("YYYY-MM-DD hh:mm:ss")}</span>)
      },
      fixed: "left"
    },
    {
      title: t("swap_amount"),
      dataIndex: "swapAmount",
      align: "center",
      render: (text: any, record: any) => {
        console.log("record", record)
        return (
          <SwapAmountWraper>
            <img src={record.contractInfo.tokenLogo} alt="" />
            <span>{ethers.utils.formatUnits(text ,record.contractInfo.decimals)}</span>
            <span> {record.contractInfo.tokenName}</span>
          </SwapAmountWraper>
        )
      }
    },
    {
      title: t("payment_token"),
      dataIndex: "paymentToken",
      align: "center"
    },
    {
      title: t("payment_amount"),
      dataIndex: "paymentAmount",
      align: "center",
      render: (text: any, record: any) => {
        return (
         <span>
           {ethers.utils.formatUnits(text ,record.contractInfo.useToken.decimals)}
            {record.contractInfo.useToken.symbol}
         </span> 
        )
      }
    },
    {
      title: t("ratio"),
      dataIndex: 'ratio',
      align: "center",
      render: (text: any, record: any) => {
        if (!text || !record) return
        return (
          <span>1 {record?.contractInfo.useToken?.symbol} = {record.contractInfo.rate} {record.contractInfo.tokenCode}</span>
        )
      }
    }
  ]
  return (
    <STS>
      <Container>
        <IdoHistoryWraper>
          <IdoHistoryContent>
            <Title>{t("my_ido_history")}</Title>
            <TableWraper>
              <Table
                columns={columns as any}
                dataSource={tableData}
                scroll={{ x: true }}
              />
            </TableWraper>
          </IdoHistoryContent>
        </IdoHistoryWraper>
      </Container>
    </STS>
  )
}

const IdoHistoryWraper = styled.div`
  width: 100%;
  @media(max-width: 767px) {
    padding-top: 0;
  }
`

const IdoHistoryContent = styled.div`
  width: 100%;
  margin: 0 auto;
  @media(max-width: 1078px) {
    width: 100%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.bg6};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: left;
`

const TableWraper = styled.div`
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    border-bottom: 3px solid #E5E5E5;
  }
  .ant-table-thead > tr > th {
    background: #F9FAFC;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`

const SwapAmountWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
    margin-right: 12px; 
  }
`