import React, { useState } from 'react'
import styled from 'styled-components'
import { SwapOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import numeral from 'numeral';
import DoubleCurrencyLogo from '../../../components/DoubleLogo'

const PairInfoContent = styled.div`
    height: 120px;
`
const Title = styled.div`
    height: 50px;
    font-weight: 600;
    font-size: 24px;
    line-height: 50px;
    padding: 0 25px;
    border-bottom: 2px solid #F4F5F8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0 8px;
    `}
    &>div>div{
        display: inline-block;
    }
`
const Content = styled.div`
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0 8px;
    `}
    border-bottom: 2px solid #F4F5F8;
`
const PriceContent = styled.div`
    font-weight: 600;
    font-size: 36px;
    line-height: 70px;
    color: #03CE3C;
    span{
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 18px;
        line-height: 36px;
        span{
            font-size: 14px;
            display: block;
        }
    `}
`
const VolumeContent = styled.div`
    table tr td{
        color: #666;
        padding: 0 6px;
        line-height: 32px;
        &:nth-of-type(2){
            color: #000;
        }
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
        table tr td{
            padding: 0 2px;
            font-size: 12px;
        }
    `}
`

export default function PairInfo({ currentPair, showDrawer, pairBase, ticker }: any) {
    const { t } = useTranslation()
    const price = (currentPair.symbol === "MATIC-WMATIC" || currentPair.symbol === "ETH-WETH") ? 1 : currentPair.price;
    return (
        <PairInfoContent>
            <Title onClick={showDrawer}>
                <div>
                    {currentPair?.token0 && currentPair?.token1 &&
                        <DoubleCurrencyLogo
                          currency0={currentPair?.base === 0 ? currentPair?.token0 : currentPair?.token1}
                          currency1={currentPair?.base === 0  ? currentPair?.token1 : currentPair?.token0}
                          size={24}
                          margin={true}
                        />
                    }
                    {`${currentPair?.symbol}`}
                </div>
                <div>
                    {document.body.clientWidth < 720 && <SwapOutlined onClick={showDrawer} />}
                </div>    
            </Title>
            <Content>
                <PriceContent>
                    {`${price >= 1 ? numeral(price).format('0,0.00') : numeral(price).format('0,0.00000000')}`}
                    &nbsp;&nbsp;
                    <span className={currentPair?.priceChangePercent > 0 ? 'up': 'down'}>{`${currentPair?.priceChangePercent > 0 ? `+${currentPair?.priceChangePercent}%`: `${currentPair?.priceChangePercent}%`}`}</span>
                </PriceContent>
                <VolumeContent>
                    <table>
                        <tbody>
                            <tr>
                                <td>{t("volume")} (24h)</td>
                                <td>{numeral(ticker?.volumea).format('0,0.0000')}</td>
                                <td>{currentPair?.token0?.symbol}</td>
                            </tr>
                            <tr>
                                <td>{t("volume")} (24h)</td>
                                <td>{numeral(ticker?.volumeb).format('0,0.0000')}</td>
                                <td>{currentPair?.token1?.symbol}</td>
                            </tr>
                        </tbody>
                    </table>
                </VolumeContent>
            </Content>
        </PairInfoContent>
    )
}
