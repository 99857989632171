import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Token } from '@uniswap/sdk'
import { Drawer } from 'antd';
import Pairs from './components/AggregatePairs'
import Transaction from './components/TransactionList'
import TradeWraper from './components/TradeWraper'
import Chart from './components/Chart';
import PairInfoContent from './components/AggregatePairInfo';
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useActiveWeb3React } from '../../hooks'
import { useAggregateTicker24hr } from '../../state/swap/hooks'
import STS from '../STS'
import { Container } from '../STS/Markets'
import { useTokens } from '../STS/hooks';
import pair_json from '../../aggregate_pair.json'
import matic_aggregate_pair from '../../matic_aggregate_pair.json'
import eth_aggregate_pair from '../../eth_aggregate_pair.json'

const SwapContent = styled.div`
    display: grid;
    grid-template-columns: minmax(300px, 1.4fr) 1fr 1fr;
    grid-template-rows: minmax(300px, calc(100vh - 300px)) 350px;
    grid-gap: 15px;
    width: 100%;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns:repeat(3, 100%);
        grid-template-rows: repeat(3, 1fr);
        padding:0;
    `}

`

const ChartContent = styled.div`
    background: #fff;
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 1;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-column-start: 1;
        grid-column-end: span 1;
        grid-row-start: 1;
        grid-row-end: span 1;
    `}
`
const PairContent = styled.div`
    background: #fff;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: span 2;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;
    `}
`
const TransactionContent = styled.div`
    background: #fff;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: span 1;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-column-start: 1;
        grid-column-end: span 1;
        grid-row-start: 3;
        grid-row-end: span 1;
    `}
`
const TradeContent = styled.div`
    background: #fff;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: span 1;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-column-start: 1;
        grid-column-end: span 1;
        grid-row-start: 2;
        grid-row-end: span 1;
    `}
`

export default function Swap({ history }: RouteComponentProps) {
    let pair_info: any = pair_json;
    const { chainId } = useActiveWeb3React()
    if (chainId === 137) {
        pair_info = matic_aggregate_pair
    }
    if (chainId === 1)  {
        pair_info = eth_aggregate_pair
    }
    const [currentSymbol, setCurrentSymbol] = useState<string>(pair_info[0]?.symbol);
    const [pairBase, setPairBase] = useState<any>("USDT")
    const [visible, setVisible] = useState<boolean>(false);
    const getPair = (symbol: string) => {
        return symbol.split("-").map((tokenName: string) => {
            if (tokenName === "ETH") return "WETH"
            if (tokenName === "MATIC" && chainId === 137) return "WMATIC"
            return tokenName
        }).join("-")
    }
    const { tickers } = useAggregateTicker24hr(pair_info.map((pair: any) => getPair(pair.symbol)))
    const allPairs = pair_info.map((pair: any) => {
        return {
            ...tickers?.[getPair(pair.symbol)],
            ...pair,
        }
    })
    const showDrawer = () => {
        if (document.body.clientWidth < 720) {
            setVisible(true);  
        }
    };

    // useEffect(() => {
    //     if(tokens.length > 0){
    //         setCurrentSymbol(tokens[0].symbol)
    //     }
    // }, [tokens.length])
  
    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <STS>
            <Container>
                <SwapContent>
                    <ChartContent>
                        <PairInfoContent
                            currentPair={allPairs.filter((pair: any) => pair.symbol === currentSymbol)[0]}
                            showDrawer={showDrawer}
                            pairBase={pairBase}
                            ticker={tickers?.[currentSymbol]}
                        />
                        <Chart
                            symbol={getPair(currentSymbol)}
                            isAggregate={true}
                        />
                    </ChartContent>
                    <PairContent>
                        <Pairs
                          allPairs={allPairs}
                          currentPair={allPairs.filter((pair: any) => pair.symbol === currentSymbol)[0]}
                          setCurrentSymbol={setCurrentSymbol}
                          pairBase={pairBase}
                          setPairBase={setPairBase}
                        />
                    </PairContent>
                    <TransactionContent>
                        <Transaction />
                    </TransactionContent>
                    <TradeContent>
                        <TradeWraper
                          currentPair={allPairs.filter((pair: any) => pair.symbol === currentSymbol)[0]}
                        />
                    </TradeContent>
                </SwapContent>
                <Drawer
                    title="Pairs"
                    placement="left"
                    width={'80%'}
                    closable={true}
                    onClose={closeDrawer}
                    visible={visible}
                    bodyStyle={{padding: '10px', background: '#F4F5F8'}}
                >
                    <Pairs
                        allPairs={allPairs}
                        currentPair={allPairs.filter((pair: any) => pair.symbol === currentSymbol)[0]}
                        setCurrentSymbol={setCurrentSymbol}
                        pairBase={pairBase}
                        setPairBase={setPairBase}
                    />
                </Drawer>
            </Container>
        </STS>    
    )
}
