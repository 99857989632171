import React, { useEffect, useState } from 'react'
import { List, Card, Tooltip, Tabs, Input, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table';
import numeral from 'numeral';
import styled from 'styled-components'
import { ExclamationCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { useTokens, useHotPortfolios, useAllPortfolios, useManagersList } from './hooks';
import TokenChart from './TokenChart';
import { useTranslation } from 'react-i18next';
import { shortenAddress } from '../../utils'
import './index.less';
import STS from './index'

const { Meta } = Card;
const { TabPane } = Tabs;
const { Search } = Input;


export const Container = styled.div`
    padding: 50px;
    padding-top: 56px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
    overflow: auto;
`
const Title = styled.h1`
    font-size:32px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-bottom: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 20px;
    `};
`

const SubTitle = styled.h2`
    font-size:14px;
    margin-bottom: 10px;
    margin-top: 28px;
`

const PortfolioAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

export default function Markets() {
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const history = useHistory();
  const { portfolios } = useHotPortfolios();
  const { allPortfolios } = useAllPortfolios();
  const { list: managerList } = useManagersList();
  const [searchPortfolios, setSearchPortfolios] = useState([]);
  const [searchManagerList, setSearchManagerList] = useState([]);
  const [searchText, setSearchText] = useState('');  
  const [activeKey, setActiveKey] = useState('portfolio');  

  const onSearch = (value: any) => {
    if(value){
      setSearchText(value);
      if(activeKey === 'portfolio'){
        const list = allPortfolios.filter((v: any) => JSON.stringify(v).toLowerCase().includes(value.toLowerCase()))
        setSearchPortfolios(list);
      }
      if(activeKey === 'manager'){
        const list = managerList.filter((v: any) => JSON.stringify(v).toLowerCase().includes(value.toLowerCase()))
        setSearchManagerList(list)
      }
    }else {
      setSearchText('')
      setSearchPortfolios([]);
      setSearchManagerList([]);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t("portfolios"),
      dataIndex: 'symbol',
      fixed: 'left',
      width: '100px',
      render: (v: string, row: any) => <div>{v}{!!row?.promote_end_at && <ClockCircleOutlined className='promote_tag' style={{fontSize: '14px', color: '#52C41A'}}/>}</div>
    },
    {
      title: t("price"),
      dataIndex: 'value_per_set',
      width: '100px',
      render: (value_per_set: number) => `$${numeral(value_per_set.toFixed(2)).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.value_per_set - b.value_per_set,
      },
    },
    {
      title: <>24h%&nbsp;<Tooltip title={t('24h_tooltip')}><ExclamationCircleOutlined /></Tooltip></>,
      dataIndex: 'price_changed_24hr',
      width: '100px',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.price_changed_24hr - b.price_changed_24hr,
      },
    },
    {
      title: <>7d%&nbsp;<Tooltip title={t('7d_tooltip')}><ExclamationCircleOutlined /></Tooltip></>,
      dataIndex: 'price_changed_7day',
      width: '100px',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.price_changed_7day - b.price_changed_7day,
      },
    },
    {
      title: <>1m%&nbsp;<Tooltip title={t('1m_tooltip')}><ExclamationCircleOutlined /></Tooltip></>,
      dataIndex: 'price_changed_1mon',
      width: '100px',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.price_changed_1mon - b.price_changed_1mon,
      },
    },
    {
      title: t("holder"),
      dataIndex: 'holders_count',
      width: '80px',
      render: (v: any) => v ? numeral(v).format('0,0') : '-',
      sorter: {
        compare: (a: any, b: any) => a.holders_count - b.holders_count,
      },
    },
    {
      title: t('market_cap'),
      dataIndex: 'total_value',
      width: '120px',
      sortOrder: 'descend',
      render: (toatl_value: number) => `$${numeral(toatl_value).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.total_value - b.total_value,
      },
    },
    {
      title: '',
      dataIndex: 'components',
      width: '200px',
      render: (components: any) => {
        return components.map(({symbol} : any) => <img className="tokenAvator" src={getTokenLogoURL(symbol)}/>)
      }
    }
  ];

  const managerColumns: ColumnsType<any> = [
    {
      title: t("name"),
      dataIndex: 'name',
      fixed: 'left',
      width: 150,
      render: (v:any, row: any) => v ? v : row.address && shortenAddress(row.address)
    },
    {
      title: <>1m%</>,
      dataIndex: 'month_apy',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.month_apy - b.month_apy,
      },
    },
    {
      title: <>1Y%</>,
      dataIndex: 'year_apy',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.year_apy - b.year_apy,
      },
    },
    {
      title: <>{t('all_time')}%</>,
      dataIndex: 'all_apy',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.all_apy - b.all_apy,
      },
    },
    {
      title: t('total_market_cap'),
      dataIndex: 'total_market_cap',
      width: 150,
    },
    {
      title: t('total_holders'),
      dataIndex: 'total_holders',
      width: 100,
    },
    {
      title: t('experience'),
      dataIndex: "created_at",
      width: 120,
      render: (v: any) => {
        const begintime = v*1000;  
        const endtime = new Date().getTime();     
        const nTime = endtime - begintime;  
        const day = Math.floor(nTime/86400000);
        return `${day} d`
      }
    }
  ];

  const onChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  return (
    <STS>
      <Container>
        {/* <Title>{t("markets")}</Title> */}
        <SubTitle>{t("featured_tokens")}</SubTitle>
        <List
          className="marketsList"
          dataSource={tokens}
          renderItem={(item: any) => (
            <List.Item>
              <Card 
                title={<span><img className="tokenAvator" src={getTokenLogoURL(item.symbol)}/>&nbsp;{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><b>${item.price_usd}</b></span>} 
                extra={<span>24h<br /><b className={`${item.price_changed_24hr >= 0 ? 'up':'down'}`}>{`${item.price_changed_24hr > 0 ? `+${item.price_changed_24hr}%`: `${item.price_changed_24hr}%`}`}</b></span>}
              >
                <TokenChart data={item.history}/>
              </Card>
            </List.Item>
          )}
        />
        <SubTitle>{t("featured_portfolio")}</SubTitle>
        <List
          className="portfolioList"
          dataSource={portfolios}
          renderItem={(item: any) => (
            <List.Item>
              <Link to={`/sts/portfolio/${item.address}`}>
                <Card 
                  title={<span>{item.symbol === 'WETH' ? 'ETH' : item.symbol} &nbsp;
                    {!!item?.promote_end_at && <ClockCircleOutlined className='promote_tag' style={{fontSize: '14px', color: '#52C41A'}}/>}
                    </span>}
                  extra={<span><b className={item.price_changed_24hr > 0 ? 'up': 'down'}>{`${item.price_changed_24hr > 0 ? `+${item.price_changed_24hr}%`: `${item.price_changed_24hr}%`}`}</b></span>}
                  >
                  <Card.Grid style={{width: '30%', border: 'none', boxShadow:'none'}}>
                    <PortfolioAvatar src={item.avatar_uri} />
                  </Card.Grid>
                  <Card.Grid style={{width: '70%', border: 'none', boxShadow:'none'}}>
                    {item.name} 
                  </Card.Grid>
                </Card>
              </Link>
            </List.Item>
          )}
        />
        <Tabs className="marketTabs" activeKey={activeKey} onChange={onChange} tabBarExtraContent={<Search className="searchInput" placeholder={t("all_portfolios_placeholder")} onSearch={onSearch} />}>
          <TabPane tab={t("all_portfolios")} key="portfolio" >
            <Table 
              className="marketTable"
              columns={columns}
              dataSource={searchText === '' && searchPortfolios.length === 0 ? allPortfolios : searchPortfolios} 
              pagination={{ position: ['bottomCenter'] }} 
              showSorterTooltip={false}
              rowKey="address"
              scroll={{ x: 500 }}
              onRow={(record: any) => {
                return {
                  onClick: event => {history.push(`/sts/portfolio/${record.address}`)}, // 点击行
                };
              }}
            />
          </TabPane>
          <TabPane tab={t('manager_list')} key="manager">
            <Table 
              className="marketTable"
              columns={managerColumns}
              dataSource={searchText === '' && searchManagerList.length === 0 ? managerList : searchManagerList} 
              pagination={{ position: ['bottomCenter'] }} 
              showSorterTooltip={false}
              rowKey="address"
              scroll={{ x: 500 }}
              onRow={(record: any) => {
                return {
                  onClick: event => {history.push(`/sts/manager/${record.address}`)}, // 点击行
                };
              }}
            />
          </TabPane>
        </Tabs>
      </Container>
    </STS>
  )
}
