export default {
    'BTC': '#F69318',
    'ETH': '#A8C6FF',
    'WETH': '#A8C6FF',
    'USDT': '#26A07A',
    'BNB': '#F3B60E',
    'USDC': '#2172D0',
    'XRP': '#262B2F',
    'ADA': '#05319E',
    'SOL': '#28E8AA',
    'AVAX': '#EC3F43',
    'LUNA': '#FFDC40',
    'DOGE': '#BEA238',
    'DOT': '#E6087B',
    'BUSD': '#F0BB12',
    'SHIB': '#EB8726',
    'MATIC': '#8146F0',
    'CRO': '#182E5A',
    'UST': '#5894F3',
    'WBTC': '#F48E44',
    'DAI': '#F7B41C',
    'LTC': '#335D9F',
    'ATOM': '#303349',
    'FILCOIN': '#0190FF',
    'RAI': '#5542F6',
}