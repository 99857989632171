import React, {useState} from 'react'
import numeral from 'numeral';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next'
import { post } from 'utils/request';
import { useActiveWeb3React } from '../../hooks'
import { APILIST } from '../../constants'

const inputRegex = /^\d+(\.\d{1,2})?$/

export default function ({isOpen, handleCancel, stsInfo, setForceUpdate}: any){
    const { chainId, account } = useActiveWeb3React()
    const { t } = useTranslation()
    const [upPriceInput, setUpPriceInput] = useState<any>({});
    const [downPriceInput, setDownPriceInput] = useState<any>({});
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        console.log('Success:', values);
        if(!account){
            return;
        }
        const api_url = chainId ? APILIST[chainId] : APILIST[4];
        const res = await post(`${api_url}/sofi/alert`, {
            price_alert: 1,
            trade_alert: values.trade_alert ? 1 : 0,
            set: stsInfo.address,
            buyer: account,
            up: Number(values.up || 0),
            down: Number(values.down || 0),
            discord: values.discord
        })
        if(res){
            handleCancel();
            setForceUpdate();
            message.success(t('setting_success'))
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const validateUpPriceNumber = (num: string): any => {
        if ((stsInfo.value_per_set < Number(num) && inputRegex.test(num)) || num === '') {
          return {
            validateStatus: 'success',
            errorMsg: null,
          };
        }
        return {
          validateStatus: 'error',
          errorMsg: t('up_to_that_price_alert_error')
        };
    }

    const handleUpPriceChange = (value: any) => {
        value = value.replace(/[^\d.]/g, "");
        setUpPriceInput({...validateUpPriceNumber(value)})
        form.setFieldsValue({up: value});
    }

    const validateDownPriceNumber = (num: string): any => {
        if ((stsInfo.value_per_set > Number(num) && inputRegex.test(num)) || num === '') {
          return {
            validateStatus: 'success',
            errorMsg: null,
          };
        }
        return {
          validateStatus: 'error',
          errorMsg: t('down_to_that_price_alert_error')
        };
    }

    const handleDownPriceChange = (value: any) => {
        value = value.replace(/[^\d.]/g, "");
        setDownPriceInput({...validateDownPriceNumber(value)})
        form.setFieldsValue({down: value});
    }
    
    return (
        <Modal title={t('price_warning_settings')} visible={isOpen} footer={null} onCancel={handleCancel}>
           <Form
                name="basic"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark
                className="waveModal"
                form={form}
            >
                <Form.Item
                    label={t('token_name')}
                >
                    {stsInfo.symbol}
                </Form.Item>
                <Form.Item
                    label={t('current_price')}
                >
                    ${numeral(stsInfo.value_per_set).format('0,0.00')}
                </Form.Item>
                <Form.Item
                    label={t('up_to_that_price_alert')}
                    name="up"
                    validateStatus={upPriceInput?.validateStatus}
                    initialValue={stsInfo.up}
                    help={upPriceInput?.errorMsg}
                >
                    <Input onChange={(e: any) => {handleUpPriceChange(e.target.value)}}/>
                </Form.Item>
                <Form.Item
                    label={t('down_to_that_price_alert')}
                    name="down"
                    validateStatus={downPriceInput?.validateStatus}
                    initialValue={stsInfo.down}
                    help={downPriceInput?.errorMsg}
                >
                    <Input onChange={(e: any) => {handleDownPriceChange(e.target.value)}}/>
                </Form.Item>
                <Form.Item style={{marginTop: '-8px'}} wrapperCol={{ offset: 0, span: 24 }}>
                    <p style={{color: '#666'}}>{t('alarm_tip')}</p>
                </Form.Item>
                <Form.Item name="trade_alert" label={t('trade_notifications')} valuePropName="checked" initialValue={!!stsInfo.trade_alert}>
                    <Checkbox>{t('receive_transaction_notifications')}</Checkbox>
                </Form.Item>
                <Form.Item
                    label={t('discord_webhook_URL')}
                    name="discord"
                    initialValue={stsInfo.discord}
                    rules={[{ required: true, message: t('please_input_the_url') }]}
                >
                    <Input placeholder="https://discord.com/api/webhooks/88215"/>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                    <a href="https://rai-finance.gitbook.io/rai-finance/faq/alert" target="_blank">{t('how_to_get_this_url')}?</a>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 9, span: 15 }}>
                    <Button type="default" onClick={handleCancel}>
                        {t('cancel')}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button type="primary" htmlType="submit">
                        {t('save')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    ) 
}