import React from 'react'
import styled from 'styled-components'
import { Tabs } from "antd"
// import IdoCurrencyInputPanel from '../../../components/RaiCurrencyInputPanel'
// import { useSwapState } from '../../../state/swap/hooks'
import TradeSwap from './AggregateSwap'
import AddLiquidity from './AddLiquidity'
import { Currency, Token } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../../hooks'

const { TabPane } = Tabs;

export default function TradeWraper ({ currentPair }: any) {
  const { chainId } = useActiveWeb3React()
  function callback(key: string) {
    console.log(key);
  }

  // const { independentField, typedValue, recipient } = useSwapState()
  // const formattedAmounts = {
  //   [independentField]: typedValue,
  //   [dependentField]: showWrap
  //     ? parsedAmounts[independentField]?.toExact() ?? ''
  //     : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  // }
  return (
    currentPair ? 
    <TradeSwapWraper>
      <TradeSwap
        currentPair={{
          ...currentPair,
          token0: new Token(chainId || 4, currentPair?.token0?.address, currentPair?.token0?.decimals, currentPair?.token0?.symbol, currentPair?.token0?.name),
          token1: new Token(chainId || 4, currentPair?.token1?.address, currentPair?.token1?.decimals, currentPair?.token1?.symbol, currentPair?.token1?.name)
        }}
      />
    </TradeSwapWraper>
    :
    null
  )
}

const TradeSwapWraper = styled.div`
  /* .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
      .ant-tabs-tab-btn {
        font-size: 18px;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.bg6}
      }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background: ${({ theme }) => theme.bg6}
    }
    .ant-tabs-tab:hover {
      color: ${({ theme }) => theme.bg6}
    }
  } */
`

